import { registerLocale } from 'i18n-iso-countries';

import * as config from 'src/services/localization/config';

const countries = {
  /**
   * Load library, setting its initial locale
   *
   * @param {string} locale
   * @return Promise
   */
  async init(locale: config.Locale): Promise<void> {
    // Load translations
    const file = await config.supportedLocales[
      locale
    ].libraries.isoCountriesLoader();

    // Set locale
    registerLocale(file);
  },
};

export default countries;
