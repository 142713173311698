import React, { useMemo } from 'react';

import { Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormProvider, { RHFTextField } from 'src/components/atoms/hook-form';

interface Props {
  error?: Error | undefined | null;
  onSubmit: (values: Form) => Promise<void> | void;
}

export interface Form {
  email: string;
}

export const ForgotPasswordForm: React.FC<Props> = ({ error, onSubmit }) => {
  // Translation
  const { t } = useTranslation(['forgot-password-page', 'common']);

  // Schema
  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().required(),
      }).required(),
    [],
  );

  // Form
  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isValid },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!error?.message && <Alert severity="error">{error.message}</Alert>}

        <RHFTextField
          id="email"
          name="email"
          label={t('common:labels.email')}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={(isSubmitSuccessful && !error) || isSubmitting}
          disabled={!isValid || isSubmitting}
          sx={{
            bgcolor: 'text.primary',
            color: (theme) =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            '&:hover': {
              bgcolor: 'text.primary',
              color: (theme) =>
                theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            },
          }}
        >
          {t('forgot-password-page:buttons.send')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
