import React, { CSSProperties, useMemo } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps, MenuItem } from '@mui/material';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';

// Fields
const fields = [{ id: 1, display: 'username' }];

export const defaultMentionStyle: CSSProperties = {
  backgroundColor: '#2adbbb',
  opacity: 0.3,
  padding: 1,
  marginLeft: -1,
  borderRadius: 3,
};

const defaultRenderSuggestionItem = (suggestion: SuggestionDataItem) => {
  const itemContent = suggestion.display;

  return (
    <MenuItem component="div" style={{ height: '100%', width: '100%' }}>
      {itemContent}
    </MenuItem>
  );
};

type Props = TextFieldProps & {
  name: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function RHFMentionTextField({
  name,
  helperText,
  ...other
}: Props) {
  // Form
  const { control } = useFormContext();

  // Constants

  const InputComponent = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unstable-nested-components
      React.forwardRef<any, React.InputHTMLAttributes<HTMLInputElement>>(
        (inputProps, ref) => {
          return (
            <Controller
              name={name}
              control={control}
              render={({ fieldState: { error } }) => (
                <TextField
                  inputRef={ref}
                  fullWidth
                  {...other}
                  inputProps={{ ...inputProps, style: {} }}
                  error={!!error}
                  helperText={error ? error?.message : helperText}
                />
              )}
            />
          );
        },
      ),
    [other, control, name, helperText],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MentionsInput
          style={{
            highlighter: {
              position: 'absolute',
              padding: '16.5px 14px',
              border: 'none',
              'white-space': 'inherit',
              'line-height': '1.4375em',
              'font-family': 'Public Sans,sans-serif',
              'font-weight': 400,
            },
          }}
          {...field}
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          renderInput={InputComponent}
        >
          <Mention
            style={defaultMentionStyle}
            trigger="{"
            data={fields}
            markup="{{__display__}}"
            appendSpaceOnAdd={true}
            renderSuggestion={defaultRenderSuggestionItem}
          />
        </MentionsInput>
      )}
    />
  );
}
