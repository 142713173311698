import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import Iconify from 'src/components/atoms/media/Iconify';
import {
  ForgotPasswordForm,
  Form,
} from 'src/components/organisms/form/ForgotPasswordForm';

import { EmailInboxIcon } from 'src/assets/icons';
import { useForgotPasswordMutation } from 'src/core';

export default function ForgotPasswordPage() {
  // Translations
  const { t } = useTranslation(['forgot-password-page', 'common']);

  // Navigation
  const navigate = useNavigate();

  // Mutations
  const [forgotPassword, { error }] = useForgotPasswordMutation();

  // Callbacks
  const handleSubmit = useCallback(
    async (values: Form) => {
      try {
        if (!values.email) {
          throw new Error('Invalid email');
        }

        const result = await forgotPassword({
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              email: values.email,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        if (!result.data) {
          throw new Error('Something went wrong');
        }

        navigate(paths.auth.resetPassword.pattern, {
          state: { email: values.email },
        });
      } catch (err) {
        console.log('ERROR: ', err);
      }
    },
    [forgotPassword, navigate],
  );

  return (
    <>
      <Helmet>
        <title>{t('forgot-password-page:page-title')}</title>
      </Helmet>

      <EmailInboxIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        {t('forgot-password-page:title')}
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        {t('forgot-password-page:description')}
      </Typography>

      <ForgotPasswordForm error={error} onSubmit={handleSubmit} />

      <Link
        component={RouterLink}
        to={paths.auth.login.pattern}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />

        {t('forgot-password-page:buttons.back')}
      </Link>
    </>
  );
}
