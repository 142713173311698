import { createContext } from 'react';

import { CurrentAdminContextValue } from 'src/interfaces/current-admin.interface';

export const defaultContext: CurrentAdminContextValue = {
  admin: null,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const CurrentAdminContext =
  createContext<CurrentAdminContextValue>(defaultContext);

CurrentAdminContext.displayName = 'CurrentUserContextValue';

export default CurrentAdminContext;
