import React from 'react';

import { styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  StackProps,
  CardProps,
} from '@mui/material';

import Image from 'src/components/atoms/media/Image';
import Iconify from 'src/components/atoms/media/Iconify';

import { fShortenNumber } from 'src/utils/formatNumber';

const StyledBlock = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" {...props} />
))({
  minWidth: 72,
  flex: '1 1',
});

const StyledItemIcon = styled(Iconify)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(0.5),
  color: theme.palette.text.disabled,
}));

type ItemProps = {
  id: string;
  name: string;
  android: number;
  ios: number;
  flag: string;
};

function CountryItem({ name, flag, android, ios }: ItemProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <StyledBlock sx={{ minWidth: 120 }}>
        <Image disabledEffect alt={name} src={flag} sx={{ width: 28, mr: 1 }} />

        <Typography variant="subtitle2">{name}</Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="ant-design:android-filled" />

        <Typography variant="body2">{fShortenNumber(android)}</Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="ant-design:apple-filled" />

        <Typography variant="body2">{fShortenNumber(ios)}</Typography>
      </StyledBlock>
    </Stack>
  );
}

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  list: ItemProps[];
}

export function WidgetTopInstalledCountries({
  title,
  subheader,
  list,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3 }}>
        {list.map((country) => (
          <CountryItem key={country.id} {...country} />
        ))}
      </Stack>
    </Card>
  );
}
