import React from 'react';

import { paths } from 'src/navigation/paths';
import SvgColor from 'src/components/atoms/container/SvgColor';

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  searchSection: icon('ic_kanban'),
  notification: icon('ic_mail'),
};

const navConfig = [
  // GENERAL
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        path: paths.dashboard.app.pattern,
        icon: ICONS.dashboard,
      },
      {
        title: 'notification',
        path: paths.dashboard.notification.pattern,
        icon: ICONS.notification,
      },
    ],
  },

  // MANAGEMENT
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'user',
        path: paths.dashboard.user.pattern,
        icon: ICONS.user,
        children: [
          { title: 'list', path: paths.dashboard.user.list.pattern },
          { title: 'create', path: paths.dashboard.user.create.pattern },
        ],
      },
      // SEARCH-SECTION
      {
        title: 'search-section',
        path: paths.dashboard.searchSection.pattern,
        icon: ICONS.searchSection,
        children: [
          { title: 'list', path: paths.dashboard.searchSection.list.pattern },
          {
            title: 'create',
            path: paths.dashboard.searchSection.create.pattern,
          },
        ],
      },
    ],
  },
];

export default navConfig;
