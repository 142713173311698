import { FunctionComponent } from 'react';

import { PathParamsFor, createRouting, query, segment } from 'ts-routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageProps<TPathParams extends (...args: any[]) => string> =
  PathParamsFor<TPathParams>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageComponent<TPathParams extends (...args: any[]) => string> =
  FunctionComponent<PageProps<TPathParams>>;

export const paths = createRouting({
  auth: {
    ...segment`/auth`,
    children: {
      login: segment`/login`,
      otp: segment`/otp`,
      forgotPassword: segment`/forgot-password`,
      resetPassword: segment`/reset-password`,
      invite: {
        ...segment`/invite`,
        query: {
          token: query('required'),
        },
      },
    },
  },
  dashboard: {
    ...segment`/dashboard`,
    children: {
      app: segment`/app`,
      notification: segment`/notification`,
      user: {
        ...segment`/user`,
        children: {
          list: segment`/list`,
          create: segment`/create`,
          edit: segment`/edit`,
        },
      },
      searchSection: {
        ...segment`/search-section`,
        children: {
          list: segment`/list`,
          create: segment`/create`,
          edit: segment`/edit`,
        },
      },
    },
  },
} as const);
