import React from 'react';

import { Outlet } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Logo from 'src/components/atoms/media/Logo';
import Image from 'src/components/atoms/media/Image';

import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from 'src/layouts/auth/styles';

type Props = {
  title?: string;
  illustration?: string;
};

export default function AuthLayout({ illustration, title }: Props) {
  // Translations
  const { t } = useTranslation('common');

  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <Typography
          variant="h3"
          sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}
        >
          {title ?? t('welcome-back')}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={
            illustration || '/assets/illustrations/illustration_dashboard.png'
          }
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}>
          <Outlet />
        </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
