import {
  ApolloCache,
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  DocumentNode,
  ServerError,
  ServerParseError,
  split,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { Client as WSClient, createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor } from 'apollo3-cache-persist';
import { ApolloPersistOptions } from 'apollo3-cache-persist/lib/types/index';
import { GraphQLErrors } from '@apollo/client/errors';

import { ApolloManager } from 'src/core/infrastructure/interfaces/apollo-manager';
import { promiseToObservable } from 'src/core/utils/promise-to-observable';
import ReduxStoreAdapter from 'src/core/adapters/redux-store';
import {
  getAutoRenewCredentials,
  getLocalCredentials,
  renewCredentials,
} from 'src/core/utils/token';
import { loggerLink } from 'src/core/services/apollo/logger';
import { assertNotNull } from 'src/core/utils/assert';

const logger = loggerLink(() => 'Snoop');

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export interface Options<TCacheShape> extends ApolloClientOptions<TCacheShape> {
  wsUri: string;
  storeAdapter: ReduxStoreAdapter;
  // Cache is already provided in the function
  persistOptions: Omit<ApolloPersistOptions<TCacheShape>, 'cache'> & {
    cache?: ApolloCache<TCacheShape>;
  };
  onError?: (err: GraphQLErrors | undefined) => void;
  onNetworkError?: (err: Error | ServerParseError | ServerError) => void;
  onUnauthenticatedError?: () => void;
}

export class Apollo<TCacheShape> implements ApolloManager<TCacheShape> {
  private client: ApolloClient<TCacheShape> | null = null;
  private persistor: CachePersistor<TCacheShape> | null = null;
  private wsClient: WSClient | null = null;
  private options: Options<TCacheShape>;

  constructor(options: Options<TCacheShape>) {
    this.options = options;
  }

  async init() {
    const {
      storeAdapter,
      uri,
      wsUri,
      persistOptions,
      onError: onErrorCb,
      onNetworkError,
      onUnauthenticatedError,
      ...options
    } = this.options;

    const isSubscription = (query: DocumentNode) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    };

    this.wsClient = createClient({
      url: wsUri,
      connectionParams: async () => {
        const credentials = await getAutoRenewCredentials(uri, storeAdapter);

        return {
          authorization: credentials?.accessToken
            ? `Bearer ${credentials?.accessToken}`
            : '',
        };
      },
      lazy: true,
      retryAttempts: 5,
      shouldRetry() {
        // Always retry connection
        return true;
      },
    });

    const buildApolloLink = (): ApolloLink => {
      // HttpLink is inside the uploadLink library
      const uploadLink = createUploadLink({
        uri,
        headers: { 'Apollo-Require-Preflight': 'true' },
      });

      // Create WS link, wsClient is initialize just above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wsLink = new GraphQLWsLink(this.wsClient!);

      // The split function takes three parameters:
      //
      // * A function that's called for each operation to execute
      // * The Link to use for an operation if the function returns a "truthy" value
      // * The Link to use for an operation if the function returns a "falsy" value
      const splitLink = split(
        ({ query }) => isSubscription(query),
        wsLink,
        uploadLink,
      );

      const authLink = setContext(async (_, { headers }) => {
        const credentials = getLocalCredentials(storeAdapter);

        return {
          headers: {
            ...headers,
            authorization: credentials?.accessToken
              ? `Bearer ${credentials?.accessToken}`
              : '',
          },
        };
      });

      const retryLink = new RetryLink({
        delay: {
          initial: 100,
        },
        attempts: {
          max: 2,
          retryIf: (error) => !!error,
        },
      });

      const errorLink = onError(
        ({ graphQLErrors, networkError, forward, operation }) => {
          if (graphQLErrors) {
            onErrorCb?.(graphQLErrors);

            for (const graphQLError of graphQLErrors) {
              switch (graphQLError?.extensions?.code) {
              case 'UNAUTHENTICATED': {
                // error code is set to UNAUTHENTICATED
                // when AuthenticationError thrown in resolver
                let forward$: Observable<boolean>;

                if (!isRefreshing) {
                  isRefreshing = true;
                  forward$ = promiseToObservable(
                    renewCredentials(uri, storeAdapter)
                      .then(() => {
                        resolvePendingRequests();
                        return true;
                      })
                      .catch(() => {
                        pendingRequests = [];
                        onUnauthenticatedError?.();
                        return false;
                      })
                      .finally(() => {
                        isRefreshing = false;
                      }),
                  ).filter((value) => Boolean(value));
                } else {
                  // Will only emit once the Promise is resolved
                  forward$ = promiseToObservable(
                    new Promise<boolean>((resolve) => {
                      pendingRequests.push(() => resolve(true));
                    }),
                  );
                }

                return forward$.flatMap(() => forward(operation));
              }
              default:
                console.warn(
                  `[GraphQL error]: Message: ${
                    graphQLError.message
                  }, Location: ${
                    graphQLError.locations
                      ? JSON.stringify(graphQLError.locations)
                      : graphQLError.locations
                  }, Path: ${graphQLError.path}`,
                );
              }
            }
          }

          if (networkError) {
            console.warn(`[Network error]: ${networkError}`);
            onNetworkError?.(networkError);
          }
        },
      );

      return ApolloLink.from(
        [
          errorLink,
          authLink,
          // Only show logger in dev mode
          process.env.NODE_ENV !== 'production' ? logger : null,
          retryLink,
          splitLink,
        ].filter(assertNotNull),
      );
    };

    this.persistor = new CachePersistor({
      cache: options.cache,
      trigger: 'write',
      ...persistOptions,
    });

    await this.persistor.restore();

    this.client = new ApolloClient({
      ...options,
      link: buildApolloLink(),
    });
  }

  getClient() {
    return this.client;
  }

  getPersistor() {
    return this.persistor;
  }

  getWSClient() {
    return this.wsClient;
  }
}
