import React from 'react';

import { TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmptyContent from 'src/components/molecules/empty-content';

export default function TableNoData() {
  // Translation
  const { t } = useTranslation('common');

  return (
    <TableRow>
      <TableCell colSpan={12}>
        <EmptyContent
          title={t('no-data')}
          sx={{
            '& span.MuiBox-root': { height: 160 },
          }}
        />
      </TableCell>
    </TableRow>
  );
}
