import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from 'src/components/atoms/media/Iconify';
import MenuPopover from 'src/components/molecules/menu-popover';
import ConfirmDialog from 'src/components/molecules/dialog/ConfirmDialog';

import { SearchSectionFragment } from 'src/core';

type Props = {
  item: SearchSectionFragment;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function SearchSectionRow({
  item,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  // Translation
  const { t } = useTranslation('common');

  const { translation, order, slug, curratedBy } = item;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell align="left">{slug}</TableCell>

        <TableCell align="left">{translation?.title ?? 'NULL'}</TableCell>

        <TableCell align="center">{order}</TableCell>

        <TableCell align="left">{curratedBy?.username ?? 'NULL'}</TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? 'inherit' : 'default'}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />

          {t('buttons.delete')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />

          {t('buttons.edit')}
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('buttons.delete')}
          </Button>
        }
      />
    </>
  );
}
