import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Timestamp: any;
  Upload: any;
};

export type Activity = {
  __typename?: 'Activity';
  _count: ActivityCount;
  author: User;
  authorId: Scalars['String'];
  childActivities?: Maybe<Array<Activity>>;
  comments?: Maybe<Array<Comment>>;
  countLikes: Scalars['Float'];
  createdAt: Scalars['Timestamp'];
  deleted?: Maybe<Scalars['Timestamp']>;
  externalLink?: Maybe<Scalars['String']>;
  firstFollowingsReactions: PaginatedActivity;
  id: Scalars['ID'];
  liked: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  myReaction?: Maybe<Activity>;
  parentActivity?: Maybe<Activity>;
  parentId?: Maybe<Scalars['String']>;
  reaction: ReactionType;
  recommendation?: Maybe<Recommendation>;
  recommendationId?: Maybe<Scalars['String']>;
  show: Show;
  showId: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type ActivityCount = {
  __typename?: 'ActivityCount';
  blocks: Scalars['Int'];
  childActivities: Scalars['Int'];
  comments: Scalars['Int'];
  notifications: Scalars['Int'];
};

export type ActivityCountAggregate = {
  __typename?: 'ActivityCountAggregate';
  _all: Scalars['Int'];
  authorId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deleted: Scalars['Int'];
  externalLink: Scalars['Int'];
  id: Scalars['Int'];
  message: Scalars['Int'];
  parentId: Scalars['Int'];
  reaction: Scalars['Int'];
  recommendationId: Scalars['Int'];
  showId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ActivityCreateManyParentActivityInput = {
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  reaction: ReactionType;
  recommendationId?: InputMaybe<Scalars['String']>;
  showId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityCreateManyParentActivityInputEnvelope = {
  data: Array<ActivityCreateManyParentActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityCreateNestedManyWithoutParentActivityInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActivityCreateOrConnectWithoutParentActivityInput>
  >;
  create?: InputMaybe<Array<ActivityCreateWithoutParentActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyParentActivityInputEnvelope>;
};

export type ActivityCreateNestedOneWithoutChildActivitiesInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutChildActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutChildActivitiesInput>;
};

export type ActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
};

export type ActivityCreateOrConnectWithoutChildActivitiesInput = {
  create: ActivityCreateWithoutChildActivitiesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutParentActivityInput = {
  create: ActivityCreateWithoutParentActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutChildActivitiesInput = {
  author: UserCreateNestedOneWithoutActivitiesInput;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentActivity?: InputMaybe<ActivityCreateNestedOneWithoutChildActivitiesInput>;
  reaction: ReactionType;
  recommendation?: InputMaybe<RecommendationCreateNestedOneWithoutActivitiesInput>;
  show: ShowCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityCreateWithoutCommentsInput = {
  author: UserCreateNestedOneWithoutActivitiesInput;
  childActivities?: InputMaybe<ActivityCreateNestedManyWithoutParentActivityInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentActivity?: InputMaybe<ActivityCreateNestedOneWithoutChildActivitiesInput>;
  reaction: ReactionType;
  recommendation?: InputMaybe<RecommendationCreateNestedOneWithoutActivitiesInput>;
  show: ShowCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityCreateWithoutParentActivityInput = {
  author: UserCreateNestedOneWithoutActivitiesInput;
  childActivities?: InputMaybe<ActivityCreateNestedManyWithoutParentActivityInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  reaction: ReactionType;
  recommendation?: InputMaybe<RecommendationCreateNestedOneWithoutActivitiesInput>;
  show: ShowCreateNestedOneWithoutActivitiesInput;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Activity>;
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityMaxAggregate = {
  __typename?: 'ActivityMaxAggregate';
  authorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deleted?: Maybe<Scalars['Timestamp']>;
  externalLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  reaction?: Maybe<ReactionType>;
  recommendationId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ActivityMinAggregate = {
  __typename?: 'ActivityMinAggregate';
  authorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deleted?: Maybe<Scalars['Timestamp']>;
  externalLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  reaction?: Maybe<ReactionType>;
  recommendationId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ActivityOrderByRelevanceFieldEnum {
  AuthorId = 'authorId',
  ExternalLink = 'externalLink',
  Id = 'id',
  Message = 'message',
  ParentId = 'parentId',
  RecommendationId = 'recommendationId',
  ShowId = 'showId',
}

export type ActivityOrderByRelevanceInput = {
  fields: Array<ActivityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ActivityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ActivityOrderByRelevanceInput>;
  author?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  authorId?: InputMaybe<SortOrder>;
  childActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  externalLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  parentActivity?: InputMaybe<ActivityOrderByWithRelationAndSearchRelevanceInput>;
  parentId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrder>;
  recommendation?: InputMaybe<RecommendationOrderByWithRelationAndSearchRelevanceInput>;
  recommendationId?: InputMaybe<SortOrder>;
  show?: InputMaybe<ShowOrderByWithRelationAndSearchRelevanceInput>;
  showId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ActivityPageInfo = {
  __typename?: 'ActivityPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ActivityRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeFilter>;
  externalLink?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringFilter>;
  reaction?: InputMaybe<EnumReactionTypeFilter>;
  recommendationId?: InputMaybe<StringFilter>;
  showId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityShowIdAuthorIdCompoundUniqueInput = {
  authorId: Scalars['String'];
  showId: Scalars['String'];
};

export type ActivityUpdateInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutActivitiesNestedInput>;
  childActivities?: InputMaybe<ActivityUpdateManyWithoutParentActivityNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentActivity?: InputMaybe<ActivityUpdateOneWithoutChildActivitiesNestedInput>;
  reaction?: InputMaybe<ReactionType>;
  recommendation?: InputMaybe<RecommendationUpdateOneWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<ReactionType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityUpdateManyWithWhereWithoutParentActivityInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutParentActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ActivityCreateOrConnectWithoutParentActivityInput>
  >;
  create?: InputMaybe<Array<ActivityCreateWithoutParentActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyParentActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ActivityUpdateWithWhereUniqueWithoutParentActivityInput>
  >;
  updateMany?: InputMaybe<
    Array<ActivityUpdateManyWithWhereWithoutParentActivityInput>
  >;
  upsert?: InputMaybe<
    Array<ActivityUpsertWithWhereUniqueWithoutParentActivityInput>
  >;
};

export type ActivityUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
  update?: InputMaybe<ActivityUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutCommentsInput>;
};

export type ActivityUpdateOneWithoutChildActivitiesNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutChildActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutChildActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ActivityUpdateWithoutChildActivitiesInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutChildActivitiesInput>;
};

export type ActivityUpdateWithWhereUniqueWithoutParentActivityInput = {
  data: ActivityUpdateWithoutParentActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutChildActivitiesInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentActivity?: InputMaybe<ActivityUpdateOneWithoutChildActivitiesNestedInput>;
  reaction?: InputMaybe<ReactionType>;
  recommendation?: InputMaybe<RecommendationUpdateOneWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityUpdateWithoutCommentsInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutActivitiesNestedInput>;
  childActivities?: InputMaybe<ActivityUpdateManyWithoutParentActivityNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentActivity?: InputMaybe<ActivityUpdateOneWithoutChildActivitiesNestedInput>;
  reaction?: InputMaybe<ReactionType>;
  recommendation?: InputMaybe<RecommendationUpdateOneWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityUpdateWithoutParentActivityInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutActivitiesNestedInput>;
  childActivities?: InputMaybe<ActivityUpdateManyWithoutParentActivityNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<ReactionType>;
  recommendation?: InputMaybe<RecommendationUpdateOneWithoutActivitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ActivityUpsertWithWhereUniqueWithoutParentActivityInput = {
  create: ActivityCreateWithoutParentActivityInput;
  update: ActivityUpdateWithoutParentActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithoutChildActivitiesInput = {
  create: ActivityCreateWithoutChildActivitiesInput;
  update: ActivityUpdateWithoutChildActivitiesInput;
};

export type ActivityUpsertWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  update: ActivityUpdateWithoutCommentsInput;
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  author?: InputMaybe<UserRelationFilter>;
  authorId?: InputMaybe<StringFilter>;
  childActivities?: InputMaybe<ActivityListRelationFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeFilter>;
  externalLink?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  parentActivity?: InputMaybe<ActivityRelationFilter>;
  parentId?: InputMaybe<StringFilter>;
  reaction?: InputMaybe<EnumReactionTypeFilter>;
  recommendation?: InputMaybe<RecommendationRelationFilter>;
  recommendationId?: InputMaybe<StringFilter>;
  show?: InputMaybe<ShowRelationFilter>;
  showId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  showId_authorId?: InputMaybe<ActivityShowIdAuthorIdCompoundUniqueInput>;
};

export type Admin = {
  __typename?: 'Admin';
  _count: AdminCount;
  createdAt: Scalars['Timestamp'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneConfirmed: Scalars['Boolean'];
  role: AdminRole;
  updatedAt: Scalars['Timestamp'];
};

export type AdminCount = {
  __typename?: 'AdminCount';
  refreshTokens: Scalars['Int'];
};

export type AdminEdge = {
  __typename?: 'AdminEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Admin>;
};

export type AdminPageInfo = {
  __typename?: 'AdminPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum AdminRole {
  Admin = 'ADMIN',
}

export type AreaCountModel = {
  __typename?: 'AreaCountModel';
  iso31661: Scalars['String'];
  monthlyCounts: Array<MonthlyCountModel>;
};

export type Artist = {
  __typename?: 'Artist';
  acting?: Maybe<Array<ShowItem>>;
  adult: Scalars['Boolean'];
  alsoKnownAs: Array<Scalars['String']>;
  biography: Scalars['String'];
  birthday?: Maybe<Scalars['String']>;
  deathday?: Maybe<Scalars['String']>;
  directing?: Maybe<Array<ShowItem>>;
  externalIds?: Maybe<ExternalIds>;
  filmography?: Maybe<Array<ShowItem>>;
  gender: Scalars['Float'];
  homepage?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  imdbId: Scalars['String'];
  knownForDepartment: Scalars['String'];
  name: Scalars['String'];
  placeOfBirth?: Maybe<Scalars['String']>;
  popularity: Scalars['Float'];
  profilePath?: Maybe<Scalars['String']>;
};

export type AuthAdminModel = {
  __typename?: 'AuthAdminModel';
  admin: Admin;
  tokens: TokensModel;
};

export type AuthModel = {
  __typename?: 'AuthModel';
  tokens: TokensModel;
  user: User;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Float'];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BoolFilter>;
};

export type ChallengeAdminDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ChallengeDto = {
  phone?: InputMaybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  _count: CommentCount;
  activity: Activity;
  activityId: Scalars['String'];
  author: User;
  authorId: Scalars['String'];
  childComments?: Maybe<Array<Comment>>;
  countLikes: Scalars['Float'];
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  liked: Scalars['Boolean'];
  message: Scalars['String'];
  parentComment?: Maybe<Comment>;
  parentId?: Maybe<Scalars['String']>;
  spoiler: Scalars['Boolean'];
  updatedAt: Scalars['Timestamp'];
};

export type CommentCount = {
  __typename?: 'CommentCount';
  blocks: Scalars['Int'];
  childComments: Scalars['Int'];
  notifications: Scalars['Int'];
};

export type CommentCountAggregate = {
  __typename?: 'CommentCountAggregate';
  _all: Scalars['Int'];
  activityId: Scalars['Int'];
  authorId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  message: Scalars['Int'];
  parentId: Scalars['Int'];
  spoiler: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CommentCreateManyActivityInput = {
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentCreateManyActivityInputEnvelope = {
  data: Array<CommentCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommentCreateManyParentCommentInput = {
  activityId: Scalars['String'];
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentCreateManyParentCommentInputEnvelope = {
  data: Array<CommentCreateManyParentCommentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommentCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CommentCreateOrConnectWithoutActivityInput>
  >;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
};

export type CommentCreateNestedManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CommentCreateOrConnectWithoutParentCommentInput>
  >;
  create?: InputMaybe<Array<CommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentCommentInputEnvelope>;
};

export type CommentCreateNestedOneWithoutChildCommentsInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutChildCommentsInput>;
  create?: InputMaybe<CommentCreateWithoutChildCommentsInput>;
};

export type CommentCreateOrConnectWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutChildCommentsInput = {
  create: CommentCreateWithoutChildCommentsInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutParentCommentInput = {
  create: CommentCreateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutActivityInput = {
  author: UserCreateNestedOneWithoutCommentsInput;
  childComments?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutChildCommentsInput>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentCreateWithoutChildCommentsInput = {
  activity: ActivityCreateNestedOneWithoutCommentsInput;
  author: UserCreateNestedOneWithoutCommentsInput;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  parentComment?: InputMaybe<CommentCreateNestedOneWithoutChildCommentsInput>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentCreateWithoutParentCommentInput = {
  activity: ActivityCreateNestedOneWithoutCommentsInput;
  author: UserCreateNestedOneWithoutCommentsInput;
  childComments?: InputMaybe<CommentCreateNestedManyWithoutParentCommentInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Comment>;
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: 'CommentMaxAggregate';
  activityId?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  spoiler?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CommentMinAggregate = {
  __typename?: 'CommentMinAggregate';
  activityId?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  spoiler?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommentPageInfo = {
  __typename?: 'CommentPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CommentRelationFilter = {
  is?: InputMaybe<CommentWhereInput>;
  isNot?: InputMaybe<CommentWhereInput>;
};

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  activityId?: InputMaybe<StringFilter>;
  authorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringFilter>;
  spoiler?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommentUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentUpdateManyWithWhereWithoutActivityInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutParentCommentInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CommentCreateOrConnectWithoutActivityInput>
  >;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<
    Array<CommentUpdateManyWithWhereWithoutActivityInput>
  >;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type CommentUpdateManyWithoutParentCommentNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CommentCreateOrConnectWithoutParentCommentInput>
  >;
  create?: InputMaybe<Array<CommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<CommentCreateManyParentCommentInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CommentUpdateWithWhereUniqueWithoutParentCommentInput>
  >;
  updateMany?: InputMaybe<
    Array<CommentUpdateManyWithWhereWithoutParentCommentInput>
  >;
  upsert?: InputMaybe<
    Array<CommentUpsertWithWhereUniqueWithoutParentCommentInput>
  >;
};

export type CommentUpdateOneWithoutChildCommentsNestedInput = {
  connect?: InputMaybe<CommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentCreateOrConnectWithoutChildCommentsInput>;
  create?: InputMaybe<CommentCreateWithoutChildCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommentUpdateWithoutChildCommentsInput>;
  upsert?: InputMaybe<CommentUpsertWithoutChildCommentsInput>;
};

export type CommentUpdateWithWhereUniqueWithoutActivityInput = {
  data: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutParentCommentInput = {
  data: CommentUpdateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutActivityInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
  childComments?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutChildCommentsNestedInput>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentUpdateWithoutChildCommentsInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutCommentsNestedInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  parentComment?: InputMaybe<CommentUpdateOneWithoutChildCommentsNestedInput>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentUpdateWithoutParentCommentInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutCommentsNestedInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutCommentsNestedInput>;
  childComments?: InputMaybe<CommentUpdateManyWithoutParentCommentNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CommentUpsertWithWhereUniqueWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  update: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutParentCommentInput = {
  create: CommentCreateWithoutParentCommentInput;
  update: CommentUpdateWithoutParentCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithoutChildCommentsInput = {
  create: CommentCreateWithoutChildCommentsInput;
  update: CommentUpdateWithoutChildCommentsInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<StringFilter>;
  author?: InputMaybe<UserRelationFilter>;
  authorId?: InputMaybe<StringFilter>;
  childComments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  parentComment?: InputMaybe<CommentRelationFilter>;
  parentId?: InputMaybe<StringFilter>;
  spoiler?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  _count: ContactCount;
  associatedUser?: Maybe<User>;
  associatedUserId?: Maybe<Scalars['String']>;
  encryptedDatum: Scalars['String'];
  id: Scalars['ID'];
  users?: Maybe<Array<User>>;
};

export type ContactCount = {
  __typename?: 'ContactCount';
  users: Scalars['Int'];
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Contact>;
};

export type ContactListDto = {
  contacts: Array<EncryptedContactInput>;
};

export type ContactPageInfo = {
  __typename?: 'ContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  _count: CountryCount;
  createdAt: Scalars['Timestamp'];
  englishName: Scalars['String'];
  iso31661: Scalars['ID'];
  languages?: Maybe<Array<Language>>;
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type CountryCount = {
  __typename?: 'CountryCount';
  folders: Scalars['Int'];
  languages: Scalars['Int'];
  showWatchProviderCountries: Scalars['Int'];
  watchProviders: Scalars['Int'];
};

export type CountryCreateNestedManyWithoutWatchProvidersInput = {
  connect?: InputMaybe<Array<CountryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CountryCreateOrConnectWithoutWatchProvidersInput>
  >;
  create?: InputMaybe<Array<CountryCreateWithoutWatchProvidersInput>>;
};

export type CountryCreateNestedOneWithoutLanguagesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutLanguagesInput>;
  create?: InputMaybe<CountryCreateWithoutLanguagesInput>;
};

export type CountryCreateNestedOneWithoutShowWatchProviderCountriesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutShowWatchProviderCountriesInput>;
  create?: InputMaybe<CountryCreateWithoutShowWatchProviderCountriesInput>;
};

export type CountryCreateOrConnectWithoutLanguagesInput = {
  create: CountryCreateWithoutLanguagesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutShowWatchProviderCountriesInput = {
  create: CountryCreateWithoutShowWatchProviderCountriesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutWatchProvidersInput = {
  create: CountryCreateWithoutWatchProvidersInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateWithoutLanguagesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  iso31661: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryCreateWithoutShowWatchProviderCountriesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  iso31661: Scalars['String'];
  languages?: InputMaybe<LanguageCreateNestedManyWithoutCountryInput>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryCreateWithoutWatchProvidersInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  iso31661: Scalars['String'];
  languages?: InputMaybe<LanguageCreateNestedManyWithoutCountryInput>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryListRelationFilter = {
  every?: InputMaybe<CountryWhereInput>;
  none?: InputMaybe<CountryWhereInput>;
  some?: InputMaybe<CountryWhereInput>;
};

export type CountryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CountryOrderByRelevanceFieldEnum {
  EnglishName = 'englishName',
  Iso31661 = 'iso31661',
  Name = 'name',
}

export type CountryOrderByRelevanceInput = {
  fields: Array<CountryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CountryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CountryOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  englishName?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  languages?: InputMaybe<LanguageOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CountryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  englishName?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  languages?: InputMaybe<LanguageOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CountryRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export type CountryScalarWhereInput = {
  AND?: InputMaybe<Array<CountryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CountryScalarWhereInput>>;
  OR?: InputMaybe<Array<CountryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  englishName?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountryUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  iso31661?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryUpdateManyWithWhereWithoutWatchProvidersInput = {
  data: CountryUpdateManyMutationInput;
  where: CountryScalarWhereInput;
};

export type CountryUpdateManyWithoutWatchProvidersNestedInput = {
  connect?: InputMaybe<Array<CountryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CountryCreateOrConnectWithoutWatchProvidersInput>
  >;
  create?: InputMaybe<Array<CountryCreateWithoutWatchProvidersInput>>;
  delete?: InputMaybe<Array<CountryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CountryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CountryWhereUniqueInput>>;
  set?: InputMaybe<Array<CountryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CountryUpdateWithWhereUniqueWithoutWatchProvidersInput>
  >;
  updateMany?: InputMaybe<
    Array<CountryUpdateManyWithWhereWithoutWatchProvidersInput>
  >;
  upsert?: InputMaybe<
    Array<CountryUpsertWithWhereUniqueWithoutWatchProvidersInput>
  >;
};

export type CountryUpdateOneRequiredWithoutLanguagesNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutLanguagesInput>;
  create?: InputMaybe<CountryCreateWithoutLanguagesInput>;
  update?: InputMaybe<CountryUpdateWithoutLanguagesInput>;
  upsert?: InputMaybe<CountryUpsertWithoutLanguagesInput>;
};

export type CountryUpdateOneRequiredWithoutShowWatchProviderCountriesNestedInput =
  {
    connect?: InputMaybe<CountryWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutShowWatchProviderCountriesInput>;
    create?: InputMaybe<CountryCreateWithoutShowWatchProviderCountriesInput>;
    update?: InputMaybe<CountryUpdateWithoutShowWatchProviderCountriesInput>;
    upsert?: InputMaybe<CountryUpsertWithoutShowWatchProviderCountriesInput>;
  };

export type CountryUpdateWithWhereUniqueWithoutWatchProvidersInput = {
  data: CountryUpdateWithoutWatchProvidersInput;
  where: CountryWhereUniqueInput;
};

export type CountryUpdateWithoutLanguagesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  iso31661?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryUpdateWithoutShowWatchProviderCountriesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  iso31661?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<LanguageUpdateManyWithoutCountryNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryUpdateWithoutWatchProvidersInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  iso31661?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<LanguageUpdateManyWithoutCountryNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type CountryUpsertWithWhereUniqueWithoutWatchProvidersInput = {
  create: CountryCreateWithoutWatchProvidersInput;
  update: CountryUpdateWithoutWatchProvidersInput;
  where: CountryWhereUniqueInput;
};

export type CountryUpsertWithoutLanguagesInput = {
  create: CountryCreateWithoutLanguagesInput;
  update: CountryUpdateWithoutLanguagesInput;
};

export type CountryUpsertWithoutShowWatchProviderCountriesInput = {
  create: CountryCreateWithoutShowWatchProviderCountriesInput;
  update: CountryUpdateWithoutShowWatchProviderCountriesInput;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  englishName?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  languages?: InputMaybe<LanguageListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountryWhereUniqueInput = {
  iso31661?: InputMaybe<Scalars['String']>;
};

export type CreateActivityDto = {
  externalLink?: InputMaybe<Scalars['String']>;
  mediaType: MediaType;
  message?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  reaction: ReactionType;
  recommendationId?: InputMaybe<Scalars['String']>;
  tmdbId: Scalars['Float'];
};

export type CreateCommentDto = {
  activityId: Scalars['String'];
  message: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDeviceDto = {
  pushToken: Scalars['String'];
};

export type CreateMessageDto = {
  message: Scalars['String'];
  threadId: Scalars['String'];
};

export type CreateRecommendationsDto = {
  message?: InputMaybe<Scalars['String']>;
  showId: Scalars['String'];
  toIds: Array<Scalars['String']>;
};

export type CreateSectionListDto = {
  caption?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sectionId: Scalars['String'];
  shows: Array<ShowDetailsArgs>;
};

export type CreateUsersListDto = {
  name: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type DailyStatDto = {
  last?: InputMaybe<Scalars['Float']>;
};

export type DailyStatModel = {
  __typename?: 'DailyStatModel';
  percentage: Scalars['Float'];
  series: Array<StatSeries>;
  total: Scalars['Int'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['Timestamp']>;
  gt?: InputMaybe<Scalars['Timestamp']>;
  gte?: InputMaybe<Scalars['Timestamp']>;
  in?: InputMaybe<Array<Scalars['Timestamp']>>;
  lt?: InputMaybe<Scalars['Timestamp']>;
  lte?: InputMaybe<Scalars['Timestamp']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['Timestamp']>>;
};

export type DeleteDeviceDto = {
  pushToken: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  brand?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  id: Scalars['String'];
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  type: DeviceType;
  updatedAt: Scalars['Timestamp'];
  userId: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};

export type DeviceCountAggregate = {
  __typename?: 'DeviceCountAggregate';
  _all: Scalars['Int'];
  brand: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  manufacturer: Scalars['Int'];
  model: Scalars['Int'];
  name: Scalars['Int'];
  os: Scalars['Int'];
  osVersion: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  year: Scalars['Int'];
};

export type DeviceMaxAggregate = {
  __typename?: 'DeviceMaxAggregate';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  userId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type DeviceMinAggregate = {
  __typename?: 'DeviceMinAggregate';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  userId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export enum DeviceType {
  Desktop = 'DESKTOP',
  Phone = 'PHONE',
  Tablet = 'TABLET',
  Tv = 'TV',
  Unknown = 'UNKNOWN',
}

export type EncryptedContactInput = {
  encryptedDatum: Scalars['String'];
};

export type EnumFolderStatusFilter = {
  equals?: InputMaybe<FolderStatus>;
  in?: InputMaybe<Array<FolderStatus>>;
  not?: InputMaybe<EnumFolderStatusFilter>;
  notIn?: InputMaybe<Array<FolderStatus>>;
};

export type EnumFolderVisibilityFilter = {
  equals?: InputMaybe<FolderVisibility>;
  in?: InputMaybe<Array<FolderVisibility>>;
  not?: InputMaybe<EnumFolderVisibilityFilter>;
  notIn?: InputMaybe<Array<FolderVisibility>>;
};

export type EnumLikeTypeFilter = {
  equals?: InputMaybe<LikeType>;
  in?: InputMaybe<Array<LikeType>>;
  not?: InputMaybe<EnumLikeTypeFilter>;
  notIn?: InputMaybe<Array<LikeType>>;
};

export type EnumMediaTypeFilter = {
  equals?: InputMaybe<MediaType>;
  in?: InputMaybe<Array<MediaType>>;
  not?: InputMaybe<EnumMediaTypeFilter>;
  notIn?: InputMaybe<Array<MediaType>>;
};

export type EnumMediaTypeListFilter = {
  equals?: InputMaybe<Array<MediaType>>;
  has?: InputMaybe<MediaType>;
  hasEvery?: InputMaybe<Array<MediaType>>;
  hasSome?: InputMaybe<Array<MediaType>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumOnboardingStatusFilter = {
  equals?: InputMaybe<OnboardingStatus>;
  in?: InputMaybe<Array<OnboardingStatus>>;
  not?: InputMaybe<EnumOnboardingStatusFilter>;
  notIn?: InputMaybe<Array<OnboardingStatus>>;
};

export type EnumReactionTypeFilter = {
  equals?: InputMaybe<ReactionType>;
  in?: InputMaybe<Array<ReactionType>>;
  not?: InputMaybe<EnumReactionTypeFilter>;
  notIn?: InputMaybe<Array<ReactionType>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<EnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumVerifiedStatusFilter = {
  equals?: InputMaybe<VerifiedStatus>;
  in?: InputMaybe<Array<VerifiedStatus>>;
  not?: InputMaybe<EnumVerifiedStatusFilter>;
  notIn?: InputMaybe<Array<VerifiedStatus>>;
};

export type ExternalIds = {
  __typename?: 'ExternalIds';
  facebookId?: Maybe<Scalars['String']>;
  freebaseId?: Maybe<Scalars['String']>;
  freebaseMid?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  imdbId?: Maybe<Scalars['String']>;
  instagramId?: Maybe<Scalars['String']>;
  tvrageId?: Maybe<Scalars['Float']>;
  twitterId?: Maybe<Scalars['String']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type Folder = {
  __typename?: 'Folder';
  _count: FolderCount;
  country?: Maybe<Country>;
  createdAt: Scalars['Timestamp'];
  firstShow?: Maybe<Show>;
  firstShows: Array<Show>;
  id: Scalars['ID'];
  iso31661?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  searchSectionId?: Maybe<Scalars['String']>;
  shows: PaginatedShow;
  slug: Scalars['String'];
  status: FolderStatus;
  translation?: Maybe<FolderTranslation>;
  updatedAt: Scalars['Timestamp'];
  users: PaginatedUser;
  visibility: FolderVisibility;
  watchProvider?: Maybe<WatchProvider>;
  watchProviderId?: Maybe<Scalars['String']>;
};

export type FolderShowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Array<ShowOrderByWithRelationAndSearchRelevanceInput>>;
  where?: InputMaybe<ShowWhereInput>;
};

export type FolderTranslationArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type FolderUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<UserWhereInput>;
};

export type FolderAvgAggregate = {
  __typename?: 'FolderAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type FolderCount = {
  __typename?: 'FolderCount';
  folderTranslations: Scalars['Int'];
  shows: Scalars['Int'];
  users: Scalars['Int'];
};

export type FolderCountAggregate = {
  __typename?: 'FolderCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  iso31661: Scalars['Int'];
  order: Scalars['Int'];
  searchSectionId: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  visibility: Scalars['Int'];
  watchProviderId: Scalars['Int'];
};

export type FolderEdge = {
  __typename?: 'FolderEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Folder>;
};

export type FolderMaxAggregate = {
  __typename?: 'FolderMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchSectionId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<FolderStatus>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  visibility?: Maybe<FolderVisibility>;
  watchProviderId?: Maybe<Scalars['String']>;
};

export type FolderMinAggregate = {
  __typename?: 'FolderMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchSectionId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<FolderStatus>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  visibility?: Maybe<FolderVisibility>;
  watchProviderId?: Maybe<Scalars['String']>;
};

export type FolderPageInfo = {
  __typename?: 'FolderPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum FolderStatus {
  Error = 'ERROR',
  Filled = 'FILLED',
  Seeding = 'SEEDING',
  Updating = 'UPDATING',
}

export type FolderSumAggregate = {
  __typename?: 'FolderSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type FolderTranslation = {
  __typename?: 'FolderTranslation';
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  folderId: Scalars['String'];
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  title: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type FolderTranslationCountAggregate = {
  __typename?: 'FolderTranslationCountAggregate';
  _all: Scalars['Int'];
  caption: Scalars['Int'];
  createdAt: Scalars['Int'];
  folderId: Scalars['Int'];
  id: Scalars['Int'];
  iso6391: Scalars['Int'];
  iso31661: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FolderTranslationMaxAggregate = {
  __typename?: 'FolderTranslationMaxAggregate';
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  folderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type FolderTranslationMinAggregate = {
  __typename?: 'FolderTranslationMinAggregate';
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  folderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum FolderVisibility {
  Everyone = 'EVERYONE',
  Nobody = 'NOBODY',
}

export type FolderWhereInput = {
  AND?: InputMaybe<Array<FolderWhereInput>>;
  NOT?: InputMaybe<Array<FolderWhereInput>>;
  OR?: InputMaybe<Array<FolderWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  searchSectionId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumFolderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  visibility?: InputMaybe<EnumFolderVisibilityFilter>;
  watchProvider?: InputMaybe<WatchProviderRelationFilter>;
  watchProviderId?: InputMaybe<StringFilter>;
};

export type FolderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Follows = {
  __typename?: 'Follows';
  blocked: Scalars['Boolean'];
  createdAt: Scalars['Timestamp'];
  from: User;
  fromId: Scalars['String'];
  to: User;
  toId: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type FollowsCountAggregate = {
  __typename?: 'FollowsCountAggregate';
  _all: Scalars['Int'];
  blocked: Scalars['Int'];
  createdAt: Scalars['Int'];
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FollowsCreateManyToInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  fromId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type FollowsCreateManyToInputEnvelope = {
  data: Array<FollowsCreateManyToInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FollowsCreateNestedManyWithoutToInput = {
  connect?: InputMaybe<Array<FollowsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FollowsCreateOrConnectWithoutToInput>>;
  create?: InputMaybe<Array<FollowsCreateWithoutToInput>>;
  createMany?: InputMaybe<FollowsCreateManyToInputEnvelope>;
};

export type FollowsCreateOrConnectWithoutToInput = {
  create: FollowsCreateWithoutToInput;
  where: FollowsWhereUniqueInput;
};

export type FollowsCreateWithoutToInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  from: UserCreateNestedOneWithoutFollowingsInput;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type FollowsEdge = {
  __typename?: 'FollowsEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Follows>;
};

export type FollowsFromIdToIdCompoundUniqueInput = {
  fromId: Scalars['String'];
  toId: Scalars['String'];
};

export type FollowsListRelationFilter = {
  every?: InputMaybe<FollowsWhereInput>;
  none?: InputMaybe<FollowsWhereInput>;
  some?: InputMaybe<FollowsWhereInput>;
};

export type FollowsMaxAggregate = {
  __typename?: 'FollowsMaxAggregate';
  blocked?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  toId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type FollowsMinAggregate = {
  __typename?: 'FollowsMinAggregate';
  blocked?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  toId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type FollowsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FollowsPageInfo = {
  __typename?: 'FollowsPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type FollowsScalarWhereInput = {
  AND?: InputMaybe<Array<FollowsScalarWhereInput>>;
  NOT?: InputMaybe<Array<FollowsScalarWhereInput>>;
  OR?: InputMaybe<Array<FollowsScalarWhereInput>>;
  blocked?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fromId?: InputMaybe<StringFilter>;
  toId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FollowsUpdateManyMutationInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type FollowsUpdateManyWithWhereWithoutToInput = {
  data: FollowsUpdateManyMutationInput;
  where: FollowsScalarWhereInput;
};

export type FollowsUpdateManyWithoutToNestedInput = {
  connect?: InputMaybe<Array<FollowsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FollowsCreateOrConnectWithoutToInput>>;
  create?: InputMaybe<Array<FollowsCreateWithoutToInput>>;
  createMany?: InputMaybe<FollowsCreateManyToInputEnvelope>;
  delete?: InputMaybe<Array<FollowsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FollowsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FollowsWhereUniqueInput>>;
  set?: InputMaybe<Array<FollowsWhereUniqueInput>>;
  update?: InputMaybe<Array<FollowsUpdateWithWhereUniqueWithoutToInput>>;
  updateMany?: InputMaybe<Array<FollowsUpdateManyWithWhereWithoutToInput>>;
  upsert?: InputMaybe<Array<FollowsUpsertWithWhereUniqueWithoutToInput>>;
};

export type FollowsUpdateWithWhereUniqueWithoutToInput = {
  data: FollowsUpdateWithoutToInput;
  where: FollowsWhereUniqueInput;
};

export type FollowsUpdateWithoutToInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  from?: InputMaybe<UserUpdateOneRequiredWithoutFollowingsNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type FollowsUpsertWithWhereUniqueWithoutToInput = {
  create: FollowsCreateWithoutToInput;
  update: FollowsUpdateWithoutToInput;
  where: FollowsWhereUniqueInput;
};

export type FollowsWhereInput = {
  AND?: InputMaybe<Array<FollowsWhereInput>>;
  NOT?: InputMaybe<Array<FollowsWhereInput>>;
  OR?: InputMaybe<Array<FollowsWhereInput>>;
  blocked?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  from?: InputMaybe<UserRelationFilter>;
  fromId?: InputMaybe<StringFilter>;
  to?: InputMaybe<UserRelationFilter>;
  toId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FollowsWhereUniqueInput = {
  fromId_toId?: InputMaybe<FollowsFromIdToIdCompoundUniqueInput>;
};

export type ForgotPasswordDto = {
  email: Scalars['String'];
};

export type Friends = {
  __typename?: 'Friends';
  inAppFriends: Array<User>;
  recommendedFriends: Array<User>;
  suggestedAccounts: Array<User>;
};

export type Genre = {
  __typename?: 'Genre';
  _count: GenreCount;
  createdAt: Scalars['Timestamp'];
  emoji: Scalars['String'];
  id: Scalars['ID'];
  shows?: Maybe<Array<Show>>;
  tmdbIds?: Maybe<Array<Scalars['Int']>>;
  translation?: Maybe<GenreTranslation>;
  updatedAt: Scalars['Timestamp'];
};

export type GenreTranslationArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type GenreAvgAggregate = {
  __typename?: 'GenreAvgAggregate';
  tmdbIds?: Maybe<Scalars['Float']>;
};

export type GenreCount = {
  __typename?: 'GenreCount';
  genreTranslations: Scalars['Int'];
  shows: Scalars['Int'];
};

export type GenreCountAggregate = {
  __typename?: 'GenreCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  emoji: Scalars['Int'];
  id: Scalars['Int'];
  tmdbIds: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GenreCreateNestedManyWithoutShowsInput = {
  connect?: InputMaybe<Array<GenreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GenreCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<GenreCreateWithoutShowsInput>>;
};

export type GenreCreateOrConnectWithoutShowsInput = {
  create: GenreCreateWithoutShowsInput;
  where: GenreWhereUniqueInput;
};

export type GenreCreateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  emoji: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type GenreEdge = {
  __typename?: 'GenreEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Genre>;
};

export type GenreListRelationFilter = {
  every?: InputMaybe<GenreWhereInput>;
  none?: InputMaybe<GenreWhereInput>;
  some?: InputMaybe<GenreWhereInput>;
};

export type GenreMaxAggregate = {
  __typename?: 'GenreMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  emoji?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type GenreMinAggregate = {
  __typename?: 'GenreMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  emoji?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type GenreOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GenrePageInfo = {
  __typename?: 'GenrePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type GenreScalarWhereInput = {
  AND?: InputMaybe<Array<GenreScalarWhereInput>>;
  NOT?: InputMaybe<Array<GenreScalarWhereInput>>;
  OR?: InputMaybe<Array<GenreScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  emoji?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  tmdbIds?: InputMaybe<IntListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GenreSumAggregate = {
  __typename?: 'GenreSumAggregate';
  tmdbIds?: Maybe<Array<Scalars['Int']>>;
};

export type GenreTranslation = {
  __typename?: 'GenreTranslation';
  createdAt: Scalars['Timestamp'];
  genreId: Scalars['String'];
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type GenreTranslationCountAggregate = {
  __typename?: 'GenreTranslationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  genreId: Scalars['Int'];
  id: Scalars['Int'];
  iso6391: Scalars['Int'];
  iso31661: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GenreTranslationMaxAggregate = {
  __typename?: 'GenreTranslationMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  genreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type GenreTranslationMinAggregate = {
  __typename?: 'GenreTranslationMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  genreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type GenreUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type GenreUpdateManyWithWhereWithoutShowsInput = {
  data: GenreUpdateManyMutationInput;
  where: GenreScalarWhereInput;
};

export type GenreUpdateManyWithoutShowsNestedInput = {
  connect?: InputMaybe<Array<GenreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GenreCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<GenreCreateWithoutShowsInput>>;
  delete?: InputMaybe<Array<GenreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GenreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GenreWhereUniqueInput>>;
  set?: InputMaybe<Array<GenreWhereUniqueInput>>;
  update?: InputMaybe<Array<GenreUpdateWithWhereUniqueWithoutShowsInput>>;
  updateMany?: InputMaybe<Array<GenreUpdateManyWithWhereWithoutShowsInput>>;
  upsert?: InputMaybe<Array<GenreUpsertWithWhereUniqueWithoutShowsInput>>;
};

export type GenreUpdateWithWhereUniqueWithoutShowsInput = {
  data: GenreUpdateWithoutShowsInput;
  where: GenreWhereUniqueInput;
};

export type GenreUpdateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type GenreUpsertWithWhereUniqueWithoutShowsInput = {
  create: GenreCreateWithoutShowsInput;
  update: GenreUpdateWithoutShowsInput;
  where: GenreWhereUniqueInput;
};

export type GenreWhereInput = {
  AND?: InputMaybe<Array<GenreWhereInput>>;
  NOT?: InputMaybe<Array<GenreWhereInput>>;
  OR?: InputMaybe<Array<GenreWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  emoji?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  shows?: InputMaybe<ShowListRelationFilter>;
  tmdbIds?: InputMaybe<IntListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GenreWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type Keyword = {
  __typename?: 'Keyword';
  _count: KeywordCount;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shows?: Maybe<Array<Show>>;
  tmdbIds?: Maybe<Array<Scalars['Int']>>;
  updatedAt: Scalars['Timestamp'];
};

export type KeywordCount = {
  __typename?: 'KeywordCount';
  shows: Scalars['Int'];
};

export type KeywordCreateNestedManyWithoutShowsInput = {
  connect?: InputMaybe<Array<KeywordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KeywordCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<KeywordCreateWithoutShowsInput>>;
};

export type KeywordCreateOrConnectWithoutShowsInput = {
  create: KeywordCreateWithoutShowsInput;
  where: KeywordWhereUniqueInput;
};

export type KeywordCreateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type KeywordListRelationFilter = {
  every?: InputMaybe<KeywordWhereInput>;
  none?: InputMaybe<KeywordWhereInput>;
  some?: InputMaybe<KeywordWhereInput>;
};

export type KeywordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type KeywordScalarWhereInput = {
  AND?: InputMaybe<Array<KeywordScalarWhereInput>>;
  NOT?: InputMaybe<Array<KeywordScalarWhereInput>>;
  OR?: InputMaybe<Array<KeywordScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tmdbIds?: InputMaybe<IntListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KeywordUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type KeywordUpdateManyWithWhereWithoutShowsInput = {
  data: KeywordUpdateManyMutationInput;
  where: KeywordScalarWhereInput;
};

export type KeywordUpdateManyWithoutShowsNestedInput = {
  connect?: InputMaybe<Array<KeywordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<KeywordCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<KeywordCreateWithoutShowsInput>>;
  delete?: InputMaybe<Array<KeywordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<KeywordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<KeywordWhereUniqueInput>>;
  set?: InputMaybe<Array<KeywordWhereUniqueInput>>;
  update?: InputMaybe<Array<KeywordUpdateWithWhereUniqueWithoutShowsInput>>;
  updateMany?: InputMaybe<Array<KeywordUpdateManyWithWhereWithoutShowsInput>>;
  upsert?: InputMaybe<Array<KeywordUpsertWithWhereUniqueWithoutShowsInput>>;
};

export type KeywordUpdateWithWhereUniqueWithoutShowsInput = {
  data: KeywordUpdateWithoutShowsInput;
  where: KeywordWhereUniqueInput;
};

export type KeywordUpdateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type KeywordUpsertWithWhereUniqueWithoutShowsInput = {
  create: KeywordCreateWithoutShowsInput;
  update: KeywordUpdateWithoutShowsInput;
  where: KeywordWhereUniqueInput;
};

export type KeywordWhereInput = {
  AND?: InputMaybe<Array<KeywordWhereInput>>;
  NOT?: InputMaybe<Array<KeywordWhereInput>>;
  OR?: InputMaybe<Array<KeywordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  shows?: InputMaybe<ShowListRelationFilter>;
  tmdbIds?: InputMaybe<IntListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type KeywordWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  _count: LanguageCount;
  country: Country;
  createdAt: Scalars['Timestamp'];
  englishName: Scalars['String'];
  fallback: Scalars['Boolean'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  name: Scalars['String'];
  rtl: Scalars['Boolean'];
  updatedAt: Scalars['Timestamp'];
};

export type LanguageCount = {
  __typename?: 'LanguageCount';
  folderTranslations: Scalars['Int'];
  genreTranslations: Scalars['Int'];
  moodTranslations: Scalars['Int'];
  searchSectionTranslations: Scalars['Int'];
  showTranslations: Scalars['Int'];
  users: Scalars['Int'];
};

export type LanguageCreateManyCountryInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  fallback: Scalars['Boolean'];
  iso6391: Scalars['String'];
  name: Scalars['String'];
  rtl: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageCreateManyCountryInputEnvelope = {
  data: Array<LanguageCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LanguageCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LanguageCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<LanguageCreateWithoutCountryInput>>;
  createMany?: InputMaybe<LanguageCreateManyCountryInputEnvelope>;
};

export type LanguageCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<LanguageCreateWithoutUsersInput>;
};

export type LanguageCreateOrConnectWithoutCountryInput = {
  create: LanguageCreateWithoutCountryInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateOrConnectWithoutUsersInput = {
  create: LanguageCreateWithoutUsersInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageCreateWithoutCountryInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  fallback: Scalars['Boolean'];
  iso6391: Scalars['String'];
  name: Scalars['String'];
  rtl: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageCreateWithoutUsersInput = {
  country: CountryCreateNestedOneWithoutLanguagesInput;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName: Scalars['String'];
  fallback: Scalars['Boolean'];
  iso6391: Scalars['String'];
  name: Scalars['String'];
  rtl: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageIso31661Iso6391CompoundUniqueInput = {
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
};

export type LanguageListRelationFilter = {
  every?: InputMaybe<LanguageWhereInput>;
  none?: InputMaybe<LanguageWhereInput>;
  some?: InputMaybe<LanguageWhereInput>;
};

export type LanguageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum LanguageOrderByRelevanceFieldEnum {
  EnglishName = 'englishName',
  Iso6391 = 'iso6391',
  Iso31661 = 'iso31661',
  Name = 'name',
}

export type LanguageOrderByRelevanceInput = {
  fields: Array<LanguageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LanguageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<LanguageOrderByRelevanceInput>;
  country?: InputMaybe<CountryOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  englishName?: InputMaybe<SortOrder>;
  fallback?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rtl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LanguageOrderByWithRelationInput = {
  country?: InputMaybe<CountryOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  englishName?: InputMaybe<SortOrder>;
  fallback?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rtl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LanguageRelationFilter = {
  is?: InputMaybe<LanguageWhereInput>;
  isNot?: InputMaybe<LanguageWhereInput>;
};

export type LanguageScalarWhereInput = {
  AND?: InputMaybe<Array<LanguageScalarWhereInput>>;
  NOT?: InputMaybe<Array<LanguageScalarWhereInput>>;
  OR?: InputMaybe<Array<LanguageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  englishName?: InputMaybe<StringFilter>;
  fallback?: InputMaybe<BoolFilter>;
  iso6391?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rtl?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LanguageUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  fallback?: InputMaybe<Scalars['Boolean']>;
  iso6391?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rtl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageUpdateManyWithWhereWithoutCountryInput = {
  data: LanguageUpdateManyMutationInput;
  where: LanguageScalarWhereInput;
};

export type LanguageUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LanguageCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<LanguageCreateWithoutCountryInput>>;
  createMany?: InputMaybe<LanguageCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LanguageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  set?: InputMaybe<Array<LanguageWhereUniqueInput>>;
  update?: InputMaybe<Array<LanguageUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<
    Array<LanguageUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<Array<LanguageUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type LanguageUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<LanguageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LanguageCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<LanguageCreateWithoutUsersInput>;
  update?: InputMaybe<LanguageUpdateWithoutUsersInput>;
  upsert?: InputMaybe<LanguageUpsertWithoutUsersInput>;
};

export type LanguageUpdateWithWhereUniqueWithoutCountryInput = {
  data: LanguageUpdateWithoutCountryInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageUpdateWithoutCountryInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  fallback?: InputMaybe<Scalars['Boolean']>;
  iso6391?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rtl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageUpdateWithoutUsersInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutLanguagesNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  englishName?: InputMaybe<Scalars['String']>;
  fallback?: InputMaybe<Scalars['Boolean']>;
  iso6391?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rtl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type LanguageUpsertWithWhereUniqueWithoutCountryInput = {
  create: LanguageCreateWithoutCountryInput;
  update: LanguageUpdateWithoutCountryInput;
  where: LanguageWhereUniqueInput;
};

export type LanguageUpsertWithoutUsersInput = {
  create: LanguageCreateWithoutUsersInput;
  update: LanguageUpdateWithoutUsersInput;
};

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  englishName?: InputMaybe<StringFilter>;
  fallback?: InputMaybe<BoolFilter>;
  iso6391?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rtl?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LanguageWhereUniqueInput = {
  iso31661_iso6391?: InputMaybe<LanguageIso31661Iso6391CompoundUniqueInput>;
};

export type Like = {
  __typename?: 'Like';
  createdAt: Scalars['Timestamp'];
  from: User;
  fromId: Scalars['String'];
  toId: Scalars['String'];
  toType: LikeType;
  updatedAt: Scalars['Timestamp'];
};

export type LikeCountAggregate = {
  __typename?: 'LikeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  toType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LikeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  fromId?: InputMaybe<SortOrder>;
  toId?: InputMaybe<SortOrder>;
  toType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LikeEdge = {
  __typename?: 'LikeEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Like>;
};

export type LikeMaxAggregate = {
  __typename?: 'LikeMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  toId?: Maybe<Scalars['String']>;
  toType?: Maybe<LikeType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type LikeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  fromId?: InputMaybe<SortOrder>;
  toId?: InputMaybe<SortOrder>;
  toType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LikeMinAggregate = {
  __typename?: 'LikeMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  toId?: Maybe<Scalars['String']>;
  toType?: Maybe<LikeType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type LikeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  fromId?: InputMaybe<SortOrder>;
  toId?: InputMaybe<SortOrder>;
  toType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LikeOrderByWithAggregationInput = {
  _count?: InputMaybe<LikeCountOrderByAggregateInput>;
  _max?: InputMaybe<LikeMaxOrderByAggregateInput>;
  _min?: InputMaybe<LikeMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  fromId?: InputMaybe<SortOrder>;
  toId?: InputMaybe<SortOrder>;
  toType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LikePageInfo = {
  __typename?: 'LikePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum LikeType {
  Activity = 'ACTIVITY',
  Comment = 'COMMENT',
}

export type LikeWhereInput = {
  AND?: InputMaybe<Array<LikeWhereInput>>;
  NOT?: InputMaybe<Array<LikeWhereInput>>;
  OR?: InputMaybe<Array<LikeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  from?: InputMaybe<UserRelationFilter>;
  fromId?: InputMaybe<StringFilter>;
  toId?: InputMaybe<StringFilter>;
  toType?: InputMaybe<EnumLikeTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum MediaType {
  Movie = 'MOVIE',
  Tv = 'TV',
}

export type Message = {
  __typename?: 'Message';
  _count: MessageCount;
  author: Participant;
  authorId: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  message: Scalars['String'];
  seens?: Maybe<Array<Seen>>;
  thread: Thread;
  threadId: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type MessageCount = {
  __typename?: 'MessageCount';
  seens: Scalars['Int'];
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Message>;
};

export type MessagePageInfo = {
  __typename?: 'MessagePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type MonthlyCountModel = {
  __typename?: 'MonthlyCountModel';
  count: Scalars['Int'];
  month: Scalars['String'];
  year: Scalars['String'];
};

export type Mood = {
  __typename?: 'Mood';
  _count: MoodCount;
  createdAt: Scalars['Timestamp'];
  icon: Scalars['String'];
  iconBlurHash: Scalars['String'];
  id: Scalars['ID'];
  shows?: Maybe<Array<Show>>;
  slug: Scalars['String'];
  translation?: Maybe<MoodTranslation>;
  updatedAt: Scalars['Timestamp'];
};

export type MoodTranslationArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MoodCount = {
  __typename?: 'MoodCount';
  moodTranslations: Scalars['Int'];
  shows: Scalars['Int'];
};

export type MoodCreateNestedManyWithoutShowsInput = {
  connect?: InputMaybe<Array<MoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MoodCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<MoodCreateWithoutShowsInput>>;
};

export type MoodCreateOrConnectWithoutShowsInput = {
  create: MoodCreateWithoutShowsInput;
  where: MoodWhereUniqueInput;
};

export type MoodCreateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  icon: Scalars['String'];
  iconBlurHash: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type MoodEdge = {
  __typename?: 'MoodEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Mood>;
};

export type MoodListRelationFilter = {
  every?: InputMaybe<MoodWhereInput>;
  none?: InputMaybe<MoodWhereInput>;
  some?: InputMaybe<MoodWhereInput>;
};

export type MoodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MoodPageInfo = {
  __typename?: 'MoodPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type MoodScalarWhereInput = {
  AND?: InputMaybe<Array<MoodScalarWhereInput>>;
  NOT?: InputMaybe<Array<MoodScalarWhereInput>>;
  OR?: InputMaybe<Array<MoodScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  icon?: InputMaybe<StringFilter>;
  iconBlurHash?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MoodTranslation = {
  __typename?: 'MoodTranslation';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  moodId: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type MoodUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  icon?: InputMaybe<Scalars['String']>;
  iconBlurHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type MoodUpdateManyWithWhereWithoutShowsInput = {
  data: MoodUpdateManyMutationInput;
  where: MoodScalarWhereInput;
};

export type MoodUpdateManyWithoutShowsNestedInput = {
  connect?: InputMaybe<Array<MoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MoodCreateOrConnectWithoutShowsInput>>;
  create?: InputMaybe<Array<MoodCreateWithoutShowsInput>>;
  delete?: InputMaybe<Array<MoodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MoodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MoodWhereUniqueInput>>;
  set?: InputMaybe<Array<MoodWhereUniqueInput>>;
  update?: InputMaybe<Array<MoodUpdateWithWhereUniqueWithoutShowsInput>>;
  updateMany?: InputMaybe<Array<MoodUpdateManyWithWhereWithoutShowsInput>>;
  upsert?: InputMaybe<Array<MoodUpsertWithWhereUniqueWithoutShowsInput>>;
};

export type MoodUpdateWithWhereUniqueWithoutShowsInput = {
  data: MoodUpdateWithoutShowsInput;
  where: MoodWhereUniqueInput;
};

export type MoodUpdateWithoutShowsInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  icon?: InputMaybe<Scalars['String']>;
  iconBlurHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type MoodUpsertWithWhereUniqueWithoutShowsInput = {
  create: MoodCreateWithoutShowsInput;
  update: MoodUpdateWithoutShowsInput;
  where: MoodWhereUniqueInput;
};

export type MoodWhereInput = {
  AND?: InputMaybe<Array<MoodWhereInput>>;
  NOT?: InputMaybe<Array<MoodWhereInput>>;
  OR?: InputMaybe<Array<MoodWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  icon?: InputMaybe<StringFilter>;
  iconBlurHash?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  shows?: InputMaybe<ShowListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MoodWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Movie = {
  __typename?: 'Movie';
  budget?: Maybe<Scalars['Int']>;
  mediaType: MediaType;
  releaseDate?: Maybe<Scalars['Timestamp']>;
  runtime?: Maybe<Scalars['Int']>;
  showId: Scalars['String'];
};

export type MovieCreateNestedOneWithoutShowInput = {
  connect?: InputMaybe<MovieWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MovieCreateOrConnectWithoutShowInput>;
  create?: InputMaybe<MovieCreateWithoutShowInput>;
};

export type MovieCreateOrConnectWithoutShowInput = {
  create: MovieCreateWithoutShowInput;
  where: MovieWhereUniqueInput;
};

export type MovieCreateWithoutShowInput = {
  budget?: InputMaybe<Scalars['Int']>;
  releaseDate?: InputMaybe<Scalars['Timestamp']>;
  runtime?: InputMaybe<Scalars['Int']>;
};

export type MovieItem = {
  __typename?: 'MovieItem';
  adult: Scalars['Boolean'];
  backdropPath?: Maybe<Scalars['String']>;
  genreIds: Array<Scalars['Float']>;
  id: Scalars['Float'];
  mediaType: MediaType;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  releaseDate: Scalars['String'];
  title: Scalars['String'];
  video: Scalars['Boolean'];
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export enum MovieOrderByRelevanceFieldEnum {
  ShowId = 'showId',
}

export type MovieOrderByRelevanceInput = {
  fields: Array<MovieOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MovieOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<MovieOrderByRelevanceInput>;
  budget?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  releaseDate?: InputMaybe<SortOrder>;
  runtime?: InputMaybe<SortOrder>;
  showId?: InputMaybe<SortOrder>;
};

export type MovieRelationFilter = {
  is?: InputMaybe<MovieWhereInput>;
  isNot?: InputMaybe<MovieWhereInput>;
};

export type MovieShowIdMediaTypeCompoundUniqueInput = {
  mediaType: MediaType;
  showId: Scalars['String'];
};

export type MovieTrending = {
  __typename?: 'MovieTrending';
  adult: Scalars['Boolean'];
  backdropPath?: Maybe<Scalars['String']>;
  genreIds: Array<Scalars['Float']>;
  id: Scalars['Float'];
  mediaType: MediaType;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  releaseDate: Scalars['String'];
  title: Scalars['String'];
  video: Scalars['Boolean'];
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export type MovieUpdateOneWithoutShowNestedInput = {
  connect?: InputMaybe<MovieWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MovieCreateOrConnectWithoutShowInput>;
  create?: InputMaybe<MovieCreateWithoutShowInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MovieUpdateWithoutShowInput>;
  upsert?: InputMaybe<MovieUpsertWithoutShowInput>;
};

export type MovieUpdateWithoutShowInput = {
  budget?: InputMaybe<Scalars['Int']>;
  releaseDate?: InputMaybe<Scalars['Timestamp']>;
  runtime?: InputMaybe<Scalars['Int']>;
};

export type MovieUpsertWithoutShowInput = {
  create: MovieCreateWithoutShowInput;
  update: MovieUpdateWithoutShowInput;
};

export type MovieWhereInput = {
  AND?: InputMaybe<Array<MovieWhereInput>>;
  NOT?: InputMaybe<Array<MovieWhereInput>>;
  OR?: InputMaybe<Array<MovieWhereInput>>;
  budget?: InputMaybe<IntFilter>;
  mediaType?: InputMaybe<EnumMediaTypeFilter>;
  releaseDate?: InputMaybe<DateTimeFilter>;
  runtime?: InputMaybe<IntFilter>;
  showId?: InputMaybe<StringFilter>;
};

export type MovieWhereUniqueInput = {
  showId?: InputMaybe<Scalars['String']>;
  showId_mediaType?: InputMaybe<MovieShowIdMediaTypeCompoundUniqueInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  block: User;
  blockActivity: Activity;
  blockComment: Comment;
  challenge: Scalars['String'];
  challengeAdmin: Scalars['String'];
  createActivity: Activity;
  createComment: Comment;
  createDevice?: Maybe<Device>;
  createRecommendation: Recommendation;
  createSectionList: Folder;
  createUser: User;
  createUsersList: Folder;
  deleteActivity: Activity;
  deleteComment: Comment;
  deleteDevice?: Maybe<Device>;
  deleteFolder: Folder;
  deleteMe: User;
  deleteSearchSection: SearchSection;
  deleteUser: User;
  follow: User;
  forgotPassword: Scalars['String'];
  importLetterboxdReviews: User;
  likeActivity: Like;
  likeComment: Like;
  markAllMessagesAsSeen: Thread;
  markAllNotificationsAsRead: Array<Notification>;
  markNotificationAsRead: Notification;
  oauthApple: AuthModel;
  oauthFacebook: AuthModel;
  oauthGoogle: AuthModel;
  oauthTikTok: AuthModel;
  pickWatchProvider: WatchProvider;
  refreshToken: TokensModel;
  registerAdmin: Scalars['String'];
  registerDevice: Device;
  resetPassword: Scalars['String'];
  sendAdminLink: Scalars['String'];
  sendContactsList: User;
  sendMessage: Message;
  sendPushNotifications: Scalars['String'];
  syncWatchProvidersCountry: Array<WatchProvider>;
  unblock: User;
  unfollow: User;
  unlikeActivity: Like;
  unlikeComment: Like;
  unpickWatchProvider: WatchProvider;
  unregisterDevicePushToken?: Maybe<Device>;
  updateActivity: Activity;
  updateComment: Comment;
  updateMe: User;
  updateProfilePicture: User;
  updateUser: User;
  uploadContactList: BatchPayload;
  verify: AuthModel;
  verifyAdmin: AuthAdminModel;
};

export type MutationBlockArgs = {
  id: Scalars['String'];
};

export type MutationBlockActivityArgs = {
  id: Scalars['String'];
};

export type MutationBlockCommentArgs = {
  id: Scalars['String'];
};

export type MutationChallengeArgs = {
  data: ChallengeDto;
};

export type MutationChallengeAdminArgs = {
  data: ChallengeAdminDto;
};

export type MutationCreateActivityArgs = {
  data: CreateActivityDto;
};

export type MutationCreateCommentArgs = {
  dto: CreateCommentDto;
};

export type MutationCreateDeviceArgs = {
  dto: CreateDeviceDto;
};

export type MutationCreateRecommendationArgs = {
  dto: CreateRecommendationsDto;
};

export type MutationCreateSectionListArgs = {
  dto: CreateSectionListDto;
};

export type MutationCreateUserArgs = {
  data: UserCreateInput;
};

export type MutationCreateUsersListArgs = {
  dto: CreateUsersListDto;
};

export type MutationDeleteActivityArgs = {
  where: ActivityWhereUniqueInput;
};

export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};

export type MutationDeleteDeviceArgs = {
  dto: DeleteDeviceDto;
};

export type MutationDeleteFolderArgs = {
  where: FolderWhereUniqueInput;
};

export type MutationDeleteSearchSectionArgs = {
  where: SearchSectionWhereUniqueInput;
};

export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationFollowArgs = {
  id: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  dto: ForgotPasswordDto;
};

export type MutationImportLetterboxdReviewsArgs = {
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationLikeActivityArgs = {
  id: Scalars['String'];
};

export type MutationLikeCommentArgs = {
  id: Scalars['String'];
};

export type MutationMarkAllMessagesAsSeenArgs = {
  threadId: Scalars['String'];
};

export type MutationMarkNotificationAsReadArgs = {
  where: NotificationWhereUniqueInput;
};

export type MutationOauthAppleArgs = {
  accessToken: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationOauthFacebookArgs = {
  accessToken: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationOauthGoogleArgs = {
  accessToken: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationOauthTikTokArgs = {
  accessToken: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationPickWatchProviderArgs = {
  country?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationRegisterAdminArgs = {
  data: RegisterAdminDto;
};

export type MutationRegisterDeviceArgs = {
  dto: RegisterDeviceDto;
};

export type MutationResetPasswordArgs = {
  dto: ResetPasswordDto;
};

export type MutationSendAdminLinkArgs = {
  data: SendAdminLinkDto;
};

export type MutationSendContactsListArgs = {
  dto: SendContactsListDto;
};

export type MutationSendMessageArgs = {
  dto: CreateMessageDto;
};

export type MutationSendPushNotificationsArgs = {
  args: PushNotificationArgs;
};

export type MutationSyncWatchProvidersCountryArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationUnblockArgs = {
  id: Scalars['String'];
};

export type MutationUnfollowArgs = {
  id: Scalars['String'];
};

export type MutationUnlikeActivityArgs = {
  id: Scalars['String'];
};

export type MutationUnlikeCommentArgs = {
  id: Scalars['String'];
};

export type MutationUnpickWatchProviderArgs = {
  id: Scalars['String'];
};

export type MutationUnregisterDevicePushTokenArgs = {
  dto: UnregisterDevicePushTokenDto;
};

export type MutationUpdateActivityArgs = {
  data: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
};

export type MutationUpdateCommentArgs = {
  dto: UpdateCommentDto;
};

export type MutationUpdateMeArgs = {
  data: UserUpdateInput;
};

export type MutationUpdateProfilePictureArgs = {
  file: Scalars['Upload'];
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUploadContactListArgs = {
  dto: ContactListDto;
};

export type MutationVerifyArgs = {
  country?: InputMaybe<Scalars['String']>;
  data: VerifyDto;
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationVerifyAdminArgs = {
  data: VerifyAdminDto;
};

export type NotInAppContact = {
  __typename?: 'NotInAppContact';
  _count: ContactCount;
  associatedUser?: Maybe<User>;
  associatedUserId?: Maybe<Scalars['String']>;
  encryptedDatum: Scalars['String'];
  id: Scalars['ID'];
  mutualContactCount: Scalars['Float'];
  users?: Maybe<Array<User>>;
};

export type NotInAppFriend = {
  __typename?: 'NotInAppFriend';
  inAppContacts: Scalars['Float'];
  name: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  _count: NotificationCount;
  activity?: Maybe<Activity>;
  activityId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  commentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  entityType?: Maybe<NotificationEntityType>;
  from?: Maybe<User>;
  fromId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recommendation?: Maybe<Recommendation>;
  recommendationId?: Maybe<Scalars['String']>;
  show?: Maybe<Show>;
  showId?: Maybe<Scalars['String']>;
  status: NotificationStatus;
  title: Scalars['String'];
  toId: Scalars['String'];
  type: NotificationType;
  updatedAt: Scalars['Timestamp'];
  users?: Maybe<Array<User>>;
};

export type NotificationBodyArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type NotificationTitleArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type NotificationCount = {
  __typename?: 'NotificationCount';
  users: Scalars['Int'];
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  _all: Scalars['Int'];
  activityId: Scalars['Int'];
  commentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityType: Scalars['Int'];
  fromId: Scalars['Int'];
  id: Scalars['Int'];
  recommendationId: Scalars['Int'];
  showId: Scalars['Int'];
  status: Scalars['Int'];
  toId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Notification>;
};

export enum NotificationEntityType {
  Activity = 'ACTIVITY',
  Comment = 'COMMENT',
  Follows = 'FOLLOWS',
  Recommendation = 'RECOMMENDATION',
  Show = 'SHOW',
}

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  activityId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  entityType?: Maybe<NotificationEntityType>;
  fromId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  toId?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  activityId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  entityType?: Maybe<NotificationEntityType>;
  fromId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  recommendationId?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
  toId?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type NotificationPageInfo = {
  __typename?: 'NotificationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum NotificationStatus {
  Read = 'READ',
  Unread = 'UNREAD',
}

export enum NotificationType {
  ActivityCreated = 'ACTIVITY_CREATED',
  ActivityLiked = 'ACTIVITY_LIKED',
  CommentAdded = 'COMMENT_ADDED',
  CommentAnswered = 'COMMENT_ANSWERED',
  CommentLiked = 'COMMENT_LIKED',
  CommentMentionned = 'COMMENT_MENTIONNED',
  FollowsCreated = 'FOLLOWS_CREATED',
  ReactionAdded = 'REACTION_ADDED',
  RecommendationCreated = 'RECOMMENDATION_CREATED',
  RecommendationThanked = 'RECOMMENDATION_THANKED',
  ShowReleased = 'SHOW_RELEASED',
}

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum OnboardingStatus {
  Completed = 'COMPLETED',
  Ongoing = 'ONGOING',
}

export type PaginatedActivity = {
  __typename?: 'PaginatedActivity';
  edges?: Maybe<Array<ActivityEdge>>;
  pageInfo?: Maybe<ActivityPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedAdmin = {
  __typename?: 'PaginatedAdmin';
  edges?: Maybe<Array<AdminEdge>>;
  pageInfo?: Maybe<AdminPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedComment = {
  __typename?: 'PaginatedComment';
  edges?: Maybe<Array<CommentEdge>>;
  pageInfo?: Maybe<CommentPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedContact = {
  __typename?: 'PaginatedContact';
  edges?: Maybe<Array<ContactEdge>>;
  pageInfo?: Maybe<ContactPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedFolder = {
  __typename?: 'PaginatedFolder';
  edges?: Maybe<Array<FolderEdge>>;
  pageInfo?: Maybe<FolderPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedFollows = {
  __typename?: 'PaginatedFollows';
  edges?: Maybe<Array<FollowsEdge>>;
  pageInfo?: Maybe<FollowsPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedGenre = {
  __typename?: 'PaginatedGenre';
  edges?: Maybe<Array<GenreEdge>>;
  pageInfo?: Maybe<GenrePageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedLike = {
  __typename?: 'PaginatedLike';
  edges?: Maybe<Array<LikeEdge>>;
  pageInfo?: Maybe<LikePageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedMessage = {
  __typename?: 'PaginatedMessage';
  edges?: Maybe<Array<MessageEdge>>;
  pageInfo?: Maybe<MessagePageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedMood = {
  __typename?: 'PaginatedMood';
  edges?: Maybe<Array<MoodEdge>>;
  pageInfo?: Maybe<MoodPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedNotification = {
  __typename?: 'PaginatedNotification';
  edges?: Maybe<Array<NotificationEdge>>;
  pageInfo?: Maybe<NotificationPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedRecommendation = {
  __typename?: 'PaginatedRecommendation';
  edges?: Maybe<Array<RecommendationEdge>>;
  pageInfo?: Maybe<RecommendationPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedSearchSection = {
  __typename?: 'PaginatedSearchSection';
  edges?: Maybe<Array<SearchSectionEdge>>;
  pageInfo?: Maybe<SearchSectionPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedShow = {
  __typename?: 'PaginatedShow';
  edges?: Maybe<Array<ShowEdge>>;
  pageInfo?: Maybe<ShowPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedSnoopRecommendation = {
  __typename?: 'PaginatedSnoopRecommendation';
  nodes?: Maybe<Array<Show>>;
  pageInfo?: Maybe<SnoopRecommendationPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  edges?: Maybe<Array<UserEdge>>;
  pageInfo?: Maybe<UserPageInfo>;
  totalCount: Scalars['Float'];
};

export type PaginatedWatchProvider = {
  __typename?: 'PaginatedWatchProvider';
  edges?: Maybe<Array<WatchProviderEdge>>;
  pageInfo?: Maybe<WatchProviderPageInfo>;
  totalCount: Scalars['Float'];
};

export type Participant = {
  __typename?: 'Participant';
  _count: ParticipantCount;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  messages?: Maybe<Array<Message>>;
  seens?: Maybe<Array<Seen>>;
  thread: Thread;
  threadId: Scalars['String'];
  type: ParticipantType;
  updatedAt: Scalars['Timestamp'];
  user: User;
  userId: Scalars['String'];
};

export type ParticipantCount = {
  __typename?: 'ParticipantCount';
  messages: Scalars['Int'];
  seens: Scalars['Int'];
};

export enum ParticipantType {
  Admin = 'ADMIN',
  Moderator = 'MODERATOR',
  Participant = 'PARTICIPANT',
}

export type PhoneContact = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Timestamp']>;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  picture?: Maybe<Scalars['String']>;
  pictureBlurHash?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

export type ProfileCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfileCreateWithoutUserInput>;
};

export type ProfileCreateOrConnectWithoutUserInput = {
  create: ProfileCreateWithoutUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['Timestamp']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  pictureBlurHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export enum ProfileOrderByRelevanceFieldEnum {
  Bio = 'bio',
  Id = 'id',
  Picture = 'picture',
  PictureBlurHash = 'pictureBlurHash',
}

export type ProfileOrderByRelevanceInput = {
  fields: Array<ProfileOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ProfileOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ProfileOrderByRelevanceInput>;
  bio?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picture?: InputMaybe<SortOrder>;
  pictureBlurHash?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileOrderByWithRelationInput = {
  bio?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  picture?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateOneRequiredWithoutUserNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ProfileCreateWithoutUserInput>;
  update?: InputMaybe<ProfileUpdateWithoutUserInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutUserInput>;
};

export type ProfileUpdateWithoutUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['Timestamp']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  pictureBlurHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ProfileUpsertWithoutUserInput = {
  create: ProfileCreateWithoutUserInput;
  update: ProfileUpdateWithoutUserInput;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  bio?: InputMaybe<StringFilter>;
  birthday?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  picture?: InputMaybe<StringFilter>;
  pictureBlurHash?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PushNotificationArgs = {
  body: Scalars['String'];
  priority: PushNotificationPriority;
  /** iOS only */
  subtitle?: InputMaybe<Scalars['String']>;
  target: PushNotificationTargetInput;
  title: Scalars['String'];
};

/** Push notification priority */
export enum PushNotificationPriority {
  Default = 'DEFAULT',
  High = 'HIGH',
  Normal = 'NORMAL',
}

/** Push notification recipient */
export enum PushNotificationRecipient {
  Everyone = 'EVERYONE',
  Inactive = 'INACTIVE',
}

export type PushNotificationTargetInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  recipientStatus?: InputMaybe<PushNotificationRecipient>;
};

export type Query = {
  __typename?: 'Query';
  activity: Activity;
  activityReactions: PaginatedActivity;
  admins: PaginatedAdmin;
  artist: Artist;
  comments: PaginatedComment;
  contacts: PaginatedContact;
  countUnreadMessages: Scalars['Float'];
  countUnreadNotifications: Scalars['Float'];
  countUsers: Scalars['Float'];
  feedActivities: PaginatedActivity;
  folder: Folder;
  folders: PaginatedFolder;
  followers: PaginatedFollows;
  friends: Friends;
  friendsTrendings: Array<Show>;
  generateSharePoster: Scalars['String'];
  generateUsername: Scalars['String'];
  genres: PaginatedGenre;
  getMostActiveUsers: PaginatedUser;
  getThreadMessages: PaginatedMessage;
  likes: PaginatedLike;
  me: User;
  meAdmin: Admin;
  mediaSuggestions: PaginatedUser;
  mediasSuggestions: PaginatedUser;
  moods: PaginatedMood;
  mostActiveUsers: PaginatedUser;
  notInAppContacts: Array<NotInAppContact>;
  notInAppFriends: Array<NotInAppFriend>;
  notifications: PaginatedNotification;
  profileActivities: PaginatedActivity;
  receivedRecommendations: PaginatedRecommendation;
  recommendedFriends: PaginatedUser;
  recommendedUsers: PaginatedUser;
  search: Array<SearchResult>;
  searchSection: SearchSection;
  searchSections: PaginatedSearchSection;
  sentRecommendations: PaginatedRecommendation;
  showActivities: PaginatedActivity;
  showDetails: Show;
  showReactions: PaginatedActivity;
  showRecommendations: Array<ShowItem>;
  showTrendings: Array<ShowTrending>;
  shows: PaginatedShow;
  snoopRecommendations: PaginatedSnoopRecommendation;
  stats: Stats;
  threadMessages: PaginatedMessage;
  trendingFollowings: PaginatedUser;
  user: User;
  userSuggestions: UserSuggestions;
  usernameAvailable: Scalars['Boolean'];
  users: PaginatedUser;
  verifyAdminToken: Scalars['String'];
  watchProviders: PaginatedWatchProvider;
};

export type QueryActivityArgs = {
  where: ActivityWhereUniqueInput;
};

export type QueryActivityReactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  where: ActivityWhereUniqueInput;
};

export type QueryAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryArtistArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  personId: Scalars['Float'];
};

export type QueryCommentsArgs = {
  activityId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryFeedActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<
    Array<ActivityOrderByWithRelationAndSearchRelevanceInput>
  >;
  where?: InputMaybe<ActivityWhereInput>;
};

export type QueryFolderArgs = {
  where: FolderWhereInput;
};

export type QueryFoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryFollowersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  where: FollowsWhereInput;
};

export type QueryFriendsTrendingsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type QueryGenerateSharePosterArgs = {
  showId: Scalars['String'];
};

export type QueryGenerateUsernameArgs = {
  name: Scalars['String'];
};

export type QueryGenresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GenreWhereInput>;
};

export type QueryGetMostActiveUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryGetThreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  threadId: Scalars['String'];
};

export type QueryLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Array<LikeOrderByWithAggregationInput>>;
  where?: InputMaybe<LikeWhereInput>;
};

export type QueryMediaSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryMediasSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryMoodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<MoodWhereInput>;
};

export type QueryMostActiveUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryProfileActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<
    Array<ActivityOrderByWithRelationAndSearchRelevanceInput>
  >;
  where?: InputMaybe<ActivityWhereInput>;
};

export type QueryReceivedRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryRecommendedFriendsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryRecommendedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QuerySearchArgs = {
  imageSize?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type QuerySearchSectionArgs = {
  where: SearchSectionWhereUniqueInput;
};

export type QuerySearchSectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<SearchSectionOrderByWithRelationInput>>;
  where?: InputMaybe<SearchSectionWhereInput>;
};

export type QuerySentRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryShowActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<
    Array<ActivityOrderByWithRelationAndSearchRelevanceInput>
  >;
  where?: InputMaybe<ActivityWhereInput>;
};

export type QueryShowDetailsArgs = {
  showDetailsArgs: ShowDetailsArgs;
};

export type QueryShowReactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  reactionType?: InputMaybe<ReactionType>;
  where: ShowWhereUniqueInput;
};

export type QueryShowRecommendationsArgs = {
  country?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  showDetailsArgs: ShowDetailsArgs;
};

export type QueryShowTrendingsArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  showTrendingsArgs: ShowTrendingsArgs;
};

export type QueryShowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<ShowOrderByWithAggregationInput>>;
  where: ShowWhereInput;
};

export type QuerySnoopRecommendationsArgs = {
  args?: InputMaybe<SnoopRecommendationsArgs>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
};

export type QueryThreadMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  threadId: Scalars['String'];
};

export type QueryTrendingFollowingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsernameAvailableArgs = {
  username: Scalars['String'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryVerifyAdminTokenArgs = {
  token: Scalars['String'];
};

export type QueryWatchProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Array<WatchProviderOrderByWithRelationInput>>;
  where?: InputMaybe<WatchProviderWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type Reaction = {
  __typename?: 'Reaction';
  activities: PaginatedActivity;
  type: ReactionType;
};

export enum ReactionType {
  Favorite = 'FAVORITE',
  Flop = 'FLOP',
  Recommended = 'RECOMMENDED',
  Seen = 'SEEN',
  SeeLater = 'SEE_LATER',
  Thanks = 'THANKS',
}

export type Recommendation = {
  __typename?: 'Recommendation';
  _count: RecommendationCount;
  createdAt: Scalars['Timestamp'];
  from: User;
  fromId: Scalars['String'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  show: Show;
  showId: Scalars['String'];
  thread: Thread;
  threadId: Scalars['String'];
  toUsers?: Maybe<Array<User>>;
  updatedAt: Scalars['Timestamp'];
};

export type RecommendationCount = {
  __typename?: 'RecommendationCount';
  activities: Scalars['Int'];
  notifications: Scalars['Int'];
  toUsers: Scalars['Int'];
};

export type RecommendationCountAggregate = {
  __typename?: 'RecommendationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  fromId: Scalars['Int'];
  id: Scalars['Int'];
  message: Scalars['Int'];
  showId: Scalars['Int'];
  threadId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RecommendationCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<RecommendationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecommendationCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<RecommendationCreateWithoutActivitiesInput>;
};

export type RecommendationCreateOrConnectWithoutActivitiesInput = {
  create: RecommendationCreateWithoutActivitiesInput;
  where: RecommendationWhereUniqueInput;
};

export type RecommendationCreateWithoutActivitiesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  show: ShowCreateNestedOneWithoutRecommendationsInput;
  toUsers?: InputMaybe<UserCreateNestedManyWithoutReceivedRecommendationsInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type RecommendationEdge = {
  __typename?: 'RecommendationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Recommendation>;
};

export type RecommendationMaxAggregate = {
  __typename?: 'RecommendationMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type RecommendationMinAggregate = {
  __typename?: 'RecommendationMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  fromId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  showId?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export enum RecommendationOrderByRelevanceFieldEnum {
  FromId = 'fromId',
  Id = 'id',
  Message = 'message',
  ShowId = 'showId',
  ThreadId = 'threadId',
}

export type RecommendationOrderByRelevanceInput = {
  fields: Array<RecommendationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type RecommendationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RecommendationOrderByRelevanceInput>;
  createdAt?: InputMaybe<SortOrder>;
  fromId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  show?: InputMaybe<ShowOrderByWithRelationAndSearchRelevanceInput>;
  showId?: InputMaybe<SortOrder>;
  threadId?: InputMaybe<SortOrder>;
  toUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RecommendationPageInfo = {
  __typename?: 'RecommendationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type RecommendationRelationFilter = {
  is?: InputMaybe<RecommendationWhereInput>;
  isNot?: InputMaybe<RecommendationWhereInput>;
};

export type RecommendationUpdateOneWithoutActivitiesNestedInput = {
  connect?: InputMaybe<RecommendationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RecommendationCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<RecommendationCreateWithoutActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RecommendationUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<RecommendationUpsertWithoutActivitiesInput>;
};

export type RecommendationUpdateWithoutActivitiesInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  show?: InputMaybe<ShowUpdateOneRequiredWithoutRecommendationsNestedInput>;
  toUsers?: InputMaybe<UserUpdateManyWithoutReceivedRecommendationsNestedInput>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type RecommendationUpsertWithoutActivitiesInput = {
  create: RecommendationCreateWithoutActivitiesInput;
  update: RecommendationUpdateWithoutActivitiesInput;
};

export type RecommendationWhereInput = {
  AND?: InputMaybe<Array<RecommendationWhereInput>>;
  NOT?: InputMaybe<Array<RecommendationWhereInput>>;
  OR?: InputMaybe<Array<RecommendationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fromId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  show?: InputMaybe<ShowRelationFilter>;
  showId?: InputMaybe<StringFilter>;
  threadId?: InputMaybe<StringFilter>;
  toUsers?: InputMaybe<UserListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RecommendationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  threadId?: InputMaybe<Scalars['String']>;
};

export type RegisterAdminDto = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  token: Scalars['String'];
};

export type RegisterDeviceDto = {
  brand?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  id: Scalars['String'];
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  pushToken?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DeviceType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  year?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordDto = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum SearchMediaType {
  Movie = 'MOVIE',
  Person = 'PERSON',
  Tv = 'TV',
}

export type SearchMovieMedia = {
  __typename?: 'SearchMovieMedia';
  adult: Scalars['Boolean'];
  backdropPath?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  mediaType: SearchMediaType;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video: Scalars['Boolean'];
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export type SearchPersonMedia = {
  __typename?: 'SearchPersonMedia';
  adult: Scalars['Boolean'];
  id: Scalars['Float'];
  mediaType: SearchMediaType;
  name: Scalars['String'];
  popularity: Scalars['Float'];
  profilePath?: Maybe<Scalars['String']>;
};

export type SearchResult = SearchMovieMedia | SearchPersonMedia | SearchTvMedia;

export type SearchSection = {
  __typename?: 'SearchSection';
  _count: SearchSectionCount;
  createdAt: Scalars['Timestamp'];
  curratedBy?: Maybe<User>;
  curratedById?: Maybe<Scalars['String']>;
  folders: Array<Folder>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  slug: Scalars['String'];
  translation?: Maybe<SearchSectionTranslation>;
  updatedAt: Scalars['Timestamp'];
};

export type SearchSectionFoldersArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type SearchSectionTranslationArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type SearchSectionAvgAggregate = {
  __typename?: 'SearchSectionAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type SearchSectionCount = {
  __typename?: 'SearchSectionCount';
  folders: Scalars['Int'];
  searchSectionTranslations: Scalars['Int'];
};

export type SearchSectionCountAggregate = {
  __typename?: 'SearchSectionCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  curratedById: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  slug: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SearchSectionEdge = {
  __typename?: 'SearchSectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SearchSection>;
};

export type SearchSectionMaxAggregate = {
  __typename?: 'SearchSectionMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  curratedById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type SearchSectionMinAggregate = {
  __typename?: 'SearchSectionMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  curratedById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type SearchSectionOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  curratedBy?: InputMaybe<UserOrderByWithRelationInput>;
  curratedById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SearchSectionPageInfo = {
  __typename?: 'SearchSectionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type SearchSectionSumAggregate = {
  __typename?: 'SearchSectionSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type SearchSectionTranslation = {
  __typename?: 'SearchSectionTranslation';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  searchSectionId: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type SearchSectionTranslationCountAggregate = {
  __typename?: 'SearchSectionTranslationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  iso6391: Scalars['Int'];
  iso31661: Scalars['Int'];
  searchSectionId: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SearchSectionTranslationMaxAggregate = {
  __typename?: 'SearchSectionTranslationMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  searchSectionId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type SearchSectionTranslationMinAggregate = {
  __typename?: 'SearchSectionTranslationMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  searchSectionId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type SearchSectionWhereInput = {
  AND?: InputMaybe<Array<SearchSectionWhereInput>>;
  NOT?: InputMaybe<Array<SearchSectionWhereInput>>;
  OR?: InputMaybe<Array<SearchSectionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  curratedBy?: InputMaybe<UserRelationFilter>;
  curratedById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SearchSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type SearchTvMedia = {
  __typename?: 'SearchTvMedia';
  backdropPath?: Maybe<Scalars['String']>;
  firstAirDate?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  mediaType: SearchMediaType;
  name: Scalars['String'];
  originCountry: Array<Scalars['String']>;
  originalLanguage: Scalars['String'];
  originalName: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export type Seen = {
  __typename?: 'Seen';
  message: Message;
  messageId: Scalars['String'];
  participant: Participant;
  participantId: Scalars['String'];
  seenAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type SendAdminLinkDto = {
  email: Scalars['String'];
};

export type SendContactsListDto = {
  contactsList: Array<PhoneContact>;
};

export type Serie = {
  __typename?: 'Serie';
  episodeRuntime?: Maybe<Scalars['Int']>;
  firstAirDate?: Maybe<Scalars['Timestamp']>;
  inProduction: Scalars['Boolean'];
  lastAirDate?: Maybe<Scalars['Timestamp']>;
  mediaType: MediaType;
  numberOfEpisodes: Scalars['Int'];
  numberOfSeasons: Scalars['Int'];
  showId: Scalars['String'];
};

export type SerieCreateNestedOneWithoutShowInput = {
  connect?: InputMaybe<SerieWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SerieCreateOrConnectWithoutShowInput>;
  create?: InputMaybe<SerieCreateWithoutShowInput>;
};

export type SerieCreateOrConnectWithoutShowInput = {
  create: SerieCreateWithoutShowInput;
  where: SerieWhereUniqueInput;
};

export type SerieCreateWithoutShowInput = {
  episodeRuntime?: InputMaybe<Scalars['Int']>;
  firstAirDate?: InputMaybe<Scalars['Timestamp']>;
  inProduction: Scalars['Boolean'];
  lastAirDate?: InputMaybe<Scalars['Timestamp']>;
  numberOfEpisodes: Scalars['Int'];
  numberOfSeasons: Scalars['Int'];
};

export enum SerieOrderByRelevanceFieldEnum {
  ShowId = 'showId',
}

export type SerieOrderByRelevanceInput = {
  fields: Array<SerieOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SerieOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SerieOrderByRelevanceInput>;
  episodeRuntime?: InputMaybe<SortOrder>;
  firstAirDate?: InputMaybe<SortOrder>;
  inProduction?: InputMaybe<SortOrder>;
  lastAirDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  numberOfEpisodes?: InputMaybe<SortOrder>;
  numberOfSeasons?: InputMaybe<SortOrder>;
  showId?: InputMaybe<SortOrder>;
};

export type SerieRelationFilter = {
  is?: InputMaybe<SerieWhereInput>;
  isNot?: InputMaybe<SerieWhereInput>;
};

export type SerieShowIdMediaTypeCompoundUniqueInput = {
  mediaType: MediaType;
  showId: Scalars['String'];
};

export type SerieUpdateOneWithoutShowNestedInput = {
  connect?: InputMaybe<SerieWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SerieCreateOrConnectWithoutShowInput>;
  create?: InputMaybe<SerieCreateWithoutShowInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SerieUpdateWithoutShowInput>;
  upsert?: InputMaybe<SerieUpsertWithoutShowInput>;
};

export type SerieUpdateWithoutShowInput = {
  episodeRuntime?: InputMaybe<Scalars['Int']>;
  firstAirDate?: InputMaybe<Scalars['Timestamp']>;
  inProduction?: InputMaybe<Scalars['Boolean']>;
  lastAirDate?: InputMaybe<Scalars['Timestamp']>;
  numberOfEpisodes?: InputMaybe<Scalars['Int']>;
  numberOfSeasons?: InputMaybe<Scalars['Int']>;
};

export type SerieUpsertWithoutShowInput = {
  create: SerieCreateWithoutShowInput;
  update: SerieUpdateWithoutShowInput;
};

export type SerieWhereInput = {
  AND?: InputMaybe<Array<SerieWhereInput>>;
  NOT?: InputMaybe<Array<SerieWhereInput>>;
  OR?: InputMaybe<Array<SerieWhereInput>>;
  episodeRuntime?: InputMaybe<IntFilter>;
  firstAirDate?: InputMaybe<DateTimeFilter>;
  inProduction?: InputMaybe<BoolFilter>;
  lastAirDate?: InputMaybe<DateTimeFilter>;
  mediaType?: InputMaybe<EnumMediaTypeFilter>;
  numberOfEpisodes?: InputMaybe<IntFilter>;
  numberOfSeasons?: InputMaybe<IntFilter>;
  showId?: InputMaybe<StringFilter>;
};

export type SerieWhereUniqueInput = {
  showId?: InputMaybe<Scalars['String']>;
  showId_mediaType?: InputMaybe<SerieShowIdMediaTypeCompoundUniqueInput>;
};

export type Show = {
  __typename?: 'Show';
  _count: ShowCount;
  allocineId?: Maybe<Scalars['String']>;
  backdrop?: Maybe<Scalars['String']>;
  backdropBlurHash?: Maybe<Scalars['String']>;
  cast: ShowCast;
  createdAt: Scalars['Timestamp'];
  firstFollowingsReactions: PaginatedActivity;
  genres?: Maybe<Array<Genre>>;
  id: Scalars['ID'];
  imdbId?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Keyword>>;
  mediaDate?: Maybe<Scalars['Timestamp']>;
  mediaType: MediaType;
  moods?: Maybe<Array<Mood>>;
  movie?: Maybe<Movie>;
  myReaction?: Maybe<Activity>;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  popularity: Scalars['Float'];
  poster?: Maybe<Scalars['String']>;
  posterBlurHash?: Maybe<Scalars['String']>;
  reactions: Array<Reaction>;
  serie?: Maybe<Serie>;
  showWatchProviderCountries?: Maybe<Array<ShowWatchProviderCountry>>;
  spokenLanguages: Scalars['JSON'];
  status: Scalars['String'];
  tmdbId: Scalars['Int'];
  trailer?: Maybe<ShowTrailer>;
  translation?: Maybe<ShowTranslation>;
  updatedAt: Scalars['Timestamp'];
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Int'];
  watchProviders?: Maybe<Array<ShowWatchProvider>>;
};

export type ShowCastArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ShowTrailerArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ShowTranslationArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ShowWatchProvidersArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ShowArtist = {
  __typename?: 'ShowArtist';
  adult: Scalars['Boolean'];
  castId: Scalars['Float'];
  character: Scalars['String'];
  creditId: Scalars['String'];
  gender: Scalars['Float'];
  id: Scalars['Float'];
  knownForDepartment: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  originalName: Scalars['String'];
  popularity: Scalars['Float'];
  profilePath?: Maybe<Scalars['String']>;
};

export type ShowAvgAggregate = {
  __typename?: 'ShowAvgAggregate';
  popularity?: Maybe<Scalars['Float']>;
  tmdbId?: Maybe<Scalars['Float']>;
  voteAverage?: Maybe<Scalars['Float']>;
  voteCount?: Maybe<Scalars['Float']>;
};

export type ShowAvgOrderByAggregateInput = {
  popularity?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowCast = {
  __typename?: 'ShowCast';
  artists: Array<ShowArtist>;
  director?: Maybe<ShowHead>;
};

export type ShowCount = {
  __typename?: 'ShowCount';
  activities: Scalars['Int'];
  folders: Scalars['Int'];
  genres: Scalars['Int'];
  keywords: Scalars['Int'];
  moods: Scalars['Int'];
  notifications: Scalars['Int'];
  recommendations: Scalars['Int'];
  showTranslations: Scalars['Int'];
  showWatchProviderCountries: Scalars['Int'];
};

export type ShowCountAggregate = {
  __typename?: 'ShowCountAggregate';
  _all: Scalars['Int'];
  allocineId: Scalars['Int'];
  backdrop: Scalars['Int'];
  backdropBlurHash: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  imdbId: Scalars['Int'];
  mediaDate: Scalars['Int'];
  mediaType: Scalars['Int'];
  originalLanguage: Scalars['Int'];
  originalTitle: Scalars['Int'];
  popularity: Scalars['Int'];
  poster: Scalars['Int'];
  posterBlurHash: Scalars['Int'];
  spokenLanguages: Scalars['Int'];
  status: Scalars['Int'];
  tmdbId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  voteAverage: Scalars['Int'];
  voteCount: Scalars['Int'];
};

export type ShowCountOrderByAggregateInput = {
  allocineId?: InputMaybe<SortOrder>;
  backdrop?: InputMaybe<SortOrder>;
  backdropBlurHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imdbId?: InputMaybe<SortOrder>;
  mediaDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  originalLanguage?: InputMaybe<SortOrder>;
  originalTitle?: InputMaybe<SortOrder>;
  popularity?: InputMaybe<SortOrder>;
  poster?: InputMaybe<SortOrder>;
  posterBlurHash?: InputMaybe<SortOrder>;
  spokenLanguages?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<ShowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShowCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ShowCreateWithoutActivitiesInput>;
};

export type ShowCreateNestedOneWithoutRecommendationsInput = {
  connect?: InputMaybe<ShowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShowCreateOrConnectWithoutRecommendationsInput>;
  create?: InputMaybe<ShowCreateWithoutRecommendationsInput>;
};

export type ShowCreateOrConnectWithoutActivitiesInput = {
  create: ShowCreateWithoutActivitiesInput;
  where: ShowWhereUniqueInput;
};

export type ShowCreateOrConnectWithoutRecommendationsInput = {
  create: ShowCreateWithoutRecommendationsInput;
  where: ShowWhereUniqueInput;
};

export type ShowCreateWithoutActivitiesInput = {
  allocineId?: InputMaybe<Scalars['String']>;
  backdrop?: InputMaybe<Scalars['String']>;
  backdropBlurHash?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  genres?: InputMaybe<GenreCreateNestedManyWithoutShowsInput>;
  id?: InputMaybe<Scalars['String']>;
  imdbId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<KeywordCreateNestedManyWithoutShowsInput>;
  mediaDate?: InputMaybe<Scalars['Timestamp']>;
  mediaType: MediaType;
  moods?: InputMaybe<MoodCreateNestedManyWithoutShowsInput>;
  movie?: InputMaybe<MovieCreateNestedOneWithoutShowInput>;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  popularity: Scalars['Float'];
  poster?: InputMaybe<Scalars['String']>;
  posterBlurHash?: InputMaybe<Scalars['String']>;
  serie?: InputMaybe<SerieCreateNestedOneWithoutShowInput>;
  showWatchProviderCountries?: InputMaybe<ShowWatchProviderCountryCreateNestedManyWithoutShowInput>;
  spokenLanguages: Scalars['JSON'];
  status: Scalars['String'];
  tmdbId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Int'];
};

export type ShowCreateWithoutRecommendationsInput = {
  allocineId?: InputMaybe<Scalars['String']>;
  backdrop?: InputMaybe<Scalars['String']>;
  backdropBlurHash?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  genres?: InputMaybe<GenreCreateNestedManyWithoutShowsInput>;
  id?: InputMaybe<Scalars['String']>;
  imdbId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<KeywordCreateNestedManyWithoutShowsInput>;
  mediaDate?: InputMaybe<Scalars['Timestamp']>;
  mediaType: MediaType;
  moods?: InputMaybe<MoodCreateNestedManyWithoutShowsInput>;
  movie?: InputMaybe<MovieCreateNestedOneWithoutShowInput>;
  originalLanguage: Scalars['String'];
  originalTitle: Scalars['String'];
  popularity: Scalars['Float'];
  poster?: InputMaybe<Scalars['String']>;
  posterBlurHash?: InputMaybe<Scalars['String']>;
  serie?: InputMaybe<SerieCreateNestedOneWithoutShowInput>;
  showWatchProviderCountries?: InputMaybe<ShowWatchProviderCountryCreateNestedManyWithoutShowInput>;
  spokenLanguages: Scalars['JSON'];
  status: Scalars['String'];
  tmdbId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Int'];
};

export type ShowCreator = {
  __typename?: 'ShowCreator';
  creditId: Scalars['String'];
  gender: Scalars['Float'];
  id: Scalars['Float'];
  name: Scalars['String'];
  profilePath?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ShowDetailsArgs = {
  mediaType: MediaType;
  tmdbId: Scalars['Float'];
};

export type ShowDirector = {
  __typename?: 'ShowDirector';
  adult: Scalars['Boolean'];
  creditId: Scalars['String'];
  department: Scalars['String'];
  gender: Scalars['Float'];
  id: Scalars['Float'];
  job: Scalars['String'];
  knownForDepartment: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  popularity: Scalars['Float'];
  profilePath?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ShowEdge = {
  __typename?: 'ShowEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Show>;
};

export type ShowHead = ShowCreator | ShowDirector;

export type ShowIdMediaTypeCompoundUniqueInput = {
  id: Scalars['String'];
  mediaType: MediaType;
};

export type ShowItem = MovieItem | TvItem;

export type ShowListRelationFilter = {
  every?: InputMaybe<ShowWhereInput>;
  none?: InputMaybe<ShowWhereInput>;
  some?: InputMaybe<ShowWhereInput>;
};

export type ShowMaxAggregate = {
  __typename?: 'ShowMaxAggregate';
  allocineId?: Maybe<Scalars['String']>;
  backdrop?: Maybe<Scalars['String']>;
  backdropBlurHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  imdbId?: Maybe<Scalars['String']>;
  mediaDate?: Maybe<Scalars['Timestamp']>;
  mediaType?: Maybe<MediaType>;
  originalLanguage?: Maybe<Scalars['String']>;
  originalTitle?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Float']>;
  poster?: Maybe<Scalars['String']>;
  posterBlurHash?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tmdbId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  voteAverage?: Maybe<Scalars['Float']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type ShowMaxOrderByAggregateInput = {
  allocineId?: InputMaybe<SortOrder>;
  backdrop?: InputMaybe<SortOrder>;
  backdropBlurHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imdbId?: InputMaybe<SortOrder>;
  mediaDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  originalLanguage?: InputMaybe<SortOrder>;
  originalTitle?: InputMaybe<SortOrder>;
  popularity?: InputMaybe<SortOrder>;
  poster?: InputMaybe<SortOrder>;
  posterBlurHash?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowMinAggregate = {
  __typename?: 'ShowMinAggregate';
  allocineId?: Maybe<Scalars['String']>;
  backdrop?: Maybe<Scalars['String']>;
  backdropBlurHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  id?: Maybe<Scalars['String']>;
  imdbId?: Maybe<Scalars['String']>;
  mediaDate?: Maybe<Scalars['Timestamp']>;
  mediaType?: Maybe<MediaType>;
  originalLanguage?: Maybe<Scalars['String']>;
  originalTitle?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Float']>;
  poster?: Maybe<Scalars['String']>;
  posterBlurHash?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tmdbId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  voteAverage?: Maybe<Scalars['Float']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type ShowMinOrderByAggregateInput = {
  allocineId?: InputMaybe<SortOrder>;
  backdrop?: InputMaybe<SortOrder>;
  backdropBlurHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imdbId?: InputMaybe<SortOrder>;
  mediaDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  originalLanguage?: InputMaybe<SortOrder>;
  originalTitle?: InputMaybe<SortOrder>;
  popularity?: InputMaybe<SortOrder>;
  poster?: InputMaybe<SortOrder>;
  posterBlurHash?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export enum ShowOrderByRelevanceFieldEnum {
  AllocineId = 'allocineId',
  Backdrop = 'backdrop',
  BackdropBlurHash = 'backdropBlurHash',
  Id = 'id',
  ImdbId = 'imdbId',
  OriginalLanguage = 'originalLanguage',
  OriginalTitle = 'originalTitle',
  Poster = 'poster',
  PosterBlurHash = 'posterBlurHash',
  Status = 'status',
}

export type ShowOrderByRelevanceInput = {
  fields: Array<ShowOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ShowOrderByWithAggregationInput = {
  _avg?: InputMaybe<ShowAvgOrderByAggregateInput>;
  _count?: InputMaybe<ShowCountOrderByAggregateInput>;
  _max?: InputMaybe<ShowMaxOrderByAggregateInput>;
  _min?: InputMaybe<ShowMinOrderByAggregateInput>;
  _sum?: InputMaybe<ShowSumOrderByAggregateInput>;
  allocineId?: InputMaybe<SortOrder>;
  backdrop?: InputMaybe<SortOrder>;
  backdropBlurHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imdbId?: InputMaybe<SortOrder>;
  mediaDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  originalLanguage?: InputMaybe<SortOrder>;
  originalTitle?: InputMaybe<SortOrder>;
  popularity?: InputMaybe<SortOrder>;
  poster?: InputMaybe<SortOrder>;
  posterBlurHash?: InputMaybe<SortOrder>;
  spokenLanguages?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ShowOrderByRelevanceInput>;
  allocineId?: InputMaybe<SortOrder>;
  backdrop?: InputMaybe<SortOrder>;
  backdropBlurHash?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  genres?: InputMaybe<GenreOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  imdbId?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<KeywordOrderByRelationAggregateInput>;
  mediaDate?: InputMaybe<SortOrder>;
  mediaType?: InputMaybe<SortOrder>;
  moods?: InputMaybe<MoodOrderByRelationAggregateInput>;
  movie?: InputMaybe<MovieOrderByWithRelationAndSearchRelevanceInput>;
  originalLanguage?: InputMaybe<SortOrder>;
  originalTitle?: InputMaybe<SortOrder>;
  popularity?: InputMaybe<SortOrder>;
  poster?: InputMaybe<SortOrder>;
  posterBlurHash?: InputMaybe<SortOrder>;
  serie?: InputMaybe<SerieOrderByWithRelationAndSearchRelevanceInput>;
  showWatchProviderCountries?: InputMaybe<ShowWatchProviderCountryOrderByRelationAggregateInput>;
  spokenLanguages?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowPageInfo = {
  __typename?: 'ShowPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ShowRelationFilter = {
  is?: InputMaybe<ShowWhereInput>;
  isNot?: InputMaybe<ShowWhereInput>;
};

export type ShowSumAggregate = {
  __typename?: 'ShowSumAggregate';
  popularity?: Maybe<Scalars['Float']>;
  tmdbId?: Maybe<Scalars['Int']>;
  voteAverage?: Maybe<Scalars['Float']>;
  voteCount?: Maybe<Scalars['Int']>;
};

export type ShowSumOrderByAggregateInput = {
  popularity?: InputMaybe<SortOrder>;
  tmdbId?: InputMaybe<SortOrder>;
  voteAverage?: InputMaybe<SortOrder>;
  voteCount?: InputMaybe<SortOrder>;
};

export type ShowTmdbIdMediaTypeCompoundUniqueInput = {
  mediaType: MediaType;
  tmdbId: Scalars['Int'];
};

export type ShowTrailer = {
  __typename?: 'ShowTrailer';
  id: Scalars['String'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  official: Scalars['Boolean'];
  publishedAt: Scalars['String'];
  site: Scalars['String'];
  size: Scalars['Float'];
  type: Scalars['String'];
};

export type ShowTranslation = {
  __typename?: 'ShowTranslation';
  createdAt: Scalars['Timestamp'];
  homepage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  overview: Scalars['String'];
  releaseDate?: Maybe<Scalars['Timestamp']>;
  showId: Scalars['String'];
  tagline: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type ShowTranslationCountAggregate = {
  __typename?: 'ShowTranslationCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  homepage: Scalars['Int'];
  id: Scalars['Int'];
  iso6391: Scalars['Int'];
  iso31661: Scalars['Int'];
  overview: Scalars['Int'];
  releaseDate: Scalars['Int'];
  showId: Scalars['Int'];
  tagline: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ShowTranslationMaxAggregate = {
  __typename?: 'ShowTranslationMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['Timestamp']>;
  showId?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ShowTranslationMinAggregate = {
  __typename?: 'ShowTranslationMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['Timestamp']>;
  showId?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type ShowTrending = MovieTrending | TvShowTrending;

export type ShowTrendingsArgs = {
  date?: InputMaybe<Scalars['String']>;
  imageSize?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  watchRegion?: InputMaybe<Scalars['String']>;
};

export type ShowUpdateOneRequiredWithoutRecommendationsNestedInput = {
  connect?: InputMaybe<ShowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShowCreateOrConnectWithoutRecommendationsInput>;
  create?: InputMaybe<ShowCreateWithoutRecommendationsInput>;
  update?: InputMaybe<ShowUpdateWithoutRecommendationsInput>;
  upsert?: InputMaybe<ShowUpsertWithoutRecommendationsInput>;
};

export type ShowUpdateWithoutRecommendationsInput = {
  allocineId?: InputMaybe<Scalars['String']>;
  backdrop?: InputMaybe<Scalars['String']>;
  backdropBlurHash?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  genres?: InputMaybe<GenreUpdateManyWithoutShowsNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  imdbId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<KeywordUpdateManyWithoutShowsNestedInput>;
  mediaDate?: InputMaybe<Scalars['Timestamp']>;
  mediaType?: InputMaybe<MediaType>;
  moods?: InputMaybe<MoodUpdateManyWithoutShowsNestedInput>;
  movie?: InputMaybe<MovieUpdateOneWithoutShowNestedInput>;
  originalLanguage?: InputMaybe<Scalars['String']>;
  originalTitle?: InputMaybe<Scalars['String']>;
  popularity?: InputMaybe<Scalars['Float']>;
  poster?: InputMaybe<Scalars['String']>;
  posterBlurHash?: InputMaybe<Scalars['String']>;
  serie?: InputMaybe<SerieUpdateOneWithoutShowNestedInput>;
  showWatchProviderCountries?: InputMaybe<ShowWatchProviderCountryUpdateManyWithoutShowNestedInput>;
  spokenLanguages?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Scalars['String']>;
  tmdbId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  voteAverage?: InputMaybe<Scalars['Float']>;
  voteCount?: InputMaybe<Scalars['Int']>;
};

export type ShowUpsertWithoutRecommendationsInput = {
  create: ShowCreateWithoutRecommendationsInput;
  update: ShowUpdateWithoutRecommendationsInput;
};

export type ShowWatchProvider = {
  __typename?: 'ShowWatchProvider';
  watchProvider: WatchProvider;
  watchProviderLink: Scalars['String'];
};

export type ShowWatchProviderCountry = {
  __typename?: 'ShowWatchProviderCountry';
  country: Country;
  createdAt: Scalars['Timestamp'];
  iso31661: Scalars['String'];
  show: Show;
  showId: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
  watchProvider: WatchProvider;
  watchProviderId: Scalars['String'];
};

export type ShowWatchProviderCountryCreateManyShowInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  iso31661: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  watchProviderId: Scalars['String'];
};

export type ShowWatchProviderCountryCreateManyShowInputEnvelope = {
  data: Array<ShowWatchProviderCountryCreateManyShowInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ShowWatchProviderCountryCreateNestedManyWithoutShowInput = {
  connect?: InputMaybe<Array<ShowWatchProviderCountryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ShowWatchProviderCountryCreateOrConnectWithoutShowInput>
  >;
  create?: InputMaybe<Array<ShowWatchProviderCountryCreateWithoutShowInput>>;
  createMany?: InputMaybe<ShowWatchProviderCountryCreateManyShowInputEnvelope>;
};

export type ShowWatchProviderCountryCreateOrConnectWithoutShowInput = {
  create: ShowWatchProviderCountryCreateWithoutShowInput;
  where: ShowWatchProviderCountryWhereUniqueInput;
};

export type ShowWatchProviderCountryCreateWithoutShowInput = {
  country: CountryCreateNestedOneWithoutShowWatchProviderCountriesInput;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  watchProvider: WatchProviderCreateNestedOneWithoutShowWatchProviderCountriesInput;
};

export type ShowWatchProviderCountryListRelationFilter = {
  every?: InputMaybe<ShowWatchProviderCountryWhereInput>;
  none?: InputMaybe<ShowWatchProviderCountryWhereInput>;
  some?: InputMaybe<ShowWatchProviderCountryWhereInput>;
};

export type ShowWatchProviderCountryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ShowWatchProviderCountryScalarWhereInput = {
  AND?: InputMaybe<Array<ShowWatchProviderCountryScalarWhereInput>>;
  NOT?: InputMaybe<Array<ShowWatchProviderCountryScalarWhereInput>>;
  OR?: InputMaybe<Array<ShowWatchProviderCountryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  iso31661?: InputMaybe<StringFilter>;
  showId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  watchProviderId?: InputMaybe<StringFilter>;
};

export type ShowWatchProviderCountryShowIdWatchProviderIdIso31661CompoundUniqueInput =
  {
    iso31661: Scalars['String'];
    showId: Scalars['String'];
    watchProviderId: Scalars['String'];
  };

export type ShowWatchProviderCountryUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type ShowWatchProviderCountryUpdateManyWithWhereWithoutShowInput = {
  data: ShowWatchProviderCountryUpdateManyMutationInput;
  where: ShowWatchProviderCountryScalarWhereInput;
};

export type ShowWatchProviderCountryUpdateManyWithoutShowNestedInput = {
  connect?: InputMaybe<Array<ShowWatchProviderCountryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ShowWatchProviderCountryCreateOrConnectWithoutShowInput>
  >;
  create?: InputMaybe<Array<ShowWatchProviderCountryCreateWithoutShowInput>>;
  createMany?: InputMaybe<ShowWatchProviderCountryCreateManyShowInputEnvelope>;
  delete?: InputMaybe<Array<ShowWatchProviderCountryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ShowWatchProviderCountryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ShowWatchProviderCountryWhereUniqueInput>>;
  set?: InputMaybe<Array<ShowWatchProviderCountryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ShowWatchProviderCountryUpdateWithWhereUniqueWithoutShowInput>
  >;
  updateMany?: InputMaybe<
    Array<ShowWatchProviderCountryUpdateManyWithWhereWithoutShowInput>
  >;
  upsert?: InputMaybe<
    Array<ShowWatchProviderCountryUpsertWithWhereUniqueWithoutShowInput>
  >;
};

export type ShowWatchProviderCountryUpdateWithWhereUniqueWithoutShowInput = {
  data: ShowWatchProviderCountryUpdateWithoutShowInput;
  where: ShowWatchProviderCountryWhereUniqueInput;
};

export type ShowWatchProviderCountryUpdateWithoutShowInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutShowWatchProviderCountriesNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  watchProvider?: InputMaybe<WatchProviderUpdateOneRequiredWithoutShowWatchProviderCountriesNestedInput>;
};

export type ShowWatchProviderCountryUpsertWithWhereUniqueWithoutShowInput = {
  create: ShowWatchProviderCountryCreateWithoutShowInput;
  update: ShowWatchProviderCountryUpdateWithoutShowInput;
  where: ShowWatchProviderCountryWhereUniqueInput;
};

export type ShowWatchProviderCountryWhereInput = {
  AND?: InputMaybe<Array<ShowWatchProviderCountryWhereInput>>;
  NOT?: InputMaybe<Array<ShowWatchProviderCountryWhereInput>>;
  OR?: InputMaybe<Array<ShowWatchProviderCountryWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  iso31661?: InputMaybe<StringFilter>;
  show?: InputMaybe<ShowRelationFilter>;
  showId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  watchProvider?: InputMaybe<WatchProviderRelationFilter>;
  watchProviderId?: InputMaybe<StringFilter>;
};

export type ShowWatchProviderCountryWhereUniqueInput = {
  showId_watchProviderId_iso31661?: InputMaybe<ShowWatchProviderCountryShowIdWatchProviderIdIso31661CompoundUniqueInput>;
};

export type ShowWhereInput = {
  AND?: InputMaybe<Array<ShowWhereInput>>;
  NOT?: InputMaybe<Array<ShowWhereInput>>;
  OR?: InputMaybe<Array<ShowWhereInput>>;
  allocineId?: InputMaybe<StringFilter>;
  backdrop?: InputMaybe<StringFilter>;
  backdropBlurHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  genres?: InputMaybe<GenreListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  imdbId?: InputMaybe<StringFilter>;
  keywords?: InputMaybe<KeywordListRelationFilter>;
  mediaDate?: InputMaybe<DateTimeFilter>;
  mediaType?: InputMaybe<EnumMediaTypeFilter>;
  moods?: InputMaybe<MoodListRelationFilter>;
  movie?: InputMaybe<MovieRelationFilter>;
  originalLanguage?: InputMaybe<StringFilter>;
  originalTitle?: InputMaybe<StringFilter>;
  popularity?: InputMaybe<FloatFilter>;
  poster?: InputMaybe<StringFilter>;
  posterBlurHash?: InputMaybe<StringFilter>;
  serie?: InputMaybe<SerieRelationFilter>;
  showWatchProviderCountries?: InputMaybe<ShowWatchProviderCountryListRelationFilter>;
  spokenLanguages?: InputMaybe<JsonFilter>;
  status?: InputMaybe<StringFilter>;
  tmdbId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  voteAverage?: InputMaybe<FloatFilter>;
  voteCount?: InputMaybe<IntFilter>;
};

export type ShowWhereUniqueInput = {
  allocineId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_mediaType?: InputMaybe<ShowIdMediaTypeCompoundUniqueInput>;
  tmdbId_mediaType?: InputMaybe<ShowTmdbIdMediaTypeCompoundUniqueInput>;
};

export type Similarity = {
  __typename?: 'Similarity';
  score: Scalars['Float'];
  visibility: SimilarityVisibility;
};

export enum SimilarityVisibility {
  HiddenCurrentUserActivities = 'HIDDEN_CURRENT_USER_ACTIVITIES',
  HiddenOtherUserActivities = 'HIDDEN_OTHER_USER_ACTIVITIES',
  NotCalculated = 'NOT_CALCULATED',
  Visible = 'VISIBLE',
}

export type SnoopRecommendationPageInfo = {
  __typename?: 'SnoopRecommendationPageInfo';
  endPage?: Maybe<Scalars['Float']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startPage?: Maybe<Scalars['Float']>;
};

export type SnoopRecommendationsArgs = {
  isLessThan2Hours?: InputMaybe<Scalars['Boolean']>;
  isRecent?: InputMaybe<Scalars['Boolean']>;
  mediaType?: InputMaybe<MediaType>;
  moodIds?: InputMaybe<Array<Scalars['String']>>;
  watchProviderIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StatSeries = {
  __typename?: 'StatSeries';
  count: Scalars['Int'];
  date: Scalars['String'];
};

export type Stats = {
  __typename?: 'Stats';
  activityDailySummary: DailyStatModel;
  appName: Scalars['String'];
  showDailySummary: DailyStatModel;
  topUsers: Array<User>;
  userDailySummary: DailyStatModel;
  usersArea: UsersAreaModel;
  usersOS: Array<UsersOs>;
};

export type StatsActivityDailySummaryArgs = {
  data: DailyStatDto;
};

export type StatsShowDailySummaryArgs = {
  data: DailyStatDto;
};

export type StatsUserDailySummaryArgs = {
  data: DailyStatDto;
};

export type StatsUsersAreaArgs = {
  data: UsersAreaDto;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  folderStatusChanged: Folder;
  messageSent: Message;
  notificationAdded: Notification;
  recommendationCreated: Recommendation;
};

export type SubscriptionFolderStatusChangedArgs = {
  iso31661: Scalars['String'];
};

export type Thread = {
  __typename?: 'Thread';
  _count: ThreadCount;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  lastMessageDate?: Maybe<Scalars['Timestamp']>;
  messages?: Maybe<Array<Message>>;
  participants: Array<Participant>;
  unseenMessagesCount: Scalars['Float'];
  updatedAt: Scalars['Timestamp'];
};

export type ThreadParticipantsArgs = {
  take: Scalars['Float'];
};

export type ThreadCount = {
  __typename?: 'ThreadCount';
  messages: Scalars['Int'];
  participants: Scalars['Int'];
};

export type TokensModel = {
  __typename?: 'TokensModel';
  accessToken: Scalars['String'];
  expiresAt: Scalars['Timestamp'];
  expiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  tokenType: Scalars['String'];
};

export type TvItem = {
  __typename?: 'TvItem';
  adult: Scalars['Boolean'];
  backdropPath?: Maybe<Scalars['String']>;
  firstAirDate?: Maybe<Scalars['String']>;
  genreIds: Array<Scalars['Float']>;
  id: Scalars['Float'];
  mediaType: MediaType;
  name: Scalars['String'];
  originCountry: Array<Scalars['String']>;
  originalLanguage: Scalars['String'];
  originalName: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export type TvShowTrending = {
  __typename?: 'TvShowTrending';
  adult: Scalars['Boolean'];
  backdropPath?: Maybe<Scalars['String']>;
  firstAirDate?: Maybe<Scalars['String']>;
  genreIds: Array<Scalars['Float']>;
  id: Scalars['Float'];
  mediaType: MediaType;
  name: Scalars['String'];
  originCountry: Array<Scalars['String']>;
  originalLanguage: Scalars['String'];
  originalName: Scalars['String'];
  overview: Scalars['String'];
  popularity: Scalars['Float'];
  posterPath?: Maybe<Scalars['String']>;
  voteAverage: Scalars['Float'];
  voteCount: Scalars['Float'];
};

export type UnregisterDevicePushTokenDto = {
  id: Scalars['String'];
};

export type UpdateCommentDto = {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  spoiler?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  _count: UserCount;
  appleId?: Maybe<Scalars['String']>;
  blocked: Scalars['Boolean'];
  contactsList?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Timestamp'];
  deleted?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed: Scalars['Boolean'];
  facebookId?: Maybe<Scalars['String']>;
  followed: Scalars['Boolean'];
  followers: Array<Follows>;
  googleId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iso6391: Scalars['String'];
  iso31661: Scalars['String'];
  language: Language;
  lastActiveAt?: Maybe<Scalars['Timestamp']>;
  mutualFollowersCount: Scalars['Int'];
  mutualFriends: PaginatedUser;
  name?: Maybe<Scalars['String']>;
  onboardingStatus: OnboardingStatus;
  phone?: Maybe<Scalars['String']>;
  phoneConfirmed: Scalars['Boolean'];
  profile: Profile;
  profileId: Scalars['String'];
  role: UserRole;
  similarity: Similarity;
  tikTokId?: Maybe<Scalars['String']>;
  type: UserType;
  updatedAt: Scalars['Timestamp'];
  username?: Maybe<Scalars['String']>;
  verifiedStatus: VerifiedStatus;
};

export type UserCount = {
  __typename?: 'UserCount';
  activities: Scalars['Int'];
  associatedContacts: Scalars['Int'];
  blocks: Scalars['Int'];
  comments: Scalars['Int'];
  contacts: Scalars['Int'];
  devices: Scalars['Int'];
  folders: Scalars['Int'];
  followers: Scalars['Int'];
  followings: Scalars['Int'];
  likes: Scalars['Int'];
  notifications: Scalars['Int'];
  participants: Scalars['Int'];
  receivedNotifications: Scalars['Int'];
  receivedRecommendations: Scalars['Int'];
  refreshTokens: Scalars['Int'];
  searchSection: Scalars['Int'];
  sentNotifications: Scalars['Int'];
  sentRecommendations: Scalars['Int'];
  watchProviders: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  appleId: Scalars['Int'];
  contactsList: Scalars['Int'];
  createdAt: Scalars['Int'];
  deleted: Scalars['Int'];
  email: Scalars['Int'];
  emailConfirmed: Scalars['Int'];
  facebookId: Scalars['Int'];
  googleId: Scalars['Int'];
  id: Scalars['Int'];
  iso6391: Scalars['Int'];
  iso31661: Scalars['Int'];
  lastActiveAt: Scalars['Int'];
  name: Scalars['Int'];
  onboardingStatus: Scalars['Int'];
  phone: Scalars['Int'];
  phoneConfirmed: Scalars['Int'];
  profileId: Scalars['Int'];
  role: Scalars['Int'];
  tikTokId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
  verifiedStatus: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  appleId?: InputMaybe<SortOrder>;
  contactsList?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  lastActiveAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  verifiedStatus?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsCreateNestedManyWithoutToInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: LanguageCreateNestedOneWithoutUsersInput;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile: ProfileCreateNestedOneWithoutUserInput;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserCreateNestedManyWithoutReceivedRecommendationsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutReceivedRecommendationsInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutReceivedRecommendationsInput>>;
};

export type UserCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutFollowingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowingsInput>;
  create?: InputMaybe<UserCreateWithoutFollowingsInput>;
};

export type UserCreateOrConnectWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowingsInput = {
  create: UserCreateWithoutFollowingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReceivedRecommendationsInput = {
  create: UserCreateWithoutReceivedRecommendationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivitiesInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsCreateNestedManyWithoutToInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: LanguageCreateNestedOneWithoutUsersInput;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile: ProfileCreateNestedOneWithoutUserInput;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserCreateWithoutCommentsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsCreateNestedManyWithoutToInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: LanguageCreateNestedOneWithoutUsersInput;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile: ProfileCreateNestedOneWithoutUserInput;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserCreateWithoutFollowingsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsCreateNestedManyWithoutToInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: LanguageCreateNestedOneWithoutUsersInput;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile: ProfileCreateNestedOneWithoutUserInput;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserCreateWithoutReceivedRecommendationsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsCreateNestedManyWithoutToInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: LanguageCreateNestedOneWithoutUsersInput;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile: ProfileCreateNestedOneWithoutUserInput;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  appleId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deleted?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  facebookId?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Timestamp']>;
  name?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phone?: Maybe<Scalars['String']>;
  phoneConfirmed?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  tikTokId?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  username?: Maybe<Scalars['String']>;
  verifiedStatus?: Maybe<VerifiedStatus>;
};

export type UserMaxOrderByAggregateInput = {
  appleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  lastActiveAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  verifiedStatus?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  appleId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  deleted?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  facebookId?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso6391?: Maybe<Scalars['String']>;
  iso31661?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Timestamp']>;
  name?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  phone?: Maybe<Scalars['String']>;
  phoneConfirmed?: Maybe<Scalars['Boolean']>;
  profileId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  tikTokId?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  username?: Maybe<Scalars['String']>;
  verifiedStatus?: Maybe<VerifiedStatus>;
};

export type UserMinOrderByAggregateInput = {
  appleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  lastActiveAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  verifiedStatus?: InputMaybe<SortOrder>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  AppleId = 'appleId',
  Email = 'email',
  FacebookId = 'facebookId',
  GoogleId = 'googleId',
  Id = 'id',
  Iso6391 = 'iso6391',
  Iso31661 = 'iso31661',
  Name = 'name',
  Phone = 'phone',
  ProfileId = 'profileId',
  TikTokId = 'tikTokId',
  Username = 'username',
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  appleId?: InputMaybe<SortOrder>;
  contactsList?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  lastActiveAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  verifiedStatus?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserOrderByRelevanceInput>;
  appleId?: InputMaybe<SortOrder>;
  contactsList?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  followers?: InputMaybe<FollowsOrderByRelationAggregateInput>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  language?: InputMaybe<LanguageOrderByWithRelationAndSearchRelevanceInput>;
  lastActiveAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationAndSearchRelevanceInput>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  verifiedStatus?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  appleId?: InputMaybe<SortOrder>;
  contactsList?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailConfirmed?: InputMaybe<SortOrder>;
  facebookId?: InputMaybe<SortOrder>;
  googleId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso6391?: InputMaybe<SortOrder>;
  iso31661?: InputMaybe<SortOrder>;
  language?: InputMaybe<LanguageOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  onboardingStatus?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneConfirmed?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationInput>;
  profileId?: InputMaybe<SortOrder>;
  tikTokId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserPageInfo = {
  __typename?: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserRole {
  Moderator = 'MODERATOR',
  User = 'USER',
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  appleId?: InputMaybe<StringFilter>;
  contactsList?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  emailConfirmed?: InputMaybe<BoolFilter>;
  facebookId?: InputMaybe<StringFilter>;
  googleId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  iso6391?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  lastActiveAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  onboardingStatus?: InputMaybe<EnumOnboardingStatusFilter>;
  phone?: InputMaybe<StringFilter>;
  phoneConfirmed?: InputMaybe<BoolFilter>;
  profileId?: InputMaybe<StringFilter>;
  tikTokId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
  verifiedStatus?: InputMaybe<EnumVerifiedStatusFilter>;
};

export type UserSuggestions = {
  __typename?: 'UserSuggestions';
  inApp: Array<User>;
  recommended: Array<User>;
  suggested: Array<User>;
};

export enum UserType {
  Artist = 'ARTIST',
  Media = 'MEDIA',
  User = 'USER',
}

export type UserUpdateInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsUpdateManyWithoutToNestedInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutUsersNestedInput>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutUserNestedInput>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpdateManyMutationInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpdateManyWithWhereWithoutReceivedRecommendationsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutReceivedRecommendationsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutReceivedRecommendationsInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutReceivedRecommendationsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserUpdateWithWhereUniqueWithoutReceivedRecommendationsInput>
  >;
  updateMany?: InputMaybe<
    Array<UserUpdateManyWithWhereWithoutReceivedRecommendationsInput>
  >;
  upsert?: InputMaybe<
    Array<UserUpsertWithWhereUniqueWithoutReceivedRecommendationsInput>
  >;
};

export type UserUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
  update?: InputMaybe<UserUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivitiesInput>;
};

export type UserUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneRequiredWithoutFollowingsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowingsInput>;
  create?: InputMaybe<UserCreateWithoutFollowingsInput>;
  update?: InputMaybe<UserUpdateWithoutFollowingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFollowingsInput>;
};

export type UserUpdateWithWhereUniqueWithoutReceivedRecommendationsInput = {
  data: UserUpdateWithoutReceivedRecommendationsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutActivitiesInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsUpdateManyWithoutToNestedInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutUsersNestedInput>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutUserNestedInput>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpdateWithoutCommentsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsUpdateManyWithoutToNestedInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutUsersNestedInput>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutUserNestedInput>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpdateWithoutFollowingsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsUpdateManyWithoutToNestedInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutUsersNestedInput>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutUserNestedInput>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpdateWithoutReceivedRecommendationsInput = {
  appleId?: InputMaybe<Scalars['String']>;
  contactsList?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  deleted?: InputMaybe<Scalars['Timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  facebookId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<FollowsUpdateManyWithoutToNestedInput>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageUpdateOneRequiredWithoutUsersNestedInput>;
  lastActiveAt?: InputMaybe<Scalars['Timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  phone?: InputMaybe<Scalars['String']>;
  phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutUserNestedInput>;
  tikTokId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
  username?: InputMaybe<Scalars['String']>;
  verifiedStatus?: InputMaybe<VerifiedStatus>;
};

export type UserUpsertWithWhereUniqueWithoutReceivedRecommendationsInput = {
  create: UserCreateWithoutReceivedRecommendationsInput;
  update: UserUpdateWithoutReceivedRecommendationsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  update: UserUpdateWithoutActivitiesInput;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutFollowingsInput = {
  create: UserCreateWithoutFollowingsInput;
  update: UserUpdateWithoutFollowingsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  appleId?: InputMaybe<StringFilter>;
  contactsList?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  emailConfirmed?: InputMaybe<BoolFilter>;
  facebookId?: InputMaybe<StringFilter>;
  followers?: InputMaybe<FollowsListRelationFilter>;
  googleId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  iso6391?: InputMaybe<StringFilter>;
  iso31661?: InputMaybe<StringFilter>;
  language?: InputMaybe<LanguageRelationFilter>;
  lastActiveAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  onboardingStatus?: InputMaybe<EnumOnboardingStatusFilter>;
  phone?: InputMaybe<StringFilter>;
  phoneConfirmed?: InputMaybe<BoolFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<StringFilter>;
  tikTokId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
  verifiedStatus?: InputMaybe<EnumVerifiedStatusFilter>;
};

export type UserWhereUniqueInput = {
  appleId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebookId?: InputMaybe<Scalars['String']>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  tikTokId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersAreaDto = {
  year?: InputMaybe<Scalars['String']>;
};

export type UsersAreaModel = {
  __typename?: 'UsersAreaModel';
  data: Array<AreaCountModel>;
  percentage: Scalars['Float'];
};

export type UsersOs = {
  __typename?: 'UsersOS';
  count: Scalars['Int'];
  os: Scalars['String'];
};

export enum VerifiedStatus {
  Confirmed = 'CONFIRMED',
  Unknown = 'UNKNOWN',
}

export type VerifyAdminDto = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type VerifyDto = {
  code: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type WatchProvider = {
  __typename?: 'WatchProvider';
  _count: WatchProviderCount;
  countries?: Maybe<Array<Country>>;
  createdAt: Scalars['Timestamp'];
  displayPriority: Scalars['Int'];
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  logoBlurHash?: Maybe<Scalars['String']>;
  mediaTypes?: Maybe<Array<MediaType>>;
  name: Scalars['String'];
  picked: Scalars['Boolean'];
  slug: Scalars['String'];
  tintColor: Scalars['String'];
  tmdbIds?: Maybe<Array<Scalars['Int']>>;
  updatedAt: Scalars['Timestamp'];
};

export type WatchProviderAvgAggregate = {
  __typename?: 'WatchProviderAvgAggregate';
  displayPriority?: Maybe<Scalars['Float']>;
  tmdbIds?: Maybe<Scalars['Float']>;
};

export type WatchProviderCount = {
  __typename?: 'WatchProviderCount';
  countries: Scalars['Int'];
  folders: Scalars['Int'];
  showWatchProviderCountries: Scalars['Int'];
  users: Scalars['Int'];
};

export type WatchProviderCountAggregate = {
  __typename?: 'WatchProviderCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayPriority: Scalars['Int'];
  id: Scalars['Int'];
  logo: Scalars['Int'];
  logoBlurHash: Scalars['Int'];
  mediaTypes: Scalars['Int'];
  name: Scalars['Int'];
  slug: Scalars['Int'];
  tintColor: Scalars['Int'];
  tmdbIds: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type WatchProviderCreateNestedOneWithoutShowWatchProviderCountriesInput =
  {
    connect?: InputMaybe<WatchProviderWhereUniqueInput>;
    connectOrCreate?: InputMaybe<WatchProviderCreateOrConnectWithoutShowWatchProviderCountriesInput>;
    create?: InputMaybe<WatchProviderCreateWithoutShowWatchProviderCountriesInput>;
  };

export type WatchProviderCreateOrConnectWithoutShowWatchProviderCountriesInput =
  {
    create: WatchProviderCreateWithoutShowWatchProviderCountriesInput;
    where: WatchProviderWhereUniqueInput;
  };

export type WatchProviderCreateWithoutShowWatchProviderCountriesInput = {
  countries?: InputMaybe<CountryCreateNestedManyWithoutWatchProvidersInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  displayPriority: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoBlurHash?: InputMaybe<Scalars['String']>;
  mediaTypes?: InputMaybe<Array<MediaType>>;
  name: Scalars['String'];
  slug: Scalars['String'];
  tintColor?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type WatchProviderEdge = {
  __typename?: 'WatchProviderEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<WatchProvider>;
};

export type WatchProviderMaxAggregate = {
  __typename?: 'WatchProviderMaxAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayPriority?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoBlurHash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tintColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type WatchProviderMinAggregate = {
  __typename?: 'WatchProviderMinAggregate';
  createdAt?: Maybe<Scalars['Timestamp']>;
  displayPriority?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoBlurHash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tintColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type WatchProviderOrderByWithRelationInput = {
  countries?: InputMaybe<CountryOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  displayPriority?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  mediaTypes?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  tintColor?: InputMaybe<SortOrder>;
  tmdbIds?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WatchProviderPageInfo = {
  __typename?: 'WatchProviderPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type WatchProviderRelationFilter = {
  is?: InputMaybe<WatchProviderWhereInput>;
  isNot?: InputMaybe<WatchProviderWhereInput>;
};

export type WatchProviderSumAggregate = {
  __typename?: 'WatchProviderSumAggregate';
  displayPriority?: Maybe<Scalars['Int']>;
  tmdbIds?: Maybe<Array<Scalars['Int']>>;
};

export type WatchProviderUpdateOneRequiredWithoutShowWatchProviderCountriesNestedInput =
  {
    connect?: InputMaybe<WatchProviderWhereUniqueInput>;
    connectOrCreate?: InputMaybe<WatchProviderCreateOrConnectWithoutShowWatchProviderCountriesInput>;
    create?: InputMaybe<WatchProviderCreateWithoutShowWatchProviderCountriesInput>;
    update?: InputMaybe<WatchProviderUpdateWithoutShowWatchProviderCountriesInput>;
    upsert?: InputMaybe<WatchProviderUpsertWithoutShowWatchProviderCountriesInput>;
  };

export type WatchProviderUpdateWithoutShowWatchProviderCountriesInput = {
  countries?: InputMaybe<CountryUpdateManyWithoutWatchProvidersNestedInput>;
  createdAt?: InputMaybe<Scalars['Timestamp']>;
  displayPriority?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoBlurHash?: InputMaybe<Scalars['String']>;
  mediaTypes?: InputMaybe<Array<MediaType>>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tintColor?: InputMaybe<Scalars['String']>;
  tmdbIds?: InputMaybe<Array<Scalars['Int']>>;
  updatedAt?: InputMaybe<Scalars['Timestamp']>;
};

export type WatchProviderUpsertWithoutShowWatchProviderCountriesInput = {
  create: WatchProviderCreateWithoutShowWatchProviderCountriesInput;
  update: WatchProviderUpdateWithoutShowWatchProviderCountriesInput;
};

export type WatchProviderWhereInput = {
  AND?: InputMaybe<Array<WatchProviderWhereInput>>;
  NOT?: InputMaybe<Array<WatchProviderWhereInput>>;
  OR?: InputMaybe<Array<WatchProviderWhereInput>>;
  countries?: InputMaybe<CountryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayPriority?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  logo?: InputMaybe<StringFilter>;
  logoBlurHash?: InputMaybe<StringFilter>;
  mediaTypes?: InputMaybe<EnumMediaTypeListFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  tintColor?: InputMaybe<StringFilter>;
  tmdbIds?: InputMaybe<IntListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type WatchProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type AdminFragment = {
  __typename?: 'Admin';
  id: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneConfirmed: boolean;
  role: AdminRole;
};

export type AdminMeFragment = {
  __typename?: 'Admin';
  id: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneConfirmed: boolean;
  role: AdminRole;
};

export type StatSeriesFragment = {
  __typename?: 'StatSeries';
  count: number;
  date: string;
};

export type DailyStatsFragment = {
  __typename?: 'DailyStatModel';
  total: number;
  percentage: number;
  series: Array<{ __typename?: 'StatSeries'; count: number; date: string }>;
};

export type BaseProfileFragment = {
  __typename?: 'Profile';
  id: string;
  picture?: string | null;
  pictureBlurHash?: string | null;
  birthday?: any | null;
};

export type SearchSectionPageInfoFragment = {
  __typename?: 'SearchSectionPageInfo';
  endCursor?: string | null;
  startCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type SearchSectionFragment = {
  __typename?: 'SearchSection';
  id: string;
  order: number;
  slug: string;
  createdAt: any;
  updatedAt: any;
  curratedBy?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    username?: string | null;
  } | null;
  translation?: {
    __typename?: 'SearchSectionTranslation';
    title: string;
  } | null;
};

export type PaginatedSearchSectionFragment = {
  __typename?: 'PaginatedSearchSection';
  totalCount: number;
  pageInfo?: {
    __typename?: 'SearchSectionPageInfo';
    endCursor?: string | null;
    startCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  } | null;
  edges?: Array<{
    __typename?: 'SearchSectionEdge';
    cursor?: string | null;
    node?: {
      __typename?: 'SearchSection';
      id: string;
      order: number;
      slug: string;
      createdAt: any;
      updatedAt: any;
      curratedBy?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        username?: string | null;
      } | null;
      translation?: {
        __typename?: 'SearchSectionTranslation';
        title: string;
      } | null;
    } | null;
  }> | null;
};

export type TokensFragment = {
  __typename?: 'TokensModel';
  accessToken: string;
  refreshToken: string;
  expiresAt: any;
  tokenType: string;
};

export type UserPageInfoFragment = {
  __typename?: 'UserPageInfo';
  endCursor?: string | null;
  startCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  username?: string | null;
  type: UserType;
  role: UserRole;
  iso6391: string;
  iso31661: string;
  phone?: string | null;
  phoneConfirmed: boolean;
  onboardingStatus: OnboardingStatus;
  deleted?: any | null;
  lastActiveAt?: any | null;
  _count: {
    __typename?: 'UserCount';
    activities: number;
    followers: number;
    followings: number;
  };
  profile: {
    __typename?: 'Profile';
    id: string;
    picture?: string | null;
    pictureBlurHash?: string | null;
    birthday?: any | null;
  };
};

export type PaginatedUserFragment = {
  __typename?: 'PaginatedUser';
  totalCount: number;
  pageInfo?: {
    __typename?: 'UserPageInfo';
    endCursor?: string | null;
    startCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  } | null;
  edges?: Array<{
    __typename?: 'UserEdge';
    cursor?: string | null;
    node?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      username?: string | null;
      type: UserType;
      role: UserRole;
      iso6391: string;
      iso31661: string;
      phone?: string | null;
      phoneConfirmed: boolean;
      onboardingStatus: OnboardingStatus;
      deleted?: any | null;
      lastActiveAt?: any | null;
      _count: {
        __typename?: 'UserCount';
        activities: number;
        followers: number;
        followings: number;
      };
      profile: {
        __typename?: 'Profile';
        id: string;
        picture?: string | null;
        pictureBlurHash?: string | null;
        birthday?: any | null;
      };
    } | null;
  }> | null;
};

export type TopUserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  username?: string | null;
  profile: {
    __typename?: 'Profile';
    id: string;
    picture?: string | null;
    pictureBlurHash?: string | null;
  };
  _count: { __typename?: 'UserCount'; activities: number };
};

export type MonthlyCountFragment = {
  __typename?: 'MonthlyCountModel';
  month: string;
  year: string;
  count: number;
};

export type AreaCountFragment = {
  __typename?: 'AreaCountModel';
  iso31661: string;
  monthlyCounts: Array<{
    __typename?: 'MonthlyCountModel';
    month: string;
    year: string;
    count: number;
  }>;
};

export type UsersAreaFragment = {
  __typename?: 'UsersAreaModel';
  percentage: number;
  data: Array<{
    __typename?: 'AreaCountModel';
    iso31661: string;
    monthlyCounts: Array<{
      __typename?: 'MonthlyCountModel';
      month: string;
      year: string;
      count: number;
    }>;
  }>;
};

export type ChallengeMutationVariables = Exact<{
  data: ChallengeAdminDto;
}>;

export type ChallengeMutation = {
  __typename?: 'Mutation';
  challengeAdmin: string;
};

export type VerifyMutationVariables = Exact<{
  data: VerifyAdminDto;
}>;

export type VerifyMutation = {
  __typename?: 'Mutation';
  verifyAdmin: {
    __typename?: 'AuthAdminModel';
    admin: { __typename?: 'Admin'; id: string };
    tokens: {
      __typename?: 'TokensModel';
      accessToken: string;
      refreshToken: string;
      expiresAt: any;
      tokenType: string;
    };
  };
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'TokensModel';
    accessToken: string;
    refreshToken: string;
    expiresAt: any;
    tokenType: string;
  };
};

export type RegisterMutationVariables = Exact<{
  data: RegisterAdminDto;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  registerAdmin: string;
};

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordDto;
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: string;
};

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordDto;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: string;
};

export type SendPushNotificationsMutationVariables = Exact<{
  args: PushNotificationArgs;
}>;

export type SendPushNotificationsMutation = {
  __typename?: 'Mutation';
  sendPushNotifications: string;
};

export type UpdateUserMutationVariables = Exact<{
  updateUserData: UserUpdateInput;
  whereUpdateUser: UserWhereUniqueInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    username?: string | null;
    type: UserType;
    role: UserRole;
    iso6391: string;
    iso31661: string;
    phone?: string | null;
    phoneConfirmed: boolean;
    onboardingStatus: OnboardingStatus;
    deleted?: any | null;
    lastActiveAt?: any | null;
    _count: {
      __typename?: 'UserCount';
      activities: number;
      followers: number;
      followings: number;
    };
    profile: {
      __typename?: 'Profile';
      id: string;
      picture?: string | null;
      pictureBlurHash?: string | null;
      birthday?: any | null;
    };
  };
};

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    username?: string | null;
    type: UserType;
    role: UserRole;
    iso6391: string;
    iso31661: string;
    phone?: string | null;
    phoneConfirmed: boolean;
    onboardingStatus: OnboardingStatus;
    deleted?: any | null;
    lastActiveAt?: any | null;
    _count: {
      __typename?: 'UserCount';
      activities: number;
      followers: number;
      followings: number;
    };
    profile: {
      __typename?: 'Profile';
      id: string;
      picture?: string | null;
      pictureBlurHash?: string | null;
      birthday?: any | null;
    };
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  meAdmin: {
    __typename?: 'Admin';
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    phoneConfirmed: boolean;
    role: AdminRole;
  };
};

export type AppStatsQueryVariables = Exact<{
  dailyStatDto: DailyStatDto;
  usersArea: UsersAreaDto;
}>;

export type AppStatsQuery = {
  __typename?: 'Query';
  stats: {
    __typename?: 'Stats';
    appName: string;
    userDailySummary: {
      __typename?: 'DailyStatModel';
      total: number;
      percentage: number;
      series: Array<{ __typename?: 'StatSeries'; count: number; date: string }>;
    };
    showDailySummary: {
      __typename?: 'DailyStatModel';
      total: number;
      percentage: number;
      series: Array<{ __typename?: 'StatSeries'; count: number; date: string }>;
    };
    activityDailySummary: {
      __typename?: 'DailyStatModel';
      total: number;
      percentage: number;
      series: Array<{ __typename?: 'StatSeries'; count: number; date: string }>;
    };
    usersArea: {
      __typename?: 'UsersAreaModel';
      percentage: number;
      data: Array<{
        __typename?: 'AreaCountModel';
        iso31661: string;
        monthlyCounts: Array<{
          __typename?: 'MonthlyCountModel';
          month: string;
          year: string;
          count: number;
        }>;
      }>;
    };
    topUsers: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      username?: string | null;
      profile: {
        __typename?: 'Profile';
        id: string;
        picture?: string | null;
        pictureBlurHash?: string | null;
      };
      _count: { __typename?: 'UserCount'; activities: number };
    }>;
    usersOS: Array<{ __typename?: 'UsersOS'; os: string; count: number }>;
  };
};

export type VerifyAdminTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyAdminTokenQuery = {
  __typename?: 'Query';
  verifyAdminToken: string;
};

export type SearchSectionsQueryVariables = Exact<{
  where?: InputMaybe<SearchSectionWhereInput>;
  orderBy?: InputMaybe<
    | Array<SearchSectionOrderByWithRelationInput>
    | SearchSectionOrderByWithRelationInput
  >;
  locale?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
}>;

export type SearchSectionsQuery = {
  __typename?: 'Query';
  searchSections: {
    __typename?: 'PaginatedSearchSection';
    totalCount: number;
    pageInfo?: {
      __typename?: 'SearchSectionPageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    } | null;
    edges?: Array<{
      __typename?: 'SearchSectionEdge';
      cursor?: string | null;
      node?: {
        __typename?: 'SearchSection';
        id: string;
        order: number;
        slug: string;
        createdAt: any;
        updatedAt: any;
        curratedBy?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          username?: string | null;
        } | null;
        translation?: {
          __typename?: 'SearchSectionTranslation';
          title: string;
        } | null;
      } | null;
    }> | null;
  };
};

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  search?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<
    Array<UserOrderByWithAggregationInput> | UserOrderByWithAggregationInput
  >;
  cursor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUser';
    totalCount: number;
    pageInfo?: {
      __typename?: 'UserPageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    } | null;
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor?: string | null;
      node?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        username?: string | null;
        type: UserType;
        role: UserRole;
        iso6391: string;
        iso31661: string;
        phone?: string | null;
        phoneConfirmed: boolean;
        onboardingStatus: OnboardingStatus;
        deleted?: any | null;
        lastActiveAt?: any | null;
        _count: {
          __typename?: 'UserCount';
          activities: number;
          followers: number;
          followings: number;
        };
        profile: {
          __typename?: 'Profile';
          id: string;
          picture?: string | null;
          pictureBlurHash?: string | null;
          birthday?: any | null;
        };
      } | null;
    }> | null;
  };
};

export type UserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    username?: string | null;
    type: UserType;
    role: UserRole;
    iso6391: string;
    iso31661: string;
    phone?: string | null;
    phoneConfirmed: boolean;
    onboardingStatus: OnboardingStatus;
    deleted?: any | null;
    lastActiveAt?: any | null;
    _count: {
      __typename?: 'UserCount';
      activities: number;
      followers: number;
      followings: number;
    };
    profile: {
      __typename?: 'Profile';
      id: string;
      picture?: string | null;
      pictureBlurHash?: string | null;
      birthday?: any | null;
    };
  };
};

export const AdminFragmentDoc = gql`
  fragment Admin on Admin {
    id
    name
    email
    phone
    phoneConfirmed
    role
  }
`;
export const AdminMeFragmentDoc = gql`
  fragment AdminMe on Admin {
    ...Admin
  }
  ${AdminFragmentDoc}
`;
export const StatSeriesFragmentDoc = gql`
  fragment StatSeries on StatSeries {
    count
    date
  }
`;
export const DailyStatsFragmentDoc = gql`
  fragment DailyStats on DailyStatModel {
    total
    percentage
    series {
      ...StatSeries
    }
  }
  ${StatSeriesFragmentDoc}
`;
export const SearchSectionPageInfoFragmentDoc = gql`
  fragment SearchSectionPageInfo on SearchSectionPageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const SearchSectionFragmentDoc = gql`
  fragment SearchSection on SearchSection {
    id
    order
    slug
    curratedBy {
      id
      name
      username
    }
    translation(locale: $locale) {
      title
    }
    createdAt
    updatedAt
  }
`;
export const PaginatedSearchSectionFragmentDoc = gql`
  fragment PaginatedSearchSection on PaginatedSearchSection {
    totalCount
    pageInfo {
      ...SearchSectionPageInfo
    }
    edges {
      cursor
      node {
        ...SearchSection
      }
    }
  }
  ${SearchSectionPageInfoFragmentDoc}
  ${SearchSectionFragmentDoc}
`;
export const TokensFragmentDoc = gql`
  fragment Tokens on TokensModel {
    accessToken
    refreshToken
    expiresAt
    tokenType
  }
`;
export const UserPageInfoFragmentDoc = gql`
  fragment UserPageInfo on UserPageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const BaseProfileFragmentDoc = gql`
  fragment BaseProfile on Profile {
    id
    picture
    pictureBlurHash
    birthday
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    username
    type
    role
    _count {
      activities
      followers
      followings
    }
    iso6391
    iso31661
    phone
    phoneConfirmed
    onboardingStatus
    deleted
    lastActiveAt
    profile {
      ...BaseProfile
    }
  }
  ${BaseProfileFragmentDoc}
`;
export const PaginatedUserFragmentDoc = gql`
  fragment PaginatedUser on PaginatedUser {
    totalCount
    pageInfo {
      ...UserPageInfo
    }
    edges {
      cursor
      node {
        ...User
      }
    }
  }
  ${UserPageInfoFragmentDoc}
  ${UserFragmentDoc}
`;
export const TopUserFragmentDoc = gql`
  fragment TopUser on User {
    id
    name
    username
    profile {
      id
      picture
      pictureBlurHash
    }
    _count {
      activities
    }
  }
`;
export const MonthlyCountFragmentDoc = gql`
  fragment MonthlyCount on MonthlyCountModel {
    month
    year
    count
  }
`;
export const AreaCountFragmentDoc = gql`
  fragment AreaCount on AreaCountModel {
    iso31661
    monthlyCounts {
      ...MonthlyCount
    }
  }
  ${MonthlyCountFragmentDoc}
`;
export const UsersAreaFragmentDoc = gql`
  fragment UsersArea on UsersAreaModel {
    percentage
    data {
      ...AreaCount
    }
  }
  ${AreaCountFragmentDoc}
`;
export const ChallengeDocument = gql`
  mutation Challenge($data: ChallengeAdminDto!) {
    challengeAdmin(data: $data)
  }
`;
export type ChallengeMutationFn = Apollo.MutationFunction<
  ChallengeMutation,
  ChallengeMutationVariables
>;

/**
 * __useChallengeMutation__
 *
 * To run a mutation, you first call `useChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [challengeMutation, { data, loading, error }] = useChallengeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChallengeMutation,
    ChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChallengeMutation, ChallengeMutationVariables>(
    ChallengeDocument,
    options,
  );
}
export type ChallengeMutationHookResult = ReturnType<
  typeof useChallengeMutation
>;
export type ChallengeMutationResult = Apollo.MutationResult<ChallengeMutation>;
export type ChallengeMutationOptions = Apollo.BaseMutationOptions<
  ChallengeMutation,
  ChallengeMutationVariables
>;
export const VerifyDocument = gql`
  mutation Verify($data: VerifyAdminDto!) {
    verifyAdmin(data: $data) {
      admin {
        id
      }
      tokens {
        ...Tokens
      }
    }
  }
  ${TokensFragmentDoc}
`;
export type VerifyMutationFn = Apollo.MutationFunction<
  VerifyMutation,
  VerifyMutationVariables
>;

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMutation,
    VerifyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(
    VerifyDocument,
    options,
  );
}
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>;
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>;
export type VerifyMutationOptions = Apollo.BaseMutationOptions<
  VerifyMutation,
  VerifyMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      ...Tokens
    }
  }
  ${TokensFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($data: RegisterAdminDto!) {
    registerAdmin(data: $data)
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($data: ForgotPasswordDto!) {
    forgotPassword(dto: $data)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: ResetPasswordDto!) {
    resetPassword(dto: $data)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SendPushNotificationsDocument = gql`
  mutation SendPushNotifications($args: PushNotificationArgs!) {
    sendPushNotifications(args: $args)
  }
`;
export type SendPushNotificationsMutationFn = Apollo.MutationFunction<
  SendPushNotificationsMutation,
  SendPushNotificationsMutationVariables
>;

/**
 * __useSendPushNotificationsMutation__
 *
 * To run a mutation, you first call `useSendPushNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushNotificationsMutation, { data, loading, error }] = useSendPushNotificationsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSendPushNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPushNotificationsMutation,
    SendPushNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPushNotificationsMutation,
    SendPushNotificationsMutationVariables
  >(SendPushNotificationsDocument, options);
}
export type SendPushNotificationsMutationHookResult = ReturnType<
  typeof useSendPushNotificationsMutation
>;
export type SendPushNotificationsMutationResult =
  Apollo.MutationResult<SendPushNotificationsMutation>;
export type SendPushNotificationsMutationOptions = Apollo.BaseMutationOptions<
  SendPushNotificationsMutation,
  SendPushNotificationsMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser(
    $updateUserData: UserUpdateInput!
    $whereUpdateUser: UserWhereUniqueInput!
  ) {
    updateUser(data: $updateUserData, where: $whereUpdateUser) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserData: // value for 'updateUserData'
 *      whereUpdateUser: // value for 'whereUpdateUser'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const MeDocument = gql`
  query Me {
    meAdmin {
      ...AdminMe
    }
  }
  ${AdminMeFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AppStatsDocument = gql`
  query AppStats($dailyStatDto: DailyStatDto!, $usersArea: UsersAreaDto!) {
    stats {
      appName
      userDailySummary(data: $dailyStatDto) {
        ...DailyStats
      }
      showDailySummary(data: $dailyStatDto) {
        ...DailyStats
      }
      activityDailySummary(data: $dailyStatDto) {
        ...DailyStats
      }
      usersArea(data: $usersArea) {
        ...UsersArea
      }
      topUsers {
        ...TopUser
      }
      usersOS {
        os
        count
      }
    }
  }
  ${DailyStatsFragmentDoc}
  ${UsersAreaFragmentDoc}
  ${TopUserFragmentDoc}
`;

/**
 * __useAppStatsQuery__
 *
 * To run a query within a React component, call `useAppStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppStatsQuery({
 *   variables: {
 *      dailyStatDto: // value for 'dailyStatDto'
 *      usersArea: // value for 'usersArea'
 *   },
 * });
 */
export function useAppStatsQuery(
  baseOptions: Apollo.QueryHookOptions<AppStatsQuery, AppStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppStatsQuery, AppStatsQueryVariables>(
    AppStatsDocument,
    options,
  );
}
export function useAppStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppStatsQuery,
    AppStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppStatsQuery, AppStatsQueryVariables>(
    AppStatsDocument,
    options,
  );
}
export type AppStatsQueryHookResult = ReturnType<typeof useAppStatsQuery>;
export type AppStatsLazyQueryHookResult = ReturnType<
  typeof useAppStatsLazyQuery
>;
export type AppStatsQueryResult = Apollo.QueryResult<
  AppStatsQuery,
  AppStatsQueryVariables
>;
export const VerifyAdminTokenDocument = gql`
  query VerifyAdminToken($token: String!) {
    verifyAdminToken(token: $token)
  }
`;

/**
 * __useVerifyAdminTokenQuery__
 *
 * To run a query within a React component, call `useVerifyAdminTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAdminTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAdminTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyAdminTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyAdminTokenQuery,
    VerifyAdminTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyAdminTokenQuery, VerifyAdminTokenQueryVariables>(
    VerifyAdminTokenDocument,
    options,
  );
}
export function useVerifyAdminTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyAdminTokenQuery,
    VerifyAdminTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyAdminTokenQuery,
    VerifyAdminTokenQueryVariables
  >(VerifyAdminTokenDocument, options);
}
export type VerifyAdminTokenQueryHookResult = ReturnType<
  typeof useVerifyAdminTokenQuery
>;
export type VerifyAdminTokenLazyQueryHookResult = ReturnType<
  typeof useVerifyAdminTokenLazyQuery
>;
export type VerifyAdminTokenQueryResult = Apollo.QueryResult<
  VerifyAdminTokenQuery,
  VerifyAdminTokenQueryVariables
>;
export const SearchSectionsDocument = gql`
  query SearchSections(
    $where: SearchSectionWhereInput
    $orderBy: [SearchSectionOrderByWithRelationInput!]
    $locale: String
    $cursor: String
    $first: Float
  ) {
    searchSections(
      where: $where
      orderBy: $orderBy
      after: $cursor
      first: $first
    ) {
      ...PaginatedSearchSection
    }
  }
  ${PaginatedSearchSectionFragmentDoc}
`;

/**
 * __useSearchSectionsQuery__
 *
 * To run a query within a React component, call `useSearchSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      locale: // value for 'locale'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSectionsQuery,
    SearchSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchSectionsQuery, SearchSectionsQueryVariables>(
    SearchSectionsDocument,
    options,
  );
}
export function useSearchSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSectionsQuery,
    SearchSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchSectionsQuery, SearchSectionsQueryVariables>(
    SearchSectionsDocument,
    options,
  );
}
export type SearchSectionsQueryHookResult = ReturnType<
  typeof useSearchSectionsQuery
>;
export type SearchSectionsLazyQueryHookResult = ReturnType<
  typeof useSearchSectionsLazyQuery
>;
export type SearchSectionsQueryResult = Apollo.QueryResult<
  SearchSectionsQuery,
  SearchSectionsQueryVariables
>;
export const UsersDocument = gql`
  query Users(
    $where: UserWhereInput
    $search: String
    $orderBy: [UserOrderByWithAggregationInput!]
    $cursor: String
    $first: Float
  ) {
    users(
      where: $where
      search: $search
      orderBy: $orderBy
      after: $cursor
      first: $first
    ) {
      ...PaginatedUser
    }
  }
  ${PaginatedUserFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UserDocument = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
