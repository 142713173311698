import React from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import { paths } from 'src/navigation/paths';

import useAuth from 'src/hooks/useAuth';

type Props = {};

export const RequireAuthGuard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  // Auth
  const { isSignedIn } = useAuth();

  // Navigation location
  const location = useLocation();

  if (!isSignedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={paths.auth.login.pattern}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};
