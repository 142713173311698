import React, { useMemo, useState } from 'react';

import {
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getEmojiFlag } from 'countries-list';
import { getName } from 'i18n-iso-countries';

import Iconify from 'src/components/atoms/media/Iconify';
import MenuPopover from 'src/components/molecules/menu-popover';
import ConfirmDialog from 'src/components/molecules/dialog/ConfirmDialog';
import Label from 'src/components/atoms/text/Label';

import { UserFragment } from 'src/core';
import { BucketFolder, getBucketPath } from 'src/utils/bucket';

type Props = {
  user: UserFragment;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserRow({
  user,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  // Translation
  const { t, i18n } = useTranslation('common');

  const {
    name,
    type,
    username,
    phoneConfirmed,
    iso31661,
    profile,
    lastActiveAt,
  } = user;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const status = useMemo(() => {
    if (
      !lastActiveAt ||
      moment(lastActiveAt).isBefore(moment().subtract(1, 'week'))
    ) {
      return 'inactive';
    }

    return 'active';
  }, [lastActiveAt]);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // Constants
  const profilePictureUri = useMemo(() => {
    if (!profile.picture) {
      return undefined;
    }

    return getBucketPath(BucketFolder.ProfilePicture, 'w185', profile.picture);
  }, [profile.picture]);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name ?? ''} src={profilePictureUri} />

            <Typography variant="subtitle2" noWrap>
              {username}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{name}</TableCell>

        <TableCell align="left">{type}</TableCell>

        <TableCell align="center">
          <Tooltip title={getName(iso31661, i18n.language)} arrow={true}>
            <Typography variant="h4" noWrap>
              {getEmojiFlag(iso31661)}
            </Typography>
          </Tooltip>
        </TableCell>

        <TableCell align="center">
          <Iconify
            icon={
              phoneConfirmed ? 'eva:checkmark-circle-fill' : 'eva:close-outline'
            }
            sx={{
              width: 20,
              height: 20,
              color: 'success.main',
              ...(!phoneConfirmed && { color: 'warning.main' }),
            }}
          />
        </TableCell>

        <TableCell align="center">
          <Tooltip
            title={lastActiveAt ? moment(lastActiveAt).format('LLL') : 'null'}
            arrow={true}
          >
            <Label
              variant="soft"
              color={status === 'active' ? 'success' : 'warning'}
              sx={{ textTransform: 'capitalize' }}
            >
              {t(`status.${status}`)}
            </Label>
          </Tooltip>
        </TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? 'inherit' : 'default'}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />

          {t('buttons.delete')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />

          {t('buttons.edit')}
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('buttons.delete')}
          </Button>
        }
      />
    </>
  );
}
