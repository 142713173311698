import React, { useCallback } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';

import Iconify from 'src/components/atoms/media/Iconify';

import useResponsive from 'src/hooks/useResponsive';
import useSelector from 'src/hooks/useSelector';
import useActions from 'src/hooks/useActions';
import { NAV } from 'src/layouts/config';
import { themeSettingsSelector } from 'src/core';

export default function NavToggleButton({ sx, ...other }: IconButtonProps) {
  // Selectors
  const { themeLayout } = useSelector(themeSettingsSelector);

  // Actions
  const { setThemeLayoutSetting } = useActions();

  // Responsive
  const isDesktop = useResponsive('up', 'lg');

  // Callbacks
  const handleToggleLayout = useCallback(() => {
    setThemeLayoutSetting(themeLayout === 'vertical' ? 'mini' : 'vertical');
  }, [setThemeLayoutSetting, themeLayout]);

  if (!isDesktop) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={handleToggleLayout}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_DASHBOARD - 12,
        bgcolor: 'background.default',
        zIndex: (theme) => theme.zIndex.appBar + 1,
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          themeLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </IconButton>
  );
}
