import localStorage from 'redux-persist/lib/storage';

import EncryptionService from 'src/utils/encryption-service';

interface Options {
  secretPassphrase: string;
}

class SecureStorage {
  encryptionService: EncryptionService;

  constructor(options: Options) {
    this.encryptionService = new EncryptionService(options.secretPassphrase);
  }

  async getItem(key: string) {
    const value = await localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return this.encryptionService.decrypt(value);
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, this.encryptionService.encrypt(value));
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}

export function createSecureStorage(options: Options) {
  return new SecureStorage({
    ...options,
  });
}
