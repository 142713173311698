import React, { useMemo } from 'react';

import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import merge from 'lodash.merge';

import useSelector from 'src/hooks/useSelector';
import { themeSettingsSelector } from 'src/core';

type Props = {
  children: React.ReactNode;
};

export default function ThemeContrast({ children }: Props) {
  // Theme
  const outerTheme = useTheme();

  // Selectors
  const { themeContrast, themeMode } = useSelector(themeSettingsSelector);

  // Variables
  const isLight = themeMode === 'light';

  const isContrastBold = themeContrast === 'bold';

  // Constants
  const themeOptions = useMemo(
    () => ({
      palette: {
        background: {
          ...(isContrastBold && {
            default: isLight
              ? outerTheme.palette.grey[100]
              : outerTheme.palette.grey[900],
          }),
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            ...(isContrastBold && {
              root: {
                boxShadow: outerTheme.customShadows.z4,
              },
            }),
          },
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLight, themeContrast],
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProvider>
  );
}
