import React, { useMemo } from 'react';

import moment from 'moment';

import { WidgetAreaInstalled } from 'src/components/molecules/widget/WidgetAreaInstalled';

import { AreaCountFragment } from 'src/core';

// Number of year we want in the select
const last = 5;

interface Props {
  title?: string;
  subheader?: string;
  data: AreaCountFragment[];
  year: string;
  onYearChange: (year: string) => void;
}

export const WidgetUsersArea = ({
  title,
  subheader,
  data: initialData,
  year,
  onYearChange,
}: Props) => {
  // Constants
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();

    return Array.from(
      { length: last },
      (v, i) => `${currentYear - last + i + 1}`,
    );
  }, []);

  const data = useMemo(
    () =>
      initialData.map((item) => ({
        name: item.iso31661,
        data: item.monthlyCounts.map(({ count }) => count),
      })),
    [initialData],
  );

  return (
    <WidgetAreaInstalled
      title={title}
      subheader={subheader}
      years={years}
      onYearChange={onYearChange}
      chart={{
        categories: moment.monthsShort(),
        series: {
          year,
          data: data,
        },
      }}
    />
  );
};
