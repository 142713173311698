import React, { useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, Container } from '@mui/material';

import { WidgetDailySummary } from 'src/components/organisms/widget/WidgetDailySummary';
import { WidgetRates } from 'src/components/molecules/widget/WidgetRates';
import { WidgetTopInstalledCountries } from 'src/components/molecules/widget/WidgetTopInstalledCountries';
import { WidgetTopAuthors } from 'src/components/molecules/widget/WidgetTopAuthors';
import { WidgetOS } from 'src/components/organisms/widget/WidgetOS';
import { WidgetUsersArea } from 'src/components/organisms/widget/WidgetUsersArea';

import { useAppStatsQuery } from 'src/core';
import { fPercent } from 'src/utils/formatNumber';

export default function AppPage() {
  // Translation
  const { t } = useTranslation('app-page');

  // State
  const [year, setYear] = useState(`${new Date().getFullYear()}`);

  // Query
  const { data: appStatsData } = useAppStatsQuery({
    variables: {
      dailyStatDto: {
        last: 10,
      },
      usersArea: {
        year,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  // Constants
  const appStats = useMemo(() => appStatsData?.stats, [appStatsData?.stats]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <WidgetDailySummary
              title={t('widgets.users.title')}
              total={appStats?.userDailySummary?.total}
              percentage={appStats?.userDailySummary.percentage ?? 0}
              series={appStats?.userDailySummary?.series}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetDailySummary
              title={t('widgets.shows.title')}
              total={appStats?.showDailySummary?.total}
              percentage={appStats?.showDailySummary?.percentage ?? 0}
              series={appStats?.showDailySummary?.series}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <WidgetDailySummary
              title={t('widgets.activities.title')}
              total={appStats?.activityDailySummary?.total}
              percentage={appStats?.activityDailySummary?.percentage ?? 0}
              series={appStats?.activityDailySummary?.series}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <WidgetOS
              title={t('widgets.OS.title')}
              series={appStats?.usersOS}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <WidgetUsersArea
              title={t('widgets.area.title')}
              subheader={`(${fPercent(
                appStats?.usersArea?.percentage ?? 0,
              )}) than last year`}
              data={appStats?.usersArea?.data ?? []}
              year={year}
              onYearChange={setYear}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <WidgetRates
              title={t('widgets.rates.title')}
              list={[
                { id: '1', name: 'iOS', rating: 4.9, review: 12 },
                { id: '2', name: 'Android', rating: 3, review: 13 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <WidgetTopInstalledCountries
              title={t('widgets.top-installed-countries.title')}
              list={[
                {
                  name: 'France',
                  android: 12,
                  ios: 24,
                  flag: `/assets/icons/flags/ic_flag_${'fr'}.svg`,
                  id: '123',
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <WidgetTopAuthors
              title={t('widgets.top-authors.title')}
              list={appStats?.topUsers ?? []}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
