import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { PaletteMode } from '@mui/material';

import useActions from 'src/hooks/useActions';
import useSelector from 'src/hooks/useSelector';
import { themeSettingsSelector } from 'src/core';

export default function useThemeColorMode() {
  // Media Query
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Selectors
  const { themeMode } = useSelector(themeSettingsSelector);

  // Actions
  const { setThemeModeSetting } = useActions();

  // Constants
  const mode: PaletteMode = useMemo(() => {
    if (!themeMode) {
      return prefersDarkMode ? 'dark' : 'light';
    }

    return themeMode;
  }, [prefersDarkMode, themeMode]);

  return { mode, setThemeModeSetting };
}
