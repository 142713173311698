import React, { useCallback, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { paramCase } from 'change-case';

import { paths } from 'src/navigation/paths';
import Iconify from 'src/components/atoms/media/Iconify';
import Scrollbar from 'src/components/atoms/scroll-bar';
import Breadcrumbs from 'src/components/atoms/navigation/Breadcrumbs';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/molecules/table';
import { SearchSectionRow } from 'src/components/molecules/row/SearchSectionRow';
import ConfirmDialog from 'src/components/molecules/dialog/ConfirmDialog';
import TableToolbar from 'src/components/molecules/toolbar/TableToolbar';

import useTable from 'src/hooks/useTable';
import {
  SearchSectionWhereInput,
  SortOrder,
  UserType,
  useSearchSectionsQuery,
} from 'src/core';
import { emptyRows } from 'src/utils/table';

export default function SearchSectionsPage() {
  // Table
  const {
    order,
    orderBy,
    dense,
    page,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'slug',
  });

  // Navigation
  const navigate = useNavigate();

  // Translation
  const { t, i18n } = useTranslation(['common', 'search-sections-page']);

  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState<UserType | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [whereInput, setWhereInput] = useState<SearchSectionWhereInput | null>(
    null,
  );

  // Queries
  const { data, refetch, fetchMore } = useSearchSectionsQuery({
    variables: {
      first: rowsPerPage,
      where: whereInput ?? undefined,
      locale: i18n.language,
      orderBy: [orderBy ? { [orderBy]: order } : {}, { id: SortOrder.Desc }],
    },
    fetchPolicy: 'cache-and-network',
  });

  // Constants
  const users = useMemo(() => data?.searchSections, [data?.searchSections]);

  const denseHeight = useMemo(() => (dense ? 52 : 72), [dense]);

  const isFiltered = useMemo(
    () => filterName !== '' || !!filterType || filterStatus !== 'all',
    [filterName, filterType, filterStatus],
  );

  const tableHead = useMemo(
    () => [
      {
        id: 'slug',
        label: t('search-sections-page:table-head.slug'),
        align: 'left',
      },
      {
        id: 'title',
        label: t('search-sections-page:table-head.title'),
        align: 'left',
      },
      {
        id: 'order',
        label: t('search-sections-page:table-head.order'),
        align: 'center',
      },
      {
        id: 'curatedBy',
        label: t('search-sections-page:table-head.curated-by'),
        align: 'left',
      },
      { id: '' },
    ],
    [t],
  );

  // Callbacks
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      onChangePage(event, newPage);
      fetchMore({
        variables: {
          cursor: users?.pageInfo?.endCursor,
        },
      });
    },
    [onChangePage, fetchMore, users?.pageInfo?.endCursor],
  );

  const handleOpenConfirm = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleFilterName = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterName(event.target.value);
    },
    [],
  );

  const handleFilterType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // setFilterType(UserType[event.target.value as keyof typeof UserType]);
      setWhereInput((value) => ({
        ...value,
        type: {
          equals: UserType[event.target.value as keyof typeof UserType],
        },
      }));
    },
    [],
  );

  const handleDeleteRow = useCallback(
    (_id: string) => {
      // const deleteRow = tableData.filter((row) => row.id !== id);
      // setSelected([]);
      // setTableData(deleteRow);
      // if (page > 0) {
      // if (dataInPage.length < 2) {
      // setPage(page - 1);
      // }
      // }
    },
    [
      // page, setPage, setSelected
    ],
  );

  const handleDeleteRows = useCallback(
    (_selectedRows: string[]) => {
      // const deleteRows = tableData.filter(
      //   (row) => !selectedRows.includes(row.id),
      // );
      // setSelected([]);
      // setTableData(deleteRows);
      // if (page > 0) {
      //   if (selectedRows.length === dataInPage.length) {
      //     setPage(page - 1);
      //   } else if (selectedRows.length === dataFiltered.length) {
      //     setPage(0);
      //   } else if (selectedRows.length > dataInPage.length) {
      //     const newPage =
      //       Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
      //       1;
      //     setPage(newPage);
      //   }
      // }
    },
    [
      // dataInPage,
      // dataFiltered.length,
      // page,
      // rowsPerPage,
      // setPage,
      // setSelected,
      // tableData,
    ],
  );

  const handleEditRow = useCallback(
    (id: string) => {
      navigate(paths.dashboard.user.edit(paramCase(id)));
    },
    [navigate],
  );

  const handleResetFilter = useCallback(() => {
    setFilterName('');
    setFilterType(null);
    setFilterStatus('TEST');
    refetch({ where: null });
  }, [refetch]);

  return (
    <>
      <Helmet>
        <title>{t('search-sections-page:title')}</title>
      </Helmet>

      <Container maxWidth="lg">
        <Breadcrumbs
          heading={t('search-sections-page:title')}
          links={[
            {
              name: t('common:page-name.dashboard'),
              href: paths.dashboard.app.pattern,
            },
            {
              name: t('common:page-name.search-section-list'),
              href: paths.dashboard.searchSection.list.pattern,
            },
          ]}
          action={
            <Button
              component={RouterLink}
              to={paths.dashboard.user.create.pattern}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('search-sections-page:buttons.new-user')}
            </Button>
          }
        />

        <Card>
          <TableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            onFilterName={handleFilterName}
            onFilterType={handleFilterType}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={users?.edges?.length ?? 0}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  users?.edges?.map((row) => row?.node?.id ?? '') ?? [],
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={dense ? 'small' : 'medium'}
                sx={{ minWidth: 800, maxHeight: 100 }}
              >
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={users?.edges?.length ?? 0}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      users?.edges?.map((row) => row?.node?.id ?? '') ?? [],
                    )
                  }
                />

                <TableBody>
                  {users?.edges?.length ? (
                    users?.edges
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) =>
                        row.node ? (
                          <SearchSectionRow
                            key={row.node?.id}
                            item={row.node}
                            selected={selected.includes(row.node?.id ?? '')}
                            onSelectRow={() => onSelectRow(row.node?.id ?? '')}
                            onDeleteRow={() =>
                              handleDeleteRow(row.node?.id ?? '')
                            }
                            onEditRow={() => handleEditRow(row.node?.id ?? '')}
                          />
                        ) : null,
                      )
                  ) : (
                    <TableNoData />
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      users?.edges?.length ?? 0,
                    )}
                  />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={users?.totalCount ?? 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={t('search-sections-page:delete-section.title')}
        content={
          <>
            {t('search-sections-page:delete-section.text', {
              count: selected.length,
            })}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {t('common:buttons.delete')}
          </Button>
        }
      />
    </>
  );
}
