import React, { useCallback, useRef } from 'react';

import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack';
import { alpha } from '@mui/material/styles';
import { Box, Collapse, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';

import Iconify from 'src/components/atoms/media/Iconify';

import StyledNotistack from 'src/providers/Snackbar/styles';
import { themeSettingsSelector } from 'src/core';

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  // Refs
  const notistackRef = useRef<NotistackProvider>(null);

  // Selectors
  const { themeDirection } = useSelector(themeSettingsSelector);

  // Variables
  const isRTL = themeDirection === 'rtl';

  // Callbacks
  const onClose = useCallback(
    (key: SnackbarKey) => () => {
      if (notistackRef.current) {
        notistackRef.current.closeSnackbar(key);
      }
    },
    [],
  );

  const action = useCallback(
    (key: SnackbarKey) => (
      <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
        <Iconify icon="eva:close-fill" />
      </IconButton>
    ),
    [onClose],
  );

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: (
            <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />
          ),
          warning: (
            <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />
          ),
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        // With close as default
        action={action}
      >
        {children}
      </NotistackProvider>
    </>
  );
}

type SnackbarIconProps = {
  icon: React.ComponentProps<typeof Iconify>['icon'];
  color: 'info' | 'success' | 'warning' | 'error';
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}
