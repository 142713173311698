import produce, { Draft } from 'immer';

import { RootState } from 'src/core/adapters/redux-store';
import { initialState } from 'src/core/adapters/redux-store/state';
import { ActionType } from 'src/core/adapters/redux-store/action-types';
import { Action } from 'src/core/adapters/redux-store/actions';

type StateSlice = RootState['main']['settings'];

export const settingsReducer = produce(
  (draft: Draft<StateSlice>, action: Action) => {
    switch (action.type) {
    case ActionType.SET_LOCALE_LANGUAGE_SETTING: {
      draft.locale.language = action.language;
      break;
    }
    case ActionType.SET_LOCALE_COUNTRY_SETTING: {
      draft.locale.country = action.country;
      break;
    }
    case ActionType.SET_THEME_MODE_SETTING: {
      draft.theme.themeMode = action.themeMode;
      break;
    }
    case ActionType.SET_THEME_DIRECTION_SETTING: {
      draft.theme.themeDirection = action.themeDirection;
      break;
    }
    case ActionType.SET_THEME_CONTRAST_SETTING: {
      draft.theme.themeContrast = action.themeContrast;
      break;
    }
    case ActionType.SET_THEME_LAYOUT_SETTING: {
      draft.theme.themeLayout = action.themeLayout;
      break;
    }
    case ActionType.SET_THEME_STRETCH_SETTING: {
      draft.theme.themeStretch = action.themeStretch;
      break;
    }
    }
  },
  initialState.main.settings,
);
