import React from 'react';

import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from 'src/components/atoms/media/Iconify';

type Props = {
  filterName: string;
  filterType?: string | null;
  isFiltered: boolean;
  optionsType?: string[];
  onResetFilter: VoidFunction;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterType: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TableToolbar({
  isFiltered,
  filterName,
  filterType,
  optionsType,
  onFilterName,
  onFilterType,
  onResetFilter,
}: Props) {
  // Translation
  const { t } = useTranslation(['common', 'users-page']);

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      {optionsType && optionsType.length > 0 && (
        <TextField
          fullWidth
          select
          label={t('common:labels.type')}
          value={filterType}
          onChange={onFilterType}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 260,
                },
              },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {optionsType.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder={t('common:placeholder.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {t('common:buttons.clear')}
        </Button>
      )}
    </Stack>
  );
}
