import React, { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import useThemeColorMode from 'src/hooks/useThemeColorMode';
import ThemeContrast from 'src/theme/ThemeContrast';
import ThemeRtlLayout from 'src/theme/ThemeRtlLayout';
import GlobalStyles from 'src/theme/global-styles';
import typography from 'src/theme/typography';
import palette from 'src/theme/palette';
import shadows from 'src/theme/shadows';
import customShadows from 'src/theme/custom-shadows';
import componentsOverride from 'src/theme/overrides';

type Props = {};

export default function ThemeProvider({
  children,
}: React.PropsWithChildren<Props>) {
  // Color mode
  const { mode } = useThemeColorMode();

  // Create theme
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(mode),
      typography,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: shadows(mode),
      customShadows: customShadows(mode),
    }),
    [mode],
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />

        <GlobalStyles />

        <ThemeContrast>
          <ThemeRtlLayout>{children}</ThemeRtlLayout>
        </ThemeContrast>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
