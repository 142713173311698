import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as config from 'src/services/localization/config';
import date from 'src/services/localization/date';
import yup from 'src/services/localization/yup';
import countries from 'src/services/localization/countries';
import { languageReduxDetector } from 'src/services/localization/redux-detector';
import { isDev } from 'src/utils/env';

const languageDetector = new LanguageDetector();

const initLibraries = (lng: config.Locale) =>
  Promise.all([date.init(lng), yup.init(lng), countries.init(lng)]);

languageDetector.addDetector(languageReduxDetector);

export const init = async () => {
  return new Promise<void>((resolve, reject) => {
    i18next
      .use(languageDetector)
      .use(Backend)
      .use(initReactI18next)
      .init(
        {
          debug: isDev(),
          compatibilityJSON: 'v3',
          fallbackLng: config.fallback,
          defaultNS: config.defaultNamespace,
          supportedLngs: config.supportedLocaleKeys,
          detection: {
            order: ['querystring', 'subdomain', 'redux', 'navigator'],
            lookupQuerystring: 'lang',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            caches: ['redux'],
          },
          interpolation: {
            escapeValue: false,
            format(value, format) {
              if (value instanceof Date) {
                return date.format(value, format);
              }

              return value;
            },
          },
        },
        async (error) => {
          if (error) {
            return reject(error);
          }

          // Initialize libraries
          try {
            await initLibraries(i18next.language as config.Locale);
          } catch (err) {
            return reject(err);
          }

          resolve();
        },
      );
  });
};

i18next.on('languageChanged', (lng: config.Locale) => {
  // Update libraries language
  initLibraries(lng);
});

export default i18next;
