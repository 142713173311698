import React, { useMemo, useState } from 'react';

import { Alert, Stack, IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Iconify from 'src/components/atoms/media/Iconify';
import FormProvider, {
  RHFTextField,
  RHFPhoneNumber,
} from 'src/components/atoms/hook-form';

interface Props {
  email: string;
  error?: Error | undefined | null;
  onSubmit: (values: Form) => Promise<void> | void;
}

export interface Form {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

export const RegisterForm: React.FC<Props> = ({ email, error, onSubmit }) => {
  // Translations
  const { t } = useTranslation(['invite-page', 'common']);

  // State
  const [showPassword, setShowPassword] = useState(false);

  // Schema
  const schema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().email().required(),
        phone: Yup.string().required(),
        password: Yup.string().required(),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match',
        ),
      }).required(),
    [],
  );

  // Form
  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email,
      phone: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting, isValid },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!error?.message && <Alert severity="error">{error.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label={t('common:labels.firstName')} />

          <RHFTextField name="lastName" label={t('common:labels.lastName')} />
        </Stack>

        <RHFTextField
          name="email"
          label={t('common:labels.email')}
          disabled={true}
        />

        <RHFPhoneNumber name="phone" label={t('common:labels.phone')} />

        <RHFTextField
          name="password"
          label={t('common:labels.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label={t('common:labels.confirm-password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={(isSubmitSuccessful && !error) || isSubmitting}
          disabled={!isValid || isSubmitting}
          sx={{
            bgcolor: 'text.primary',
            color: (theme) =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            '&:hover': {
              bgcolor: 'text.primary',
              color: (theme) =>
                theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            },
          }}
        >
          {t('invite-page:buttons.send')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
