import {
  TypedUseSelectorHook,
  useSelector as RRuseSelector,
} from 'react-redux';

import { RootState } from 'src/core/adapters/redux-store';

const useSelector: TypedUseSelectorHook<RootState> = RRuseSelector;

export default useSelector;
