import React, { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import { WidgetDonut } from 'src/components/molecules/widget/WidgetDonut';

import { UsersOs } from 'src/core';

interface Props {
  title: string;
  series: UsersOs[] | undefined;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WidgetOS: React.FC<Props> = ({ title, series }) => {
  // Theme
  const theme = useTheme();

  // Constants
  const usersOSSeries = useMemo(() => {
    if (!series) {
      return [];
    }

    return series.map((item) => ({
      label: item.os,
      value: item.count,
    }));
  }, [series]);

  return (
    <WidgetDonut
      title={title}
      chart={{
        colors: [theme.palette.primary.main, theme.palette.info.main],
        series: usersOSSeries,
      }}
    />
  );
};
