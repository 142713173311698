import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import Breadcrumbs from 'src/components/atoms/navigation/Breadcrumbs';
import UserNewEditForm, {
  FormValuesProps,
} from 'src/components/organisms/form/UserNewEditForm';

import { useUpdateUserMutation, useUserQuery } from 'src/core';

export default function UserEditPage() {
  // Translation
  const { t } = useTranslation(['common', 'user-edit-page']);

  // Params
  const {
    state: { id },
  } = useLocation();

  // Queries
  const { data } = useUserQuery({
    variables: { where: { id } },
    fetchPolicy: 'cache-and-network',
  });

  // Mutation
  const [updateUser] = useUpdateUserMutation();

  const handleUpdateUser = useCallback(
    async (values: FormValuesProps) => {
      await updateUser({
        variables: {
          updateUserData: {
            name: values.name,
            username: values.username,
            ...(values.phoneNumber ? { phone: values.phoneNumber } : {}),
            phoneConfirmed: values.phoneConfirmed,
            type: values.type,
            onboardingStatus: values.onboardingStatus,
          },
          whereUpdateUser: {
            id,
          },
        },
      });
    },
    [updateUser, id],
  );

  return (
    <>
      <Helmet>
        <title>{t('user-edit-page:title')}</title>
      </Helmet>

      <Container maxWidth="lg">
        <Breadcrumbs
          heading="Edit user"
          links={[
            {
              name: t('common:page-name.dashboard'),
              href: paths.dashboard.app.pattern,
            },
            {
              name: t('common:page-name.user-list'),
              href: paths.dashboard.user.list.pattern,
            },
            { name: t('common:page-name.user-edit') },
          ]}
        />

        <UserNewEditForm
          isEdit
          currentUser={data?.user}
          onSubmit={handleUpdateUser}
        />
      </Container>
    </>
  );
}
