import React, { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import { WidgetSummary } from 'src/components/molecules/widget/WidgetSummary';

import { StatSeries } from 'src/core';

interface Props {
  title: string;
  total: number | undefined;
  percentage: number | undefined;
  series: StatSeries[] | undefined;
}

export const WidgetDailySummary: React.FC<Props> = ({
  title,
  total,
  percentage,
  series,
}) => {
  // Theme
  const theme = useTheme();

  // Constants
  const dailySeries = useMemo(
    () => series?.map((item) => item.count).reverse() ?? [],
    [series],
  );

  return (
    <WidgetSummary
      title={title}
      percent={percentage ?? 0}
      total={total ?? 0}
      chart={{
        colors: [theme.palette.primary.main],
        series: dailySeries,
      }}
    />
  );
};
