import React, { useCallback, useState } from 'react';

import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import useSelector from 'src/hooks/useSelector';
import Main from 'src/layouts/dashboard/Main';
import Header from 'src/layouts/dashboard/header';
import NavMini from 'src/layouts/dashboard/nav/NavMini';
import NavVertical from 'src/layouts/dashboard/nav/NavVertical';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import { themeSettingsSelector } from 'src/core';

export default function DashboardLayout() {
  // Selectors
  const { themeLayout } = useSelector(themeSettingsSelector);

  // Responsive
  const isDesktop = useResponsive('up', 'lg');

  // State
  const [open, setOpen] = useState(false);

  // Variables
  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  // Callbacks
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderNavVertical = useCallback(
    () => <NavVertical openNav={open} onCloseNav={handleClose} />,
    [handleClose, open],
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical()}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical()}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical()}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
