import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import {
  ResetPasswordForm,
  Form,
} from 'src/components/organisms/form/ResetPasswordForm';

import { useResetPasswordMutation } from 'src/core';

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default function ResetPasswordPage() {
  // Translation
  const { t } = useTranslation(['reset-password-page', 'common']);

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();

  // Mutation
  const [resetPassword, { error }] = useResetPasswordMutation();

  // Callbacks
  const onSubmit = useCallback(
    async (values: Form) => {
      try {
        if (
          !values.code1 ||
          !values.code2 ||
          !values.code3 ||
          !values.code4 ||
          !values.code5 ||
          !values.code6
        ) {
          throw new Error('Invalid code');
        }

        if (!values.password) {
          throw new Error('Invalid password');
        }

        const result = await resetPassword({
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              email: location.state.email,
              code: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`,
              password: values.password,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        navigate(paths.auth.login.pattern, { replace: true });
      } catch (err) {
        console.log('ERROR: ', err);
      }
    },
    [location.state.email, navigate, resetPassword],
  );

  return (
    <>
      <Helmet>
        <title>{t('reset-password-page:page-title')}</title>
      </Helmet>

      <Content>
        <Typography variant="h4" gutterBottom>
          {t('reset-password-page:title')}
        </Typography>

        <ResetPasswordForm error={error} onSubmit={onSubmit} />
      </Content>
    </>
  );
}
