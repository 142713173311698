import produce, { Draft } from 'immer';

import { RootState } from 'src/core/adapters/redux-store';
import { initialState } from 'src/core/adapters/redux-store/state';
import { ActionType } from 'src/core/adapters/redux-store/action-types';
import { Action } from 'src/core/adapters/redux-store/actions';

type StateSlice = RootState['secure']['authentication'];

export const authenticationReducer = produce(
  (draft: Draft<StateSlice>, action: Action) => {
    switch (action.type) {
    case ActionType.LOGIN:
      draft.credentials = action.credentials;
      return;
    case ActionType.LOGOUT:
      draft.credentials = null;
      return;
    case ActionType.REFRESH_TOKEN:
      if (draft.credentials) {
        draft.credentials.accessToken = action.credentials.accessToken;
        draft.credentials.expiresAt = action.credentials.expiresAt;
        draft.credentials.tokenType = action.credentials.tokenType;

        if (action.credentials.refreshToken) {
          draft.credentials.refreshToken = action.credentials.refreshToken;
        }
      }
      return;
    }
  },
  initialState.secure.authentication,
);
