import { Dispatch } from 'redux';

import { AuthenticationAction } from 'src/core/adapters/redux-store/states/authentication/actions';
import { ActionType } from 'src/core/adapters/redux-store/action-types';
import {
  Credentials,
  RefreshCredentials,
} from 'src/core/entities/authentication';

export const login =
  (credentials: Credentials) => (dispatch: Dispatch<AuthenticationAction>) => {
    dispatch({
      type: ActionType.LOGIN,
      credentials,
    });
  };

export const logout = () => (dispatch: Dispatch<AuthenticationAction>) => {
  dispatch({
    type: ActionType.LOGOUT,
  });
};

export const refreshToken =
  (credentials: RefreshCredentials) =>
    (dispatch: Dispatch<AuthenticationAction>) => {
      dispatch({
        type: ActionType.REFRESH_TOKEN,
        credentials,
      });
    };
