import { Middleware, Store, Action, Reducer, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  PersistConfig,
  persistReducer,
  persistStore,
  Persistor,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { createLogger } from 'redux-logger';
import reduce from 'lodash.reduce';
import { PersistPartial } from 'redux-persist/lib/persistReducer';

import { StateManager } from 'src/core/infrastructure/interfaces/state-manager';

interface StoreOptions<
  ApplicationState extends object,
  ApplicationAction extends Action = Action,
> {
  reducers: {
    [K in keyof ApplicationState]: Reducer<
      ApplicationState[K],
      ApplicationAction
    >;
  };
  persistConfigs: {
    [K in keyof ApplicationState]: PersistConfig<ApplicationState[K]>;
  };
}

export class ReduxStore<
  ApplicationState extends object,
  ApplicationAction extends Action = Action,
> implements StateManager<ApplicationState, ApplicationAction>
{
  private store: Store<ApplicationState, ApplicationAction>;
  private persistor: Persistor;

  constructor(options: StoreOptions<ApplicationState, ApplicationAction>) {
    const { reducers, persistConfigs } = options;

    const rootReducer = combineReducers(
      reduce(
        reducers,
        (acc, value, keyString) => {
          const key = keyString as keyof ApplicationState;

          acc[key] = persistReducer(persistConfigs[key], value);

          return acc;
        },
        {} as {
          [K in keyof ApplicationState]: Reducer<
            ApplicationState[K] & PersistPartial,
            ApplicationAction
          >;
        },
      ),
    );

    const middleware: Array<Middleware> = [];

    if (process.env.NODE_ENV !== 'production') {
      middleware.push(createLogger());
    }

    const store = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }).concat(middleware),
      devTools: process.env.NODE_ENV !== 'production',
    });

    const persistor = persistStore(store);

    this.store = store as unknown as Store<ApplicationState, ApplicationAction>;
    this.persistor = persistor;
  }

  getState(): ApplicationState {
    return this.store.getState();
  }

  dispatch(action: ApplicationAction): void {
    this.store.dispatch(action);
  }

  subscribe(listener: () => void): () => void {
    return this.store.subscribe(listener);
  }

  getStore(): Store<ApplicationState, ApplicationAction> {
    return this.store;
  }

  getPersistor(): Persistor {
    return this.persistor;
  }
}
