import React, { useState, useMemo } from 'react';

import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Alert, Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { paths } from 'src/navigation/paths';
import Iconify from 'src/components/atoms/media/Iconify';
import FormProvider, { RHFTextField } from 'src/components/atoms/hook-form';

interface Props {
  error?: Error | undefined | null;
  onSubmit: (values: Form) => Promise<void> | void;
}

export interface Form {
  email: string;
  password: string;
}

export const LoginForm: React.FC<Props> = ({ error, onSubmit }) => {
  // Translations
  const { t } = useTranslation(['login-page', 'common']);

  // State
  const [showPassword, setShowPassword] = useState(false);

  // Schema
  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      }).required(),
    [],
  );

  const methods = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isValid },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!error?.message && <Alert severity="error">{error.message}</Alert>}

        <RHFTextField
          id="email"
          name="email"
          label={t('common:labels.email')}
        />

        <RHFTextField
          id="password"
          name="password"
          label={t('common:labels.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={paths.auth.forgotPassword.pattern}
          variant="body2"
          color="inherit"
          underline="always"
        >
          {t('login-page:buttons.forgot-password')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={(isSubmitSuccessful && !error) || isSubmitting}
        disabled={!isValid || isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) =>
            theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          },
        }}
      >
        {t('login-page:buttons.login')}
      </LoadingButton>
    </FormProvider>
  );
};
