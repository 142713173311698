/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { List, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NavSectionProps } from 'src/components/molecules/nav-section/types';
import { StyledSubheader } from 'src/components/molecules/nav-section/vertical/styles';
import NavList from 'src/components/molecules/nav-section/vertical/NavList';

export default function NavSectionVertical({
  data,
  sx,
  ...other
}: NavSectionProps) {
  const { t } = useTranslation();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>
                {t(group.subheader as any)}
              </StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
