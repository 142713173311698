import React from 'react';

import { RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import router from 'src/navigation/routes';

import useApolloClient from 'src/hooks/useApolloClient';
import CurrentAdminProvider from 'src/providers/CurrentAdmin/Provider';
import SnackbarProvider from 'src/providers/Snackbar/SnackbarProvider';
import ThemeProvider from 'src/theme';

export default function Navigation() {
  // Apollo client
  const { client } = useApolloClient();

  if (!client) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <CurrentAdminProvider>
          <SnackbarProvider>
            <RouterProvider router={router} />
          </SnackbarProvider>
        </CurrentAdminProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
