import React, { useCallback, useEffect, useMemo } from 'react';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CustomFile } from 'src/interfaces/upload.interface';

import { paths } from 'src/navigation/paths';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
  RHFSwitch,
} from 'src/components/atoms/hook-form';

import { useSnackbar } from 'src/providers/Snackbar';
import { fData } from 'src/utils/formatNumber';
import { OnboardingStatus, UserFragment, UserRole, UserType } from 'src/core';

export interface FormValuesProps {
  avatarUrl: CustomFile | string | null;
  name: string;
  username: string;
  type: UserType;
  role: UserRole;
  phoneNumber: string;
  phoneConfirmed: boolean;
  birthYear: string;
  onboardingStatus: OnboardingStatus;
}

type Props = {
  isEdit?: boolean;
  currentUser?: UserFragment;
  onSubmit: (values: FormValuesProps) => void;
};

export default function UserNewEditForm({
  isEdit = false,
  currentUser,
  onSubmit: onSubmitCb,
}: Props) {
  // Navigation
  const navigate = useNavigate();

  // Translation
  const { t } = useTranslation([
    'common',
    'user-create-page',
    'user-edit-page',
  ]);

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // Form validation
  const newUserSchema = Yup.object().shape({
    name: Yup.string().required(
      t('user-create-page:error.required', {
        fieldName: t('common:labels.fullName'),
      }),
    ),
    username: Yup.string().required(
      t('user-create-page:error.required', {
        fieldName: t('common:labels.username'),
      }),
    ),
    type: Yup.string().required(
      t('user-create-page:error.required', {
        fieldName: t('common:labels.type'),
      }),
    ),
    role: Yup.string().required(
      t('user-create-page:error.required', {
        fieldName: t('common:labels.role'),
      }),
    ),
    avatarUrl: Yup.string()
      .required(
        t('user-create-page:error.required', {
          fieldName: t('common:labels.avatar'),
        }),
      )
      .nullable(true),
  });

  // Constants
  const defaultValues = useMemo(
    () => ({
      ...(isEdit ? { id: currentUser?.id || '' } : {}),
      name: currentUser?.name || '',
      username: currentUser?.username || '',
      phoneNumber: currentUser?.phone || '',
      avatarUrl: currentUser?.profile.picture || null,
      type: currentUser?.type || UserType.User,
      role: currentUser?.role || UserRole.User,
      phoneConfirmed: currentUser?.phoneConfirmed || false,
      onboardingStatus:
        currentUser?.onboardingStatus || OnboardingStatus.Ongoing,
      birthYear: currentUser?.profile.birthday
        ? moment(currentUser?.profile.birthday).format('YYYY')
        : '',
    }),
    [currentUser, isEdit],
  );

  // Form
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(newUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Effects
  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  // Callbacks
  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        // await new Promise((resolve) => setTimeout(resolve, 500));
        await onSubmitCb(data);

        reset();
        enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
        navigate(paths.dashboard.user.list.pattern);
      } catch (error) {
        console.error(error);
      }
    },
    [isEdit, navigate, reset, enqueueSnackbar, onSubmitCb],
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue],
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    {t('user-create-page:avatar.message', {
                      size: fData(3145728),
                    })}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              {isEdit && (
                <RHFTextField
                  name="id"
                  label={t('common:labels.id')}
                  disabled={true}
                />
              )}

              <RHFTextField name="name" label={t('common:labels.fullName')} />

              <RHFTextField
                name="username"
                label={t('common:labels.username')}
              />

              <RHFTextField
                name="phoneNumber"
                label={t('common:labels.phone')}
              />

              <RHFSelect
                native
                name="type"
                label={t('common:labels.type')}
                placeholder={t('common:labels.type')}
              >
                {Object.values(UserType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect
                native
                name="role"
                label={t('common:labels.role')}
                placeholder={t('common:labels.role')}
              >
                {Object.values(UserRole).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect
                native
                name="onboardingStatus"
                label={t('common:labels.onboarding-status')}
                placeholder={t('common:labels.onboarding-status')}
              >
                {Object.values(OnboardingStatus).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField
                name="birthYear"
                label={t('common:labels.birthYear')}
              />

              <RHFSwitch
                name="phoneConfirmed"
                label={t('common:labels.phoneConfirmed')}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {isEdit
                  ? t('user-edit-page:buttons.save')
                  : t('user-create-page:buttons.save')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
