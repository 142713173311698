import { createContext } from 'react';

import { ApolloClientContextValue } from 'src/interfaces/apollo-client-context.interface';

export const defaultContext: ApolloClientContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  client: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cleanAndClose: async () => {},
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ApolloClientContext =
  createContext<ApolloClientContextValue>(defaultContext);

ApolloClientContext.displayName = 'ApolloClientContextValue';

export default ApolloClientContext;
