import React from 'react';

import { ApexOptions } from 'apexcharts';
import { Card, CardHeader, Box, CardProps } from '@mui/material';

import { SmallSelect } from 'src/components/atoms/input/SmallSelect';
import Chart from 'src/components/atoms/chart';

import useChart from 'src/hooks/useChart';

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  years: string[];
  chart: {
    categories?: string[];
    colors?: string[];
    series: {
      year: string;
      data: {
        name: string;
        data: number[];
      }[];
    };
    options?: ApexOptions;
  };
  onYearChange: (year: string) => void;
}

export function WidgetAreaInstalled({
  title,
  subheader,
  years,
  chart,
  onYearChange,
  ...other
}: Props) {
  const { colors, categories, series, options } = chart;

  const chartOptions = useChart({
    colors,
    xaxis: {
      categories,
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <SmallSelect
            value={series.year}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onYearChange(event.target.value)
            }
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SmallSelect>
        }
      />

      <Box key={series.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart
          type="line"
          series={series.data}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
