import React from 'react';

import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';

import { MotionContainer, varBounce } from 'src/components/atoms/animate';

import useCurrentAdmin from 'src/hooks/useCurrentAdmin';
import { ForbiddenIllustration } from 'src/assets/illustrations';
import { AdminRole } from 'src/core';

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: AdminRole[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { admin } = useCurrentAdmin();

  // const currentRole = 'user';
  const currentRole = admin?.role; // admin;

  if (
    !currentRole ||
    (typeof roles !== 'undefined' && !roles.includes(currentRole))
  ) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
