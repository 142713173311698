export enum ActionType {
  // Authentication
  LOGIN = '@Authentication/LOGIN',
  LOGOUT = '@Authentication/LOGOUT',
  REFRESH_TOKEN = '@Authentication/REFRESH_TOKEN',

  // Settings
  SET_LOCALE_LANGUAGE_SETTING = '@Settings/SET_LOCALE_LANGUAGE',
  SET_LOCALE_COUNTRY_SETTING = '@Settings/SET_LOCALE_COUNTRY',
  SET_COLOR_MODE_SETTING = '@Settings/SET_COLOR_MODE',
  SET_THEME_MODE_SETTING = '@Settings/SET_THEME_MODE',
  SET_THEME_DIRECTION_SETTING = '@Settings/SET_THEME_DIRECTION',
  SET_THEME_CONTRAST_SETTING = '@Settings/SET_THEME_CONTRAST',
  SET_THEME_LAYOUT_SETTING = '@Settings/SET_THEME_LAYOUT',
  SET_THEME_STRETCH_SETTING = '@Settings/SET_THEME_STRETCH',
}
