const bucketImageSizes = {
  'profile-picture': ['w45', 'w185', 'w632', 'original'],
  'back-drop-picture': ['w300', 'w780', 'w1280', 'original'],
  'poster-picture': ['w92', 'w154', 'w185', 'w342', 'w500', 'w780', 'original'],
  'poster-share-picture': ['original'],
  'logo-picture': ['w65', 'w100', 'original'],
} as const;

export enum BucketFolder {
  ProfilePicture = 'profile-picture',
  BackDropPicture = 'back-drop-picture',
  PosterPicture = 'poster-picture',
  PosterSharePicture = 'poster-share-picture',
  LogoPicture = 'logo-picture',
}

type BucketKey = `${BucketFolder}`;

type Sizes = typeof bucketImageSizes;

export const getBucketPath = <K extends BucketKey>(
  bucketKey: K,
  size: Sizes[K][number],
  fileName: string,
) => {
  return `${process.env.REACT_APP_MEDIA_URL}${bucketKey}/${size}${fileName}`;
};
