import React, { useCallback, useMemo } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormProvider, {
  RHFMentionTextField,
  RHFSelect,
} from 'src/components/atoms/hook-form';

import { PushNotificationPriority, PushNotificationRecipient } from 'src/core';

export interface FormValuesProps {
  title: string;
  subtitle?: string;
  description: string;
  recipientStatus: PushNotificationRecipient;
  priority: PushNotificationPriority;
}

interface Props {
  onSubmit: (values: FormValuesProps) => Promise<void> | void;
}

export default function NotificationForm({ onSubmit: onSubmitCb }: Props) {
  // Translation
  const { t } = useTranslation(['notification-page', 'common']);

  // Schema
  const schema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().max(50).required(),
        subtitle: Yup.string().max(50),
        description: Yup.string().min(20).max(150).required(),
        recipientStatus: Yup.string()
          .oneOf(Object.values(PushNotificationRecipient))
          .required(),
        priority: Yup.string()
          .oneOf(Object.values(PushNotificationPriority))
          .required(),
      }).required(),
    [],
  );

  // Form
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: '',
      subtitle: '',
      description: '',
      recipientStatus: PushNotificationRecipient.Everyone,
      priority: PushNotificationPriority.Default,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Callbacks
  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        // await new Promise((resolve) => setTimeout(resolve, 500));
        await onSubmitCb(data);

        reset();
      } catch (error) {
        console.error(error);
      }
    },
    [reset, onSubmitCb],
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFMentionTextField
                name="title"
                label={t('common:labels.title')}
              />

              <RHFMentionTextField
                name="subtitle"
                label={t('common:labels.subtitle')}
              />

              <RHFMentionTextField
                name="description"
                label={t('common:labels.description')}
                multiline
                rows={3}
              />
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFSelect
                native
                name="recipientStatus"
                label={t('notification-page:labels.recipient')}
                placeholder={t('notification-page:labels.recipient')}
              >
                {Object.values(PushNotificationRecipient).map((type) => (
                  <option key={type} value={type}>
                    {t(`notification-page:values.recipient.${type}`)}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect
                native
                name="priority"
                label={t('notification-page:labels.priority')}
                placeholder={t('notification-page:labels.priority')}
              >
                {Object.values(PushNotificationPriority).map((type) => (
                  <option key={type} value={type}>
                    {t(`notification-page:values.priority.${type}`)}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
            >
              {t('notification-page:buttons.send')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
