import { useMemo, useContext } from 'react';

import CurrentAdminContext from 'src/providers/CurrentAdmin/Context';

export default function useCurrentAdmin() {
  // Current admin context
  const { admin } = useContext(CurrentAdminContext);

  // Constants
  const isRetrieved = useMemo(() => !!admin, [admin]);

  return {
    adminId: admin?.id,
    admin,
    isRetrieved,
  };
}
