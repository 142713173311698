import React from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

type Props = MuiTelInputProps & {
  name: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function RHFPhoneNumber({ name, helperText, ...other }: Props) {
  // Translation
  const { i18n } = useTranslation();

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          fullWidth
          error={!!error}
          langOfCountryName={i18n.language}
          preferredCountries={['FR']}
          defaultCountry="FR"
          forceCallingCode={true}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
