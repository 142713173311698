import { Dispatch } from 'redux';

import { SettingsAction } from 'src/core/adapters/redux-store/states/settings/actions';
import { ActionType } from 'src/core/adapters/redux-store/action-types';
import { LocaleSettings } from 'src/core/entities/locale-settings';
import { ThemeSettings } from 'src/core/entities/theme-settings';

export const setLocaleLanguageSetting =
  (language: LocaleSettings['language']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_LOCALE_LANGUAGE_SETTING,
        language,
      });
    };

export const setLocaleCountrySetting =
  (country: LocaleSettings['country']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_LOCALE_COUNTRY_SETTING,
        country,
      });
    };

export const setThemeModeSetting =
  (themeMode: ThemeSettings['themeMode']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_THEME_MODE_SETTING,
        themeMode,
      });
    };

export const setThemeDirectionSetting =
  (themeDirection: ThemeSettings['themeDirection']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_THEME_DIRECTION_SETTING,
        themeDirection,
      });
    };

export const setThemeContrastSetting =
  (themeContrast: ThemeSettings['themeContrast']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_THEME_CONTRAST_SETTING,
        themeContrast,
      });
    };

export const setThemeLayoutSetting =
  (themeLayout: ThemeSettings['themeLayout']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_THEME_LAYOUT_SETTING,
        themeLayout,
      });
    };

export const setThemeStretchSetting =
  (themeStretch: ThemeSettings['themeStretch']) =>
    (dispatch: Dispatch<SettingsAction>) => {
      dispatch({
        type: ActionType.SET_THEME_STRETCH_SETTING,
        themeStretch,
      });
    };
