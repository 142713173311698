import React, { useCallback, Suspense } from 'react';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import LoadingScreen from 'src/components/organisms/loading/LoadingScreen';

import { storeAdapter } from 'src/utils/store';
import Navigation from 'src/navigation';
import ApolloClientProvider from 'src/providers/ApolloClient/Provider';
import { init as initI18next } from 'src/services/localization';
import 'src/App.css';

function App() {
  // Callbacks
  const handleBeforeLift = useCallback(async () => {
    // Init i18next when redux store is loaded
    await initI18next();
  }, []);

  return (
    <Provider store={storeAdapter.getStore()}>
      <PersistGate
        loading={null}
        persistor={storeAdapter.getPersistor()}
        onBeforeLift={handleBeforeLift}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Suspense fallback={<LoadingScreen />}>
            <ApolloClientProvider>
              <Navigation />
            </ApolloClientProvider>
          </Suspense>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
