import {
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-empty-function
let promiseResolve = (_value: ApolloClient<NormalizedCacheObject> | null) => {};
export const getApolloClient = new Promise<ApolloClient<NormalizedCacheObject> | null>((resolve) => {
  promiseResolve = resolve;
});

export const setApolloInstance = (client: ApolloClient<NormalizedCacheObject> | null) => {
  promiseResolve(client);
};
