import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
} from '@apollo/client';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { relayStylePagination } from '@apollo/client/utilities';

import useActions from 'src/hooks/useActions';
import ApolloAdapter from 'src/core/adapters/apollo';
import ApolloClientContext from 'src/providers/ApolloClient/Context';
import { setApolloInstance } from 'src/utils/apollo';
import { storeAdapter } from 'src/utils/store';

const ApolloClientProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  // Actions
  const { logout } = useActions();

  // States
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);
  // const [wsClient, setWSClient] = useState<WSClient | null>(null);
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject> | null>(null);

  // Callbacks
  const init = useCallback(async () => {
    const apolloAdapter = new ApolloAdapter({
      uri: `${process.env.REACT_APP_API_URL}graphql`,
      wsUri: `${process.env.REACT_APP_WS_URL}subscriptions`,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              users: relayStylePagination(['search', 'where']),
            },
          },
        },
      }),
      storeAdapter: storeAdapter,
      name: 'web-dashboard',
      version: process.env.npm_package_version,
      onUnauthenticatedError() {
        // Logout user
        logout();
      },
      persistOptions: {
        storage: new LocalStorageWrapper(window.localStorage),
        debug: process.env.NODE_ENV === 'development',
      },
    });

    await apolloAdapter.init();

    setClient(apolloAdapter.getClient());
    setPersistor(apolloAdapter.getPersistor());

    // Keep global instance accessible to use it outside hooks
    setApolloInstance(apolloAdapter.getClient());
  }, [logout]);

  const cleanAndClose = useCallback(async () => {
    await client?.resetStore();
    await client?.cache.reset();
    await persistor?.purge();
    await persistor?.persist();
  }, [client, persistor]);

  // Value
  const value = useMemo(
    () => ({
      init,
      client,
      cleanAndClose,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, cleanAndClose],
  );

  // Effect
  useEffect(() => {
    // Init at launch
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloClientContext.Provider value={value}>
      {children}
    </ApolloClientContext.Provider>
  );
};

export default ApolloClientProvider;
