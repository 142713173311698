import React from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import { paths } from 'src/navigation/paths';

import RootLayout from 'src/layouts/root/RootLayout';
import AuthLayout from 'src/layouts/auth/AuthLayout';
import CompactLayout from 'src/layouts/compact/CompactLayout';
import AppPage from 'src/pages/root/AppPage';
import LoginPage from 'src/pages/auth/LoginPage';
import OtpPage from 'src/pages/auth/OtpPage';
import ForgotPasswordPage from 'src/pages/auth/ForgotPasswordPage';
import ResetPasswordPage from 'src/pages/auth/ResetPasswordPage';
import InvitePage, {
  loader as invitePageLoader,
} from 'src/pages/auth/InvitePage';
import { RequireAuthGuard } from 'src/guards/RequireAuthGuard';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import UsersPage from 'src/pages/root/UsersPage';
import UserCreatePage from 'src/pages/root/UserCreatePage';
import UserEditPage from 'src/pages/root/UserEditPage';
import SearchSectionsPage from 'src/pages/root/search-sections/SearchSectionsPage';
import NotificationPage from 'src/pages/root/NotificationPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<RootLayout />}>
      <Route path={paths.auth.pattern} element={<AuthLayout />}>
        <Route path={paths.auth.login.pattern} element={<LoginPage />} />

        <Route
          path={paths.auth.invite.pattern}
          loader={invitePageLoader}
          element={<InvitePage />}
        />
      </Route>

      <Route path={paths.auth.pattern} element={<CompactLayout />}>
        <Route path={paths.auth.otp.pattern} element={<OtpPage />} />

        <Route
          path={paths.auth.forgotPassword.pattern}
          element={<ForgotPasswordPage />}
        />

        <Route
          path={paths.auth.resetPassword.pattern}
          element={<ResetPasswordPage />}
        />
      </Route>

      <Route
        path={paths.dashboard.pattern}
        element={
          <RequireAuthGuard>
            <DashboardLayout />
          </RequireAuthGuard>
        }
      >
        <Route
          index={true}
          element={
            <RequireAuthGuard>
              <Navigate to={paths.dashboard.app.pattern} replace={true} />
            </RequireAuthGuard>
          }
        />

        <Route path={paths.dashboard.app.pattern} element={<AppPage />} />

        <Route
          path={paths.dashboard.notification.pattern}
          element={<NotificationPage />}
        />

        <Route
          path={paths.dashboard.user.list.pattern}
          element={<UsersPage />}
        />

        <Route
          path={paths.dashboard.user.create.pattern}
          element={<UserCreatePage />}
        />

        <Route
          path={paths.dashboard.user.edit.pattern}
          element={<UserEditPage />}
        />

        <Route
          path={paths.dashboard.searchSection.list.pattern}
          element={<SearchSectionsPage />}
        />
      </Route>

      <Route
        index={true}
        element={
          <RequireAuthGuard>
            <Navigate to={paths.dashboard.app.pattern} replace={true} />
          </RequireAuthGuard>
        }
      />
    </Route>,
  ),
);

export default router;
