import CryptoJS from 'crypto-js';

/**
 * EncryptionService
 */
class EncryptionService {
  secretPassphrase = '';

  constructor(secretPassphrase: string) {
    this.secretPassphrase = secretPassphrase;
  }

  /**
   * Function to encrypt data
   * @param value
   * @returns
   */
  encrypt(data: string) {
    const cypher = CryptoJS.AES.encrypt(data, this.secretPassphrase).toString();

    return cypher;
  }

  /**
   * Function to decrypt data
   * @param value
   * @returns
   */
  decrypt(cypher: string) {
    try {
      const bytes = CryptoJS.AES.decrypt(cypher, this.secretPassphrase);
      const data = bytes.toString(CryptoJS.enc.Utf8);

      return data || null;
    } catch (ex) {
      return null;
    }
  }
}

export default EncryptionService;
