import { useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Action } from 'src/core/adapters/redux-store';
import * as actionCreators from 'src/core/adapters/redux-store/action-creators';

export default function useActions() {
  const dispatch = useDispatch<Dispatch<Action>>();

  return bindActionCreators(actionCreators, dispatch);
}
