/* eslint-disable indent */
import React, { useCallback, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import Iconify from 'src/components/atoms/media/Iconify';
import Breadcrumbs from 'src/components/atoms/navigation/Breadcrumbs';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/molecules/table';
import UserRow from 'src/components/molecules/row/UserRow';
import ConfirmDialog from 'src/components/molecules/dialog/ConfirmDialog';
import TableToolbar from 'src/components/molecules/toolbar/TableToolbar';

import useTable from 'src/hooks/useTable';
import { SortOrder, UserType, UserWhereInput, useUsersQuery } from 'src/core';
import { emptyRows } from 'src/utils/table';

const STATUS_OPTIONS = ['all', 'deleted'];

export default function UserListPage() {
  // Table
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  // Navigation
  const navigate = useNavigate();

  // Translation
  const { t } = useTranslation(['common', 'users-page']);

  const [filterName, setFilterName] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [whereInput, setWhereInput] = useState<UserWhereInput | null>(null);

  // Queries
  const { data, fetchMore } = useUsersQuery({
    variables: {
      first: rowsPerPage,
      search: filterName,
      where: whereInput,
      orderBy: [orderBy ? { [orderBy]: order } : {}, { id: SortOrder.Desc }],
    },
    fetchPolicy: 'cache-and-network',
  });

  // Constants
  const users = useMemo(() => data?.users, [data?.users]);

  const denseHeight = useMemo(() => (dense ? 52 : 72), [dense]);

  const isFiltered = useMemo(
    () => filterName !== '' || !!whereInput || filterStatus !== 'all',
    [filterName, whereInput, filterStatus],
  );

  const tableHead = useMemo(
    () => [
      {
        id: 'username',
        label: t('users-page:table-head.username'),
        align: 'left',
      },
      { id: 'name', label: t('users-page:table-head.name'), align: 'left' },
      { id: 'type', label: t('users-page:table-head.type'), align: 'left' },
      {
        id: 'iso31661',
        label: t('users-page:table-head.country'),
        align: 'center',
      },
      {
        id: 'phoneConfirmed',
        label: t('users-page:table-head.verified'),
        align: 'center',
      },
      {
        id: 'lastActiveAt',
        label: t('users-page:table-head.status'),
        align: 'center',
      },
      { id: '' },
    ],
    [t],
  );

  const optionsRole = useMemo(
    () => [t('users-page:filter.all'), ...Object.keys(UserType)],
    [t],
  );

  // Callbacks
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      onChangePage(event, newPage);
      fetchMore({
        variables: {
          cursor: users?.pageInfo?.endCursor,
        },
      });
    },
    [onChangePage, fetchMore, users?.pageInfo?.endCursor],
  );

  const handleOpenConfirm = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
      setPage(0);
      setFilterStatus(newValue);
      switch (newValue) {
        case 'all':
          setWhereInput((value) => {
            delete value?.deleted;

            if (value && Object.keys(value).length === 0) {
              return null;
            }

            return value;
          });
          break;
        case 'deleted':
          setWhereInput((value) => ({
            ...value,
            deleted: {
              not: null,
            },
          }));
          break;
      }
    },
    [setPage],
  );

  const handleFilterName = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setPage(0);
      setFilterName(event.target.value);
    },
    [setPage],
  );

  const handleFilterType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPage(0);
      setWhereInput((value) => ({
        ...value,
        type: {
          equals: UserType[event.target.value as keyof typeof UserType],
        },
      }));
    },
    [setPage],
  );

  const handleDeleteRow = useCallback((_id: string) => {
    // TODO: Bind with delete mutation
  }, []);

  const handleDeleteRows = useCallback((_selectedRows: string[]) => {
    // TODO: Bind with delete mutation
  }, []);

  const handleEditRow = useCallback(
    (id: string) => {
      navigate(paths.dashboard.user.edit.pattern, { state: { id } });
    },
    [navigate],
  );

  const handleResetFilter = useCallback(() => {
    setFilterName('');
    setWhereInput(null);
    setFilterStatus(t('users-page:filter.all'));
    // refetch({ where: null, search: null });
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t('users-page:title')}</title>
      </Helmet>

      <Container maxWidth="lg">
        <Breadcrumbs
          heading={t('users-page:title')}
          links={[
            {
              name: t('common:page-name.dashboard'),
              href: paths.dashboard.app.pattern,
            },
            {
              name: t('common:page-name.user'),
              href: paths.dashboard.user.list.pattern,
            },
            { name: t('common:page-name.user-list') },
          ]}
          action={
            <Button
              component={RouterLink}
              to={paths.dashboard.user.create.pattern}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('users-page:buttons.new-user')}
            </Button>
          }
        />

        <Card sx={{ flex: 1 }}>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <TableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterType={
              Object.keys(UserType)[
                Object.values(UserType).indexOf(
                  whereInput?.type?.equals ?? (null as unknown as UserType),
                )
              ] ?? t('users-page:filter.all')
            }
            optionsType={optionsRole}
            onFilterName={handleFilterName}
            onFilterType={handleFilterType}
            onResetFilter={handleResetFilter}
          />

          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              overflowY: 'scroll',
            }}
          >
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={users?.edges?.length ?? 0}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  users?.edges?.map((row) => row?.node?.id ?? '') ?? [],
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                rowCount={users?.edges?.length ?? 0}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    users?.edges?.map((row) => row?.node?.id ?? '') ?? [],
                  )
                }
              />

              <TableBody>
                {users?.edges?.length ? (
                  users?.edges
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) =>
                      row.node ? (
                        <UserRow
                          key={row.node?.id}
                          user={row.node}
                          selected={selected.includes(row.node?.id ?? '')}
                          onSelectRow={() => onSelectRow(row.node?.id ?? '')}
                          onDeleteRow={() =>
                            handleDeleteRow(row.node?.id ?? '')
                          }
                          onEditRow={() => handleEditRow(row.node?.id ?? '')}
                        />
                      ) : null,
                    )
                ) : (
                  <TableNoData />
                )}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(
                    page,
                    rowsPerPage,
                    users?.edges?.length ?? 0,
                  )}
                />
              </TableBody>
            </Table>
          </TableContainer>

          <TablePaginationCustom
            count={users?.totalCount ?? 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={t('users-page:delete-user.title')}
        content={
          <>{t('users-page:delete-user.text', { count: selected.length })}</>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {t('common:buttons.delete')}
          </Button>
        }
      />
    </>
  );
}
