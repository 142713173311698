import React from 'react';

import {
  Box,
  Card,
  Rating,
  CardHeader,
  Typography,
  Stack,
  CardProps,
} from '@mui/material';

import { fShortenNumber } from 'src/utils/formatNumber';

type ItemProps = {
  id: string;
  name: string;
  rating: number;
  review: number;
};

function Item({ rating, review, name }: ItemProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ flexGrow: 1, minWidth: 160 }}>
        <Typography variant="subtitle2">{name}</Typography>
      </Box>

      <Stack alignItems="flex-end" sx={{ pr: 3 }}>
        <Rating
          readOnly
          size="small"
          precision={0.5}
          name="reviews"
          value={rating}
        />

        <Typography variant="caption" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {fShortenNumber(review)} reviews
        </Typography>
      </Stack>
    </Stack>
  );
}

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  list: ItemProps[];
}

export function WidgetRates({ title, subheader, list, ...other }: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
        {list.map((app) => (
          <Item key={app.id} {...app} />
        ))}
      </Stack>
    </Card>
  );
}
