import { ApolloClient } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { Client as WSClient } from 'graphql-ws';

import { Apollo, Options } from 'src/core/infrastructure/apollo';

export interface ApolloAdapterInterface<TCacheShape> {
  getClient(): ApolloClient<TCacheShape> | null;
  getPersistor(): CachePersistor<TCacheShape> | null;
  getWSClient(): WSClient | null;
}

export default class ApolloAdapter<TCacheShape>
implements ApolloAdapterInterface<TCacheShape>
{
  private apollo: Apollo<TCacheShape>;

  constructor(options: Options<TCacheShape>) {
    this.apollo = new Apollo(options);
  }

  async init() {
    await this.apollo.init();
  }

  getClient() {
    return this.apollo.getClient();
  }

  getPersistor() {
    return this.apollo.getPersistor();
  }

  getWSClient() {
    return this.apollo.getWSClient();
  }
}
