import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Stack, Typography } from '@mui/material';

import { paths } from 'src/navigation/paths';
import { LoginForm, Form } from 'src/components/organisms/form/LoginForm';

import { useChallengeMutation } from 'src/core';

export default function LoginPage() {
  // Translation
  const { t } = useTranslation(['login-page', 'common']);

  // Navigate
  const navigate = useNavigate();

  // Mutation
  const [challenge, { error }] = useChallengeMutation({
    errorPolicy: 'all',
  });

  // Callbacks
  const handleSubmit = useCallback(
    async (values: Form) => {
      try {
        if (!values.email) {
          throw new Error('Invalid email');
        }

        if (!values.password) {
          throw new Error('Invalid password');
        }

        const result = await challenge({
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              email: values.email,
              password: values.password,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        navigate(paths.auth.otp.pattern, {
          state: { email: values.email, password: values.password },
        });
        // Error is used in the hook
      } catch {}
    },
    [challenge, navigate],
  );

  return (
    <>
      <Helmet>
        <title>{t('login-page:page-title')}</title>
      </Helmet>

      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">{t('login-page:title')}</Typography>
      </Stack>

      <LoginForm error={error} onSubmit={handleSubmit} />
    </>
  );
}
