import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import Breadcrumbs from 'src/components/atoms/navigation/Breadcrumbs';
import UserNewEditForm, {
  FormValuesProps,
} from 'src/components/organisms/form/UserNewEditForm';
// import { useCreateUserMutation } from 'src/core';

export default function UserCreatePage() {
  // Translation
  const { t } = useTranslation(['common', 'user-create-page']);

  // const [createUser] = useCreateUserMutation();

  const handleUpdateUser = useCallback(async (_values: FormValuesProps) => {
    // createUser({
    //   variables: {
    //     data: {
    //       name: values.name,
    //       username: values.username,
    //       ...(values.phoneNumber ? { phone: values.phoneNumber } : {}),
    //       phoneConfirmed: values.phoneConfirmed,
    //       type: values.type,
    //       onboardingStatus: values.onboardingStatus,
    //     },
    //   },
    // });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('user-create-page:title')}</title>
      </Helmet>

      <Container maxWidth="lg">
        <Breadcrumbs
          heading={t('user-create-page:title')}
          links={[
            {
              name: t('common:page-name.dashboard'),
              href: paths.dashboard.app.pattern,
            },
            {
              name: t('common:page-name.user'),
              href: paths.dashboard.user.list.pattern,
            },
            { name: t('common:page-name.user-create') },
          ]}
        />

        <UserNewEditForm onSubmit={handleUpdateUser} />
      </Container>
    </>
  );
}
