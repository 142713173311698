import * as Yup from 'yup';

import * as config from 'src/services/localization/config';

const yup = {
  /**
   * Load library, setting its initial locale
   *
   * @param {string} locale
   * @return Promise
   */
  async init(locale: config.Locale): Promise<void> {
    const translations = await config.supportedLocales[
      locale
    ].libraries.yupLoader();

    Yup.setLocale(translations);
  },
};

export default yup;
