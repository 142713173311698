import localStorage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import ReduxStoreAdapter from 'src/core/adapters/redux-store';
import { createSecureStorage } from 'src/utils/secure-storage';

const secureStorage = createSecureStorage({
  secretPassphrase: process.env.REACT_APP_SECURE_STORE_SECRET_PASSPHRASE,
});

// Configure redux store
export const storeAdapter = new ReduxStoreAdapter({
  persistConfigs: {
    main: {
      key: 'main',
      storage: localStorage,
      stateReconciler: autoMergeLevel2,
    },
    secure: {
      key: 'secure',
      storage: secureStorage,
    },
  },
});
