import React, { useMemo, useState } from 'react';

import {
  Alert,
  Stack,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Iconify from 'src/components/atoms/media/Iconify';
import FormProvider, {
  RHFCodes,
  RHFTextField,
} from 'src/components/atoms/hook-form';

interface Props {
  error?: Error | undefined | null;
  onSubmit: (values: Form) => Promise<void> | void;
}

export interface Form {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
  password: string;
  confirmPassword: string;
}

export const ResetPasswordForm: React.FC<Props> = ({ error, onSubmit }) => {
  // Translation
  const { t } = useTranslation(['reset-password-page', 'common']);

  // State
  const [showPassword, setShowPassword] = useState(false);

  // Schema
  const schema = useMemo(
    () =>
      Yup.object({
        code1: Yup.string().required(),
        code2: Yup.string().required(),
        code3: Yup.string().required(),
        code4: Yup.string().required(),
        code5: Yup.string().required(),
        code6: Yup.string().required(),
        password: Yup.string().required(),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match',
        ),
      }).required(),
    [],
  );

  // Form
  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors, isValid },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!error?.message && <Alert severity="error">{error.message}</Alert>}

        <RHFCodes
          keyName="code"
          inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']}
        />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            {t('reset-password-page:errors.code-required')}
          </FormHelperText>
        )}

        <RHFTextField
          name="password"
          label={t('common:labels.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label={t('common:labels.confirm-password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={(isSubmitSuccessful && !error) || isSubmitting}
          disabled={!isValid || isSubmitting}
          sx={{
            bgcolor: 'text.primary',
            color: (theme) =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            '&:hover': {
              bgcolor: 'text.primary',
              color: (theme) =>
                theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
            },
          }}
        >
          {t('reset-password-page:buttons.send')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
