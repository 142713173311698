import React, { useState } from 'react';

import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from 'src/components/atoms/media/Image';
import MenuPopover from 'src/components/molecules/menu-popover';
import { IconButtonAnimate } from 'src/components/atoms/animate';

import {
  supportedLocales,
  supportedLocalesArray,
} from 'src/services/localization/config';

export default function LanguagePopover() {
  const { i18n } = useTranslation();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const currentLang =
    supportedLocales[i18n.language as keyof typeof supportedLocales];

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    i18n.changeLanguage(newLang);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Image disabledEffect src={currentLang.icon} alt={currentLang.name} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}
      >
        <Stack spacing={0.75}>
          {supportedLocalesArray.map((option) => (
            <MenuItem
              key={option.iso6391}
              selected={option.iso6391 === i18n.language}
              onClick={() => handleChangeLang(option.iso6391)}
            >
              <Image
                disabledEffect
                alt={option.name}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
