import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import Iconify from 'src/components/atoms/media/Iconify';
import { OtpForm, Form } from 'src/components/organisms/form/OtpForm';

import useActions from 'src/hooks/useActions';
import { EmailInboxIcon } from 'src/assets/icons';
import { useVerifyMutation, useChallengeMutation } from 'src/core';

export default function OtpPage() {
  // Translations
  const { t } = useTranslation(['otp-page', 'common']);

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();

  // Mutations
  const [challenge] = useChallengeMutation();
  const [verify, { error }] = useVerifyMutation();

  // Actions
  const { login } = useActions();

  // Callbacks
  const handleResend = useCallback(async () => {
    try {
      if (!location.state.email) {
        throw new Error('Invalid email');
      }

      if (!location.state.password) {
        throw new Error('Invalid password');
      }

      const result = await challenge({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            email: location.state.email,
            password: location.state.password,
          },
        },
      });

      if (result.errors) {
        throw result.errors;
      }
    } catch (err) {
      console.log('ERROR: ', err);
    }
  }, [challenge, location.state.email, location.state.password]);

  const handleSubmit = useCallback(
    async (values: Form) => {
      try {
        if (
          !values.code1 ||
          !values.code2 ||
          !values.code3 ||
          !values.code4 ||
          !values.code5 ||
          !values.code6
        ) {
          throw new Error('Invalid code');
        }

        const result = await verify({
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              email: location.state.email,
              code: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        if (!result.data) {
          throw new Error('Something went wrong');
        }

        login({
          accessToken: result.data.verifyAdmin.tokens.accessToken,
          // TODO: Check why refreshToken can be null
          refreshToken: result.data.verifyAdmin.tokens.refreshToken ?? '',
          expiresAt: result.data.verifyAdmin.tokens.expiresAt,
          tokenType: result.data.verifyAdmin.tokens.tokenType,
        });

        navigate(paths.dashboard.pattern, { replace: true });
      } catch (err) {
        console.log('ERROR: ', err);
      }
    },
    [location.state.email, login, navigate, verify],
  );

  return (
    <>
      <Helmet>
        <title>{t('otp-page:page-title')}</title>
      </Helmet>

      <EmailInboxIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        {t('otp-page:title')}
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        {t('otp-page:description')}
      </Typography>

      <OtpForm error={error} onSubmit={handleSubmit} />

      <Typography variant="body2" sx={{ my: 3 }}>
        {t('otp-page:no-code')}&nbsp;

        <Link variant="subtitle2" onClick={handleResend}>
          {t('otp-page:buttons.resend')}
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        to={paths.auth.login.pattern}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />

        {t('otp-page:buttons.back')}
      </Link>
    </>
  );
}
