import { combineReducers } from 'redux';

import { authenticationReducer } from 'src/core/adapters/redux-store/states/authentication/reducer';
import { settingsReducer } from 'src/core/adapters/redux-store/states/settings/reducer';
import { RootState } from 'src/core/adapters/redux-store/state';
import { Action } from 'src/core/adapters/redux-store/actions';

const mainReducer = combineReducers<RootState['main'], Action>({
  settings: settingsReducer,
});

const secureReducer = combineReducers<RootState['secure'], Action>({
  authentication: authenticationReducer,
});

export default { main: mainReducer, secure: secureReducer };
