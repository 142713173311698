import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  UriFunction,
} from '@apollo/client';

import { Credentials } from 'src/core/entities/authentication';
import ReduxStoreAdapter, {
  credentialsSelector,
} from 'src/core/adapters/redux-store';
import { refreshToken as refreshTokenAction } from 'src/core/adapters/redux-store/action-creators';
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from 'src/core/services/apollo/@generated';
import { loggerLink } from 'src/core/services/apollo/logger';

const _1minute = 1 * 60 * 1000;
const logger = loggerLink(() => 'Snoop-Refresh');
let pendingPromiseRenewCredentials: Promise<Credentials> | null = null;

export const getLocalCredentials = (
  storeAdapter: ReduxStoreAdapter,
): Credentials | null => {
  const store = storeAdapter.getStore();
  const credentials = credentialsSelector(store.getState());

  if (!credentials) {
    return null;
  }

  return credentials;
};

export const getAutoRenewCredentials = async (
  uri: string | UriFunction | undefined,
  storeAdapter: ReduxStoreAdapter,
): Promise<Credentials | null> => {
  const credentials = getLocalCredentials(storeAdapter);
  const currentTimestamp = Date.now();

  if (!credentials) {
    return null;
  }

  if (currentTimestamp + _1minute <= credentials.expiresAt) {
    return credentials;
  }

  // Avoid multiple call to refresh token
  if (!pendingPromiseRenewCredentials) {
    pendingPromiseRenewCredentials = renewCredentials(
      uri,
      storeAdapter,
    ).finally(() => {
      pendingPromiseRenewCredentials = null;
    });
  }

  return pendingPromiseRenewCredentials;
};

const renew = async (
  uri: string | UriFunction | undefined,
  refreshToken: string,
) => {
  const httpLink = new HttpLink({ uri });

  // Create new client to call refresh token graphql mutation
  const client = new ApolloClient({
    link: ApolloLink.from([logger, httpLink]),
    cache: new InMemoryCache({}),
  });

  const { data, errors } = await client.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: RefreshTokenDocument,
    variables: {
      refreshToken: refreshToken,
    },
    fetchPolicy: 'network-only',
  });

  if (errors || !data) {
    throw new Error('Something went wrong during refresh token');
  }

  return data;
};

export const renewCredentials = async (
  uri: string | UriFunction | undefined,
  storeAdapter: ReduxStoreAdapter,
): Promise<Credentials> => {
  const store = storeAdapter.getStore();
  const dispatch = store.dispatch;
  const credentials = credentialsSelector(store.getState());

  if (!credentials?.refreshToken) {
    throw new Error('Refresh token is not defined');
  }

  const data = await renew(uri, credentials.refreshToken);

  // Refresh data in redux
  refreshTokenAction(data.refreshToken)(dispatch);

  return data.refreshToken;
};
