import { PersistConfig, Persistor } from 'redux-persist';
import { Store } from 'redux';

import { ReduxStore } from 'src/core/infrastructure/redux-store';
import { RootState } from 'src/core/adapters/redux-store/state';
import { Action } from 'src/core/adapters/redux-store/actions';
import reducers from 'src/core/adapters/redux-store/reducers';

interface Options {
  persistConfigs: {
    [K in keyof RootState]: PersistConfig<RootState[K]>;
  };
}

export interface ReduxStoreAdapterInterface {
  getStore: () => Store<RootState, Action>;
  getPersistor: () => Persistor;
}
export default class ReduxStoreAdapter implements ReduxStoreAdapterInterface {
  private store: ReduxStore<RootState, Action>;

  constructor(options: Options) {
    const store = new ReduxStore<RootState, Action>({
      reducers,
      persistConfigs: options.persistConfigs,
    });

    this.store = store;
  }

  getStore(): Store<RootState, Action> {
    return this.store.getStore();
  }

  getPersistor(): Persistor {
    return this.store.getPersistor();
  }
}

export type { RootState, Action };
export * from 'src/core/adapters/redux-store/selectors';
