import React, { useMemo } from 'react';

import { alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  Card,
  Avatar,
  CardHeader,
  Typography,
  CardProps,
} from '@mui/material';

import Iconify from 'src/components/atoms/media/Iconify';

import { fShortenNumber } from 'src/utils/formatNumber';
import { TopUserFragment } from 'src/core';
import { BucketFolder, getBucketPath } from 'src/utils/bucket';

type ItemProps = {
  item: TopUserFragment;
  index?: number;
};

function Item({ index, item }: ItemProps) {
  const uri = useMemo(() => {
    if (!item.profile.picture) {
      return undefined;
    }

    return getBucketPath(
      BucketFolder.ProfilePicture,
      'w185',
      item.profile.picture,
    );
  }, [item.profile.picture]);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar alt={item.username ?? ''} src={uri} />

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{item.username}</Typography>

        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          <Iconify icon="eva:film-outline" width={16} sx={{ mr: 0.5 }} />

          {fShortenNumber(item._count.activities ?? 0)}
        </Typography>
      </Box>

      <Iconify
        icon="ant-design:trophy-filled"
        sx={{
          p: 1,
          width: 40,
          height: 40,
          borderRadius: '50%',
          color: 'primary.main',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          ...(index === 1 && {
            color: 'info.main',
            bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
          }),
          ...(index === 2 && {
            color: 'error.main',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
        }}
      />
    </Stack>
  );
}

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  list: TopUserFragment[];
}

export function WidgetTopAuthors({ title, subheader, list, ...other }: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3 }}>
        {list.map((user, index) => (
          <Item key={user.id} item={user} index={index} />
        ))}
      </Stack>
    </Card>
  );
}
