import { Credentials } from 'src/core/entities/authentication';
import { LocaleSettings } from 'src/core/entities/locale-settings';
import { ThemeSettings } from 'src/core/entities/theme-settings';

export interface RootState {
  readonly secure: {
    readonly authentication: {
      credentials: Credentials | null;
    };
  };
  readonly main: {
    readonly settings: {
      locale: LocaleSettings;
      theme: ThemeSettings;
    };
  };
}

export const initialState: RootState = {
  secure: {
    authentication: {
      credentials: null,
    },
  },
  main: {
    settings: {
      locale: {
        language: null,
        country: null,
      },
      theme: {
        themeMode: null,
        themeDirection: 'ltr',
        themeContrast: 'default',
        themeLayout: 'vertical',
        themeStretch: false,
      },
    },
  },
};
