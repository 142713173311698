import React, { useCallback } from 'react';

import { LoaderFunction, useLoaderData } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/navigation/paths';
import { RegisterForm, Form } from 'src/components/organisms/form/RegisterForm';

import useSearchParams from 'src/hooks/useSearchParams';
import {
  VerifyAdminTokenDocument,
  VerifyAdminTokenQuery,
  VerifyAdminTokenQueryVariables,
} from 'src/core';
import { getApolloClient } from 'src/utils/apollo';
import { useRegisterMutation } from 'src/core';

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * Check if provided token is still valid
 */
export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const apolloClient = await getApolloClient;

  if (!token) {
    throw new Error('Token is missing');
  }

  if (!apolloClient) {
    throw new Error('Apollo client is not defined');
  }

  const { data, error } = await apolloClient.query<
    VerifyAdminTokenQuery,
    VerifyAdminTokenQueryVariables
  >({
    query: VerifyAdminTokenDocument,
    variables: { token },
    fetchPolicy: 'no-cache',
  });

  if (error) {
    throw error;
  }

  return data.verifyAdminToken;
};

export default function InvitePage() {
  // Translation
  const { t } = useTranslation(['invite-page', 'common']);

  // Navigation
  const navigate = useNavigate();

  // Mutation
  const [registerAdmin, { error }] = useRegisterMutation();

  // Loader data
  const email = useLoaderData() as string;

  // Extract token from query string
  const [searchParams] = useSearchParams<typeof paths.auth.invite>();
  const token = searchParams.get('token');

  // Callbacks
  const onSubmit = useCallback(
    async (values: Form) => {
      try {
        if (!values.firstName) {
          throw new Error('Invalid firstName');
        }

        if (!values.lastName) {
          throw new Error('Invalid lastName');
        }

        if (!values.email) {
          throw new Error('Invalid email');
        }

        if (!values.phone) {
          throw new Error('Invalid phone');
        }

        if (!values.password) {
          throw new Error('Invalid password');
        }

        if (!token) {
          throw new Error('Invalid token');
        }

        const result = await registerAdmin({
          fetchPolicy: 'no-cache',
          variables: {
            data: {
              token,
              name: `${values.firstName}${values.lastName}`,
              email: values.email,
              phone: values.phone,
              password: values.password,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        navigate(paths.auth.otp.pattern, {
          state: { email: values.email, password: values.password },
        });
      } catch (err) {
        console.log('ERROR: ', err);
      }
    },
    [navigate, registerAdmin, token],
  );

  return (
    <>
      <Helmet>
        <title>{t('invite-page:page-title')}</title>
      </Helmet>

      <Content>
        <Typography variant="h4" gutterBottom>
          {t('invite-page:title')}
        </Typography>

        <RegisterForm email={email} error={error} onSubmit={onSubmit} />
      </Content>
    </>
  );
}
