import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import NotificationForm, {
  FormValuesProps,
} from 'src/components/organisms/form/NotificationForm';

import {
  themeSettingsSelector,
  useSendPushNotificationsMutation,
} from 'src/core';
import { useSnackbar } from 'src/providers/Snackbar';

export default function NotificationPage() {
  // Translation
  const { t } = useTranslation(['notification-page', 'common']);

  // Selectors
  const { themeStretch } = useSelector(themeSettingsSelector);

  // SnackBar
  const { enqueueSnackbar } = useSnackbar();

  // Mutation
  const [sendPushNotifications] = useSendPushNotificationsMutation({
    fetchPolicy: 'no-cache',
  });

  // Callbacks
  const handleSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        const result = await sendPushNotifications({
          variables: {
            args: {
              target: {
                recipientStatus: data.recipientStatus,
              },
              title: data.title,
              subtitle: data.subtitle,
              body: data.description,
              priority: data.priority,
            },
          },
        });

        if (result.errors) {
          throw result.errors;
        }

        enqueueSnackbar(t('notification-page:success'));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        enqueueSnackbar(err?.message ?? t('common:something-wrong'), {
          variant: 'error',
        });
      }
    },
    [sendPushNotifications, enqueueSnackbar, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('notification-page:page-title')}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography marginBottom={2} variant="h4" gutterBottom>
          {t('notification-page:title')}
        </Typography>

        <NotificationForm onSubmit={handleSubmit} />
      </Container>
    </>
  );
}
