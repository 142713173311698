import React, { PropsWithChildren, useEffect, useState } from 'react';

import isEqual from 'lodash.isequal';

import useSelector from 'src/hooks/useSelector';
import { credentialsSelector, useMeQuery } from 'src/core';
import CurrentAdminContext from 'src/providers/CurrentAdmin/Context';

const CurrentAdminProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  // Selectors
  const credentials = useSelector(credentialsSelector);

  // Query
  const { data, previousData, ...rest } = useMeQuery({
    // Skip if we don't have credentials
    skip: !credentials,
    fetchPolicy: 'cache-and-network',
  });

  // State
  const [admin, setAdmin] = useState(data?.meAdmin);

  // Effects
  useEffect(() => {
    if (!isEqual(data, previousData)) {
      setAdmin(data?.meAdmin);
    }
  }, [data, previousData]);

  return (
    <CurrentAdminContext.Provider value={{ admin }} {...rest}>
      {children}
    </CurrentAdminContext.Provider>
  );
};

export default CurrentAdminProvider;
