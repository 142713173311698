import { CustomDetector } from 'i18next-browser-languagedetector';

import { storeAdapter } from 'src/utils/store';
import { localeSettingsSelector } from 'src/core';
import { setLocaleLanguageSetting } from 'src/core/adapters/redux-store/action-creators';

const store = storeAdapter.getStore();

export const languageReduxDetector: CustomDetector = {
  name: 'redux',
  lookup: () => {
    const state = store.getState();
    const locale = localeSettingsSelector(state);

    return locale.language as string;
  },
  cacheUserLanguage: (langKey: string) => {
    const state = store.getState();
    const locale = localeSettingsSelector(state);

    if (locale.language !== langKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      store.dispatch<any>(
        setLocaleLanguageSetting(langKey as `${string}-${string}`),
      );
    }
  },
};
